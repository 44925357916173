import { Check, Horse, LightningA, Repeat } from '@phosphor-icons/react';
import classNames from 'classnames';
import { usePlanning } from 'hooks/UsePlanning';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import ActivityModal from './ActivityModal';
import { AvatarInitials, AvatarSize } from 'ui/Avatar';
import { contactInitials } from 'utilities/Contact';
import BluePrintModal from './BluePrintModal';
import {
  activityNameInCal,
  BluePrintState,
  CalendarActivity,
  CalendarActivityType,
  DragDropType,
  ExtraTargets,
  GroupBy,
  orderActivities,
  SelectedActivityState,
} from 'utilities/Planning';
import { isSameDay } from 'date-fns';
import { equalGroupByApplied, GroupByApplied } from 'context/Calendar';
import { ActivityHeight, ActivityHeightSpacious } from './DayGrid';
import { informalHorseName } from 'utilities/Horse';
import ApiErrorParser from 'api/ApiErrorParser';
import { RealActivities } from 'openapi';
import Spinner, { SpinnerSize } from 'ui/Loading/Spinner';

export interface Props {
  activities?: CalendarActivity[];
  className?: string;

  // The heights for every day part.
  heights: number[];

  // Which day this component belongs to
  day: Date;

  // To which contact, stable, horse, etc does this component apply to
  appliedGroupBy?: GroupByApplied;

  // True when we want to show the horse name in the calendar item.
  showHorseName: boolean;

  // True when we want to show the primary contact icon in the calendar item.
  showContactAvatar: boolean;

  // Enable drag/drop
  dragDropType: DragDropType;

  // Give the calendar components more spacing and height.
  spacious: boolean;

  // On activity save from blue print modal
  onSaved?: () => Promise<void>;
}

interface DragOver {
  dayPart?: number; // At which day part should the drag-over placeholder be shown.
  index?: number; // Wat which item index within the day part should the drag-over placeholder be shown.
}

interface GroupedCalendarActivities {
  activities: CalendarActivity[];
  dayPart: number;
  identifier: string;
}

/**
 * Lays out Calendar activities in a day parts time scale.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * When a company has defined day parts, then we can divide a day into smaller blocks. Like morning and afternoon.
 * The calendar items will show up in each day part as list items.
 * You can drag/drop, inspect, add and edit CalendarActivities from this component.
 *
 *            ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 *            │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 *            │      │      │      │      │      │      │      │
 *            │      │      │      │      │      │      │      │
 * ┌──────────┐──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │morning   │      │      │      │      │      │      │      │
 * │afternoon │      │      │      │      │      │      │      │
 * ┼──────────┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │morning   │      │xxxxxx│      │      │      │      │      │
 * │afternoon │      │xxxxxx│      │      │      │      │      │
 * ┼──────────┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │morning   │      │      │      │      │      │      │      │
 * │afternoon │      │      │      │      │      │      │      │
 * ┼──────────┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │morning   │      │      │      │      │      │      │      │
 * │afternoon │      │      │      │      │      │      │      │
 * └──────────┘──────└──────└──────└──────└──────└──────└──────┘
 *
 */
export default function ActivityContainerDayParts({
  day,
  appliedGroupBy,
  activities,
  heights,
  className,
  showHorseName,
  showContactAvatar,
  dragDropType,
  spacious,
  onSaved,
}: Props): JSX.Element {
  const {
    moveActivityToDayPart,
    moveActivityToDay,
    lastUsedActivityType,
    dragActivity,
    setDragActivity,
    bluePrint,
    requestBluePrint,
    unsetBluePrint,
    selectedActivity,
    setSelectedActivity,
    clearSelectedActivity,
    groupBy: groupByType,
    moveActivityToContact,
    saveActivityExtraTarget,
    setModalError,
  } = usePlanning();

  // Indicator if we're checking with a dryrun if we can add this item in case of multi-add.
  const [loadingBluePrintExtra, setLoadingBluePrintExtra] = useState<ExtraTargets | undefined>();

  // Add 4px item spacing when 'spacious' is set to true.
  const itemSpacing = useMemo(() => {
    return spacious ? 4 : 1;
  }, [spacious]);

  // The activity height is not fully equal to ActivityHeightSpacious because we also
  // apply margins to the top and bottom when 'spacious' is set to true.
  const activityHeight = useCallback(
    (itemCount: number) => {
      const height = (spacious ? ActivityHeightSpacious : ActivityHeight) - itemSpacing;
      if (itemCount > 1) {
        return height - itemSpacing / itemCount;
      } else if (itemCount === 1) {
        return height - itemSpacing / 2;
      } else {
        return height - itemSpacing;
      }
    },
    [spacious, itemSpacing],
  );

  // Check if drag drop is enabled. In the ClusterPlus scenario drag-drop is enabled when it's within the same cluster.
  const dragDropEnabled = useMemo(() => {
    if (dragDropType === DragDropType.DayPart) {
      return true;
    }
    if (dragDropType === DragDropType.ClusterPlus && equalGroupByApplied(dragActivity?.originGroupByUid, appliedGroupBy)) {
      return true;
    }
    return false;
  }, [dragDropType, appliedGroupBy, dragActivity]);

  // We split the activities into group according to the day parts.
  const splitActivities = useMemo((): GroupedCalendarActivities[] => {
    const res: GroupedCalendarActivities[] = [];
    if (heights?.length === 1) {
      // Return all activities in the first daypart.
      const ordered = orderActivities(activities ?? []);
      res.push({
        activities: ordered,
        dayPart: 0,
        identifier: ordered.reduce((acc, cur) => acc + cur, ''),
      });
      return res;
    }
    for (let i = 0; i < (heights.length ?? 0); i++) {
      const filtered = orderActivities(activities?.filter(activity => activity.dayPart === i) ?? []);
      res.push({
        activities: filtered,
        dayPart: i,
        identifier: filtered.reduce((acc, cur) => acc + cur, ''),
      });
    }
    return res;
  }, [activities, heights]);

  const showBluePrintExtraTargetLoading = useCallback(
    (dayPart: number): boolean => {
      if (!bluePrint) {
        return false;
      }
      if (!(bluePrint.type === CalendarActivityType.Activity || bluePrint.type === CalendarActivityType.FacilityEvent)) {
        return false;
      }
      if (!bluePrint.extraTargets) {
        return false;
      }

      if (
        loadingBluePrintExtra?.day &&
        isSameDay(loadingBluePrintExtra.day, day) &&
        equalGroupByApplied(loadingBluePrintExtra.appliedGroupBy, appliedGroupBy) &&
        loadingBluePrintExtra.dayPart === dayPart
      ) {
        return true;
      }
      return false;
    },
    [appliedGroupBy, day, loadingBluePrintExtra, bluePrint],
  );

  const showBluePrintExtraTarget = useCallback(
    (dayPart: number) => {
      if (!bluePrint) {
        return false;
      }
      if (!(bluePrint.type === CalendarActivityType.Activity || bluePrint.type === CalendarActivityType.FacilityEvent)) {
        return false;
      }
      if (!bluePrint.extraTargets) {
        return false;
      }

      for (const extraTarget of bluePrint.extraTargets) {
        if (
          extraTarget.day &&
          isSameDay(extraTarget.day, day) &&
          equalGroupByApplied(extraTarget.appliedGroupBy, appliedGroupBy) &&
          extraTarget.dayPart === dayPart
        ) {
          return true;
        }
      }

      return false;
    },
    [appliedGroupBy, bluePrint, day],
  );

  const [dragOver, setDragOver] = useState<DragOver | undefined>();

  const bluePrintClasses = 'box-border bg-blue-500 bg-opacity-10 rounded-lg border-blue-500 border-2 pointer-events-none';
  const bluePrintClassesSpacious = bluePrintClasses + ' mx-1';

  return (
    <div className={className}>
      <div className='w-full h-full flex flex-col'>
        {splitActivities.map((dayPartActivities, index) => {
          let height: string | number | undefined = undefined;
          if (heights) {
            height = heights[dayPartActivities.dayPart];
          } else {
            height = `${Math.round(100 / splitActivities.length)}%`;
          }
          return (
            <div
              key={`${dayPartActivities.identifier}-${index}`}
              className='w-full'
              style={{ height }}
              onDragOver={event => {
                if (!dragDropEnabled) {
                  // Drag drop on daypart level is disabled.
                  return;
                }
                const pos = event.clientY - event.currentTarget.getBoundingClientRect().top;
                const itemIndex = Math.floor(pos / activityHeight(dayPartActivities.activities.length));

                setDragOver({
                  dayPart: !dragActivity?.activity.isAllDayEvent ? undefined : dayPartActivities.dayPart,
                  index: !dragActivity?.activity.isAllDayEvent ? undefined : itemIndex,
                });

                event.preventDefault();
              }}
              onDragLeave={() => setDragOver(undefined)}
              onDrop={event => {
                if (!dragDropEnabled) {
                  // Drag drop on daypart level is disabled.
                  return;
                }
                event.preventDefault();
                setDragOver(undefined);
                const uid = dragActivity?.activity?.uid;
                if (!uid) {
                  console.error('Failed to get uid from drag event');
                  return;
                }
                if (dragOver) {
                  const horseUid: string | undefined = appliedGroupBy?.groupBy === GroupBy.Horse ? appliedGroupBy?.subject?.uid : undefined;
                  if (appliedGroupBy?.groupBy === GroupBy.Staff) {
                    const contactUid: string | undefined =
                      appliedGroupBy?.groupBy === GroupBy.Staff ? appliedGroupBy?.subject?.uid : undefined;
                    moveActivityToContact(
                      uid,
                      dragActivity.originGroupByUid?.subject?.uid,
                      contactUid,
                      day,
                      dragOver.dayPart,
                      dragOver.index,
                    ).catch(e => {
                      console.error(e);
                    });
                  } else if (dragOver.dayPart !== undefined && dragOver.index !== undefined) {
                    moveActivityToDayPart(uid, day, dragOver.dayPart, dragOver.index, horseUid).catch(e => {
                      console.error(e);
                    });
                  } else {
                    moveActivityToDay(uid, day, horseUid).then(e => {
                      console.error(e);
                    });
                  }
                }
              }}
              onClick={e => {
                e.stopPropagation();
                if (selectedActivity && selectedActivity.selectedActivityState === SelectedActivityState.Finalize) {
                  // When we are in a finalize state (i.e. when we create a pregnancy check) we just return.
                  unsetBluePrint(0);
                  setDragOver(undefined);
                } else if (selectedActivity && selectedActivity.selectedActivityState !== SelectedActivityState.Selected) {
                  setSelectedActivity(selectedActivity.activity, SelectedActivityState.Selected, selectedActivity?.groupByUid);
                } else if (bluePrint) {
                  if (bluePrint.state === BluePrintState.SelectExtraTarget) {
                    const copyBluePrint = { ...bluePrint };
                    if (!copyBluePrint.appliedGroupBy) {
                      copyBluePrint.horseUid = appliedGroupBy?.groupBy === GroupBy.Horse ? appliedGroupBy.subject?.uid : undefined;
                      copyBluePrint.day = day;
                      copyBluePrint.appliedGroupBy = appliedGroupBy;
                      copyBluePrint.duration = 0;
                      copyBluePrint.startPeriodOffset = 0;
                      copyBluePrint.dayPart = dayPartActivities.dayPart;
                      copyBluePrint.type = CalendarActivityType.Activity;
                      requestBluePrint(copyBluePrint);
                    } else {
                      const extra = copyBluePrint.extraTargets ?? [];

                      const extraItem = {
                        day,
                        appliedGroupBy,
                        duration: 0,
                        startPeriodOffset: 0,
                        dayPart: dayPartActivities.dayPart,
                      };
                      const index = extra.findIndex(
                        item =>
                          item.day === day &&
                          item.appliedGroupBy?.subject?.uid === appliedGroupBy?.subject?.uid &&
                          item.dayPart === dayPartActivities.dayPart,
                      );
                      if (index === -1) {
                        setLoadingBluePrintExtra(extraItem);
                        saveActivityExtraTarget(extraItem, true)
                          .then(() => {
                            extra.push(extraItem);
                            copyBluePrint.extraTargets = extra;
                            requestBluePrint(copyBluePrint);
                          })
                          .catch(error => {
                            setModalError(new ApiErrorParser<RealActivities>(error));
                          })
                          .finally(() => setLoadingBluePrintExtra(undefined));
                      } else {
                        extra.splice(index, 1);
                        copyBluePrint.extraTargets = extra;
                        requestBluePrint(copyBluePrint);
                      }
                    }
                  } else {
                    // We already have a blueprint active then close it. Otherwise create a new blueprint for the given daypart.
                    unsetBluePrint(0);
                    clearSelectedActivity();
                  }
                } else {
                  clearSelectedActivity();
                  requestBluePrint({
                    day,
                    appliedGroupBy,
                    horseUid: appliedGroupBy?.groupBy === GroupBy.Horse ? appliedGroupBy.subject?.uid : undefined,
                    stableUid: appliedGroupBy?.groupBy === GroupBy.Stable ? appliedGroupBy.subject?.uid : undefined,
                    duration: 0,
                    startPeriodOffset: 0,
                    dayPart: dayPartActivities.dayPart,
                    state: BluePrintState.EditCompact,
                    activityTypeUid: groupByType === GroupBy.Horse ? lastUsedActivityType?.uid : undefined,
                    type: groupByType === GroupBy.Facility ? CalendarActivityType.FacilityEvent : CalendarActivityType.Activity,
                  });
                }
              }}
            >
              {dayPartActivities.activities.map((activity, itemIndex) => {
                const primaryAssignee = activity.assignedTo.find(assignee => assignee.primary)?.contact;
                return (
                  <Fragment key={activity.uid}>
                    {dragDropEnabled && dragOver?.dayPart === dayPartActivities.dayPart && dragOver.index === itemIndex && (
                      <div className={bluePrintClasses} style={{ height: activityHeight(dayPartActivities.activities.length) }} />
                    )}
                    <ActivityModal activity={activity} groupByUid={appliedGroupBy?.subject?.uid}>
                      <div
                        draggable={dragDropType !== DragDropType.Disabled && !activity.done}
                        onDrag={() => setDragActivity({ activity, originGroupByUid: appliedGroupBy })}
                        onDragEnd={() => setDragActivity(undefined)}
                        onClick={e => {
                          if (bluePrint?.state !== BluePrintState.SelectExtraTarget) {
                            setSelectedActivity(activity, SelectedActivityState.Info, appliedGroupBy?.subject?.uid);
                            unsetBluePrint(0);
                            e.stopPropagation();
                          }
                        }}
                        className={classNames('box-border flex pr-1 py-0 justify-center items-center  select-none', {
                          'opacity-20': dragActivity?.activity.uid === activity.uid,
                          'rounded-l': isSameDay(day, activity.startTime), // Rounded left border if this day is the last day of the event.
                          'rounded-r': isSameDay(day, activity.endTime), // Rounded right border if this day is the last day of the event.
                          '-mr-1': !isSameDay(day, activity.endTime), // Overlap the right border if it's a multi day event
                          'cursor-pointer': bluePrint?.state !== BluePrintState.SelectExtraTarget,
                          grayscale: bluePrint?.state === BluePrintState.SelectExtraTarget,
                        })}
                        style={{
                          marginLeft: itemSpacing,
                          marginRight: itemSpacing,
                          marginTop: itemSpacing,
                          height: activityHeight(dayPartActivities.activities.length),
                          backgroundColor: activity.secondaryColor,
                          borderColor: activity.primaryColor,
                          borderLeftWidth: 6,
                          borderRightWidth: selectedActivity?.activity.uid === activity.uid ? 1 : 0,
                          borderTopWidth: selectedActivity?.activity.uid === activity.uid ? 1 : 0,
                          borderBottomWidth: selectedActivity?.activity.uid === activity.uid ? 1 : 0,
                        }}
                      >
                        <div className='grow ml-1 truncate'>
                          <p className='text-sm truncate'>
                            <span className='font-medium'>
                              {activityNameInCal(activity)} {activity.isAutomaticallyPlanned && <LightningA className='inline' />}{' '}
                              {activity.rRule && <Repeat className='inline -mt-1' />}
                            </span>
                            {showHorseName && (
                              <span className='ml-1 text-xs inline-flex items-center'>
                                - <Horse className='ml-0.5' />
                                {informalHorseName(activity.horse)}
                              </span>
                            )}
                          </p>
                          {activity.startEndText && <p className='text-xs opacity-90 truncate -mt-1'>{activity.startEndText}</p>}
                        </div>
                        {primaryAssignee && showContactAvatar && (
                          <AvatarInitials
                            size={AvatarSize.XSmall}
                            initials={primaryAssignee ? contactInitials(primaryAssignee) : '?'}
                            color={primaryAssignee.color}
                            uuid={primaryAssignee?.uid}
                          />
                        )}
                        {activity.done && <Check weight='bold' className='ml-0.5' size={20} />}
                      </div>
                    </ActivityModal>
                  </Fragment>
                );
              })}
              {bluePrint &&
                (bluePrint.type === CalendarActivityType.Activity || bluePrint.type === CalendarActivityType.FacilityEvent) &&
                bluePrint.day &&
                isSameDay(bluePrint.day, day) &&
                equalGroupByApplied(bluePrint.appliedGroupBy, appliedGroupBy) &&
                bluePrint.dayPart === dayPartActivities.dayPart &&
                bluePrint.state !== BluePrintState.SelectExtraTarget && (
                  <BluePrintModal onSaved={onSaved}>
                    <div
                      className={spacious ? bluePrintClassesSpacious : bluePrintClasses}
                      style={{
                        height: activityHeight(dayPartActivities.activities.length),
                        marginTop: itemSpacing,
                        marginLeft: itemSpacing,
                        marginRight: itemSpacing,
                      }}
                      onClick={e => e.stopPropagation()}
                    />
                  </BluePrintModal>
                )}
              {showBluePrintExtraTarget(dayPartActivities.dayPart) && (
                <div
                  className={spacious ? bluePrintClassesSpacious : bluePrintClasses}
                  style={{
                    height: activityHeight(dayPartActivities.activities.length),
                    marginTop: itemSpacing,
                    marginLeft: itemSpacing,
                    marginRight: itemSpacing,
                  }}
                  onClick={e => e.stopPropagation()}
                />
              )}
              {showBluePrintExtraTargetLoading(dayPartActivities.dayPart) && (
                <div
                  className={classNames(spacious ? bluePrintClassesSpacious : bluePrintClasses, 'flex items-center justify-center')}
                  style={{
                    height: activityHeight(dayPartActivities.activities.length),
                    marginTop: itemSpacing,
                    marginLeft: itemSpacing,
                    marginRight: itemSpacing,
                  }}
                  onClick={e => e.stopPropagation()}
                >
                  <Spinner size={SpinnerSize.XSmall} />
                </div>
              )}

              {dragDropEnabled &&
                dragOver?.index !== undefined &&
                dragOver?.dayPart !== undefined &&
                dragOver?.dayPart === dayPartActivities.dayPart &&
                dragOver.index >= dayPartActivities.activities.length && (
                  <div
                    className={spacious ? bluePrintClassesSpacious : bluePrintClasses}
                    style={{
                      height: activityHeight(dayPartActivities.activities.length),
                      marginTop: itemSpacing,
                      marginLeft: itemSpacing,
                      marginRight: itemSpacing,
                    }}
                  />
                )}

              {dragDropEnabled && dragOver !== undefined && dragOver?.dayPart === undefined && (
                <div
                  className={classNames('absolute inset-0', spacious ? bluePrintClassesSpacious : bluePrintClasses)}
                  style={{
                    margin: itemSpacing,
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
