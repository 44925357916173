import { zodResolver } from '@hookform/resolvers/zod';
import { Horse as HorseIcon, MapPinArea, X } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import {
  Contact,
  EquineMHorseSearchDetail,
  EquineMSearchLastPage,
  HorseGroup,
  HorseLocationMove,
  HorsegroupsService,
  HorsesService,
  ModulePermissionsEnum,
  Stable,
  StablesService,
} from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, DateInput, SelectInput, TextInput } from 'ui/Inputs';
import { InputSize, OptionItemInterface } from 'ui/Inputs/SelectInput';
import { Label } from 'ui/Label';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { transformEmpty, transformEmptyNumber, transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import { z } from 'zod';
import TogggleInput from 'ui/Inputs/ToggleInput';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { colorList } from 'utilities/string.utility';
import { gender, genderList } from 'utilities/Horse';
import Fieldset from 'ui/Fieldset';
import { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import { ApiPromises } from 'utilities/ApiPromises';
import usePermissions from 'hooks/UsePermissions';
import { activeContacts } from 'utilities/ApiRequests';
import useApiPromises from 'api/hooks/useApiPromises';
import { useAccount } from 'context/AccountContext';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import { BadgeSize } from 'ui/Badge/Badge';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';
import useHorseCarePricing from 'hooks/UseHorseCarePricing';
import SearchResultSearchedHorsesModal from './SearchResultSearchedHorsesModal';
import { SearchResultSearchedHorseRow } from './SearchResultSearchedHorseRow';
import { BreedingBadge, CareBadge, SportBadge } from '../HorseUsageBadges';
import RvoConfirmReportModal from '../RvoConfirmReportModal';
import HorseBasicDetailsModal from '../HorseBasicDetailsModal';

/**
 * Small helper to transform null values to undefined
 */
const transformNullToUndefined = (value: unknown) => (value === null ? undefined : value);

interface HorseCreate extends HorseDetail {
  move_date: HorseLocationMove['date'];
  move_destination_uid: HorseLocationMove['destination_uid'];
  move_arrival_is_import: HorseLocationMove['arrival_is_import'];
  location_differs_from_stable: boolean;
  is_external: boolean;
}

interface FoundHorse {
  details: HorseDetail;
  selected: boolean;
}

interface Props {
  open: boolean;
  onRequestCloseModal: () => void;
  onCreated: (newHorse: HorseDetail) => Promise<void> | void;
  defaultName?: string;
  newOwner?: Contact;

  // flag that indicates if the horse is external (=no location and no stable)
  isExternal?: boolean;
}

function CreateHorseModal({ open, onRequestCloseModal, onCreated, defaultName, newOwner, isExternal }: Props): JSX.Element {
  const [foundHorse, setFoundHorse] = useState<FoundHorse>();
  // the horse that has been found from the external DB
  const [searchHorses, setSearchHorses] = useState<EquineMSearchLastPage>();
  // the page nr when we navigate through the results of the external DB
  const [searchHorsesPageNr, setSearchHorsesPageNr] = useState<number>(1);
  // the selected horse from the external DB
  const [searchHorseSelected, setSearchHorseSelected] = useState<EquineMHorseSearchDetail>();
  // flag that indicate if we are searching in the external DB
  const [searchingHorse, setSearchingHorse] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>();
  const [groups, setGroups] = useState<HorseGroup[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [stables, setStables] = useState<Stable[]>([]);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { errorString, loading } = useApiPromises({ apiPromises, catchErrors: true });
  const [createdHorse, setCreatedHorse] = useState<HorseDetail>();
  // flag that indicate if a horse is external (= no location and no stable)
  const [isExternalFormValue, setIsExternalFormValue] = useState<boolean>();

  const { selectedOrganizationUid, selectedOrganizationDetails, generateCacheKey, refreshServiceContracts } = useOrganization();
  const { accountDetails } = useAccount();
  const { t } = useTranslation();
  const { closeModal: closeBasicHorseModal, modalIsVisible: basicHorseModalIsVisible, showModal: showBasicHorseModal } = useModal();
  const { closeModal: closeModalRvo, modalIsVisible: modalIsVisiblRvo, showModal: showModalRvo } = useModal();
  const { closeModal: closeModalSearchHorses, modalIsVisible: modalIsVisiblSearchHorses, showModal: showModalSearchHorses } = useModal();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const { isEnabled: horseServicesIsEnabled } = useHorseCarePricing();

  // determine if we should disable the usage fields
  const disableUsage = horseServicesIsEnabled === false || !selectedOrganizationDetails?.is_paid;

  // convert the color list to a selectList
  const colors = useMemo((): OptionItemInterface[] => colorList(t).map<OptionItemInterface>(c => ({ id: c.value, name: c.label })), [t]);
  const genders = useMemo((): OptionItemInterface[] => genderList(t).map<OptionItemInterface>(c => ({ id: c.value, name: c.label })), [t]);

  // Form validation
  const schema = useMemo(() => {
    const fields: { [k in keyof HorseDetail]?: true } = {};

    // include the fields that are only needed when we create a new horse
    if (!foundHorse?.selected) {
      fields.name = true;
      fields.color = true;
      fields.sex = true;
      fields.date_of_birth = true;
      fields.UELN = true;
      fields.chip_nr = true;
      fields.withers_height = true;
    }

    if (!isExternalFormValue) {
      fields.stable_uid = true;

      if (!disableUsage) {
        fields.use_in_breeding = true;
        fields.use_in_care = true;
        fields.use_in_sport = true;
      }
    }

    const _schema = schemas.HorseDetail.pick(fields);

    if (!isExternalFormValue) {
      _schema.extend({
        stable_uid: z.string().nullish(),
        move_destination_uid: z.string().nullish(),
        move_arrival_is_import: z.boolean().default(false),
        move_date: z.string(),
      });
    }

    // change the schema when we create a new horse
    if (!foundHorse?.selected) {
      return _schema.extend({
        // We should have a nullable type for both color and sex
        // as we are sure about the value, it is a number, we could add also a nullable validator
        color: z.number().nullable(),
        sex: z.number().nullable(),
      });
    }

    return _schema;
  }, [disableUsage, foundHorse?.selected, isExternalFormValue]);

  const defaultValues = useMemo(() => {
    return {
      move_date: new Date().toISOString().substring(0, 10),
      move_arrival_is_import: false,
      use_in_breeding: isExternal ? false : true,
      use_in_care: isExternal ? false : true,
      use_in_sport: isExternal ? false : true,
      is_external: isExternal,
      date_of_birth: undefined,
    };
  }, [isExternal]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    control,
  } = useForm<HorseCreate>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  // watch specific form values.
  // needed for checking if there is an inactive horse with the same values
  const nameFormValue = watch('name');
  const uelnFormValue = watch('UELN');
  const chipNrFormValue = watch('chip_nr');
  const destinationUidFormValue = watch('move_destination_uid');
  const stableUidFormValue = watch('stable_uid');
  const locationDiffersFromStableValue = watch('location_differs_from_stable');

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  // Set the destinations variable
  const destinationContact = contacts?.find(contact => contact.uid === destinationUidFormValue);

  // Visibility fields
  // See https://gitlab.qubis.nl/equinem/equinemcore/-/wikis/Horse/Move-field-information
  const fieldIsImportIsVisible = !destinationContact;

  // build the option list for the select
  const groupOptions: OptionItemInterface[] = groups.map(group => ({
    id: group.uid,
    name: group.name ?? group.uid,
  }));

  // build the option list for the select
  const stableOptions: OptionItemInterface[] = stables.map(stable => ({
    id: stable.uid,
    name: stable.location.business_name,
  }));

  /**
   * Split into 2 groups
   * 1. My locations
   * 2. other contacts
   */
  const filteredContacts = useMemo((): WrappedComboboxProps<Contact>[] => {
    return [
      {
        heading: t('my-locations', 'My locations'),
        items: (contacts ?? []).filter(contact => contact.external_location || contact.stable_location_uid),
        notFoundLabel: t('no-locations-found', 'No locations found'),
        icon: <MapPinArea />,
      },
      {
        heading: t('contacts', 'Contacts'),
        items: (contacts ?? []).filter(contact => !contact.external_location && !contact.stable_location_uid),
        notFoundLabel: t('no-contacts-found', 'No contacts found'),
      },
    ];
  }, [contacts, t]);

  /**
   * Submit handler
   */
  const onSubmit = async (data: HorseCreate) => {
    if (!selectedOrganizationUid) return;

    setSubmitting(true);

    try {
      // save the newHorse so we can use it in the onCreated event
      let newHorse: HorseDetail;

      // there are 2 situations
      // 1) we have found a horse and we should set the location and group
      // 2) we have not found a horse and we should create a new one

      if (foundHorse?.selected) {
        // we should set the horse active and set the location
        const setLocationPromise = HorsesService.horsesMoveCreate({
          organisationUid: selectedOrganizationUid,
          uid: foundHorse.details.uid,
          requestBody: {
            set_active: true,
            date: data.move_date,
            destination_uid: data.move_destination_uid,
            arrival_is_import: data.move_arrival_is_import,
          },
        });
        await setLocationPromise;

        // set the other data for the horse
        const setGroupPromise = HorsesService.horsesPartialUpdate({
          organisationUid: selectedOrganizationUid,
          uid: foundHorse.details.uid,
          requestBody: {
            group_uid: data.group_uid,
            sex: data.sex,
            color: data.color,
            date_of_birth: data.date_of_birth,
            // we should check if the useage is disabled, if so set it to false
            use_in_breeding: disableUsage ? false : data.use_in_breeding,
            use_in_care: disableUsage ? false : data.use_in_care,
            use_in_sport: disableUsage ? false : data.use_in_sport,
            new_owners: newOwner ? [newOwner.uid] : undefined,
          },
        });
        newHorse = await setGroupPromise;
      } else {
        const createHorsePromise = HorsesService.horsesCreate({
          organisationUid: selectedOrganizationUid,
          requestBody: {
            stable_uid: data.stable_uid,
            name: data.name,
            group_uid: data.group_uid,
            UELN: data.UELN,
            chip_nr: data.chip_nr,
            sex: data.sex,
            color: data.color,
            date_of_birth: data.date_of_birth,
            withers_height: data.withers_height,
            // we should check if the useage is disabled, if so set it to false
            use_in_breeding: disableUsage ? false : data.use_in_breeding,
            use_in_care: disableUsage ? false : data.use_in_care,
            use_in_sport: disableUsage ? false : data.use_in_sport,
            dam: data.dam,
            sire: data.sire,
            damsire: data.damsire,
            FEI_pass_nr: data.FEI_pass_nr,
            id_horsetelex: data.id_horsetelex,
            studbook: data.studbook,
            register_move: {
              date: data.move_date,
              destination_uid: data.move_destination_uid,
              arrival_is_import: data.move_arrival_is_import,
            },
            new_owners: newOwner ? [newOwner.uid] : undefined,

            // non field values, are coming from the horsetelex detail search
            damdam: data.damdam,
            damgranddam1: data.damgranddam1,
            damgranddam2: data.damgranddam2,
            damgrandsire1: data.damgrandsire1,
            damgrandsire2: data.damgrandsire2,
            siredam: data.siredam,
            siregranddam1: data.siregranddam1,
            siregranddam2: data.siregranddam2,
            siregrandsire1: data.siregrandsire1,
            siregrandsire2: data.siregrandsire2,
            siresire: data.siresire,
          } as HorseDetail,
        });

        newHorse = await createHorsePromise;
      }

      // fire the onCreated event
      await onCreated(newHorse);

      // in order to have the service_contract reflect possible changes for the new horse (usage options), we need to refresh the organization details
      if (horseServicesIsEnabled) {
        refreshServiceContracts();
      }

      // save the horse, so we can check if we need to show an RVO modal
      setCreatedHorse(newHorse);
      // show the cofirm modal when we have mutations
      if (destinationContact?.machtiging_rvo_gegeven) {
        showModalRvo();
      }

      // close the modal
      onRequestCloseModal();
    } catch (error: unknown) {
      setApiError(new ApiErrorParser(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * event that will be fired after the modal has been closed
   */
  const onClosed = useCallback(() => {
    setApiError(undefined);
    setFoundHorse(undefined);
    reset(defaultValues);
    setSearchHorseSelected(undefined);
    setSearchHorses(undefined);
    setSearchingHorse(false);

    // reset back to the given value
    setValue('is_external', Boolean(isExternal));
  }, [defaultValues, isExternal, reset, setApiError, setValue]);

  /**
   * Clear the selected Horse
   */
  const clearSelectedSearchedHorse = useCallback(() => {
    reset();
    onClosed();
  }, [onClosed, reset]);

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }

    // Load all contacts, including the removed once.
    // fall back on the horseContactsList endpoint if the user does not have the permission to view all contacts
    if (hasPermission(ModulePermissionsEnum.VIEW_CONTACTS)) {
      promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganizationUid, generateCacheKey));
    } else {
      promises.appendList<Contact>(
        'contacts',
        () =>
          HorsesService.horsesContactsList({
            organisationUid: selectedOrganizationUid,
          }),
        setContacts,
        generateCacheKey('horse-contacts'),
      );
    }

    promises.appendList<HorseGroup>(
      'horse-groups',
      () =>
        HorsegroupsService.horsegroupsList({
          organisationUid: selectedOrganizationUid,
        }),
      setGroups,
      generateCacheKey('horse-groups'),
    );

    promises.appendList<Stable>(
      'stables',
      () =>
        StablesService.stablesList({
          organisationUid: selectedOrganizationUid,
        }),
      setStables,
      generateCacheKey('stables'),
    );

    setApiPromises(promises);
    return promises;
  }, [generateCacheKey, hasPermission, selectedOrganizationUid]);

  /**
   * Toggle a foundHorse as selected or not
   */
  const toggleSelectedHorse = (selected: boolean) => {
    setFoundHorse(prevState => (prevState ? { ...prevState, selected } : undefined));
  };

  /**
   * Search in the external DB
   */
  const searchHorse = useCallback(
    async (page: number) => {
      // we do not search if current selected horse is the same as the form values
      if (
        String(transformNullToUndefined(searchHorseSelected?.UELN)).toLowerCase() === String(uelnFormValue).toLowerCase() &&
        String(transformNullToUndefined(searchHorseSelected?.chip_nr)).toLowerCase() === String(chipNrFormValue).toLowerCase() &&
        String(transformNullToUndefined(searchHorseSelected?.name)).toLowerCase() === String(nameFormValue).toLowerCase()
      ) {
        return;
      }

      setSearchHorsesPageNr(page);
      setSearchingHorse(true);

      try {
        const findHorseInfo = await HorsesService.horsesSearchHorseCreate({
          organisationUid: selectedOrganizationUid ?? '',
          requestBody: {
            UELN: uelnFormValue,
            chip_nr: chipNrFormValue,
            name: nameFormValue,
            page,
            pageSize: 10,
          },
        });

        setSearchHorses(findHorseInfo);
      } catch (error) {
        console.error('Failed to search horse', error);
      } finally {
        setSearchingHorse(false);
      }
    },
    [
      chipNrFormValue,
      nameFormValue,
      searchHorseSelected?.UELN,
      searchHorseSelected?.chip_nr,
      searchHorseSelected?.name,
      selectedOrganizationUid,
      uelnFormValue,
    ],
  );

  /**
   * Set the given horse as selected and reset the results and autofill the form
   */
  const onSelecteSearchedHorse = (horse: EquineMHorseSearchDetail) => {
    setSearchHorses(undefined);
    setSearchHorseSelected(horse);
  };

  /**
   * When the searchHorseSelected has been filled, we update the input fields with the found horse data.
   */
  useEffect(() => {
    if (!searchHorseSelected) return;

    // default values
    searchHorseSelected.name && setValue('name', searchHorseSelected.name);
    searchHorseSelected.date_of_birth && setValue('date_of_birth', searchHorseSelected.date_of_birth);
    searchHorseSelected.sex && setValue('sex', searchHorseSelected.sex);
    searchHorseSelected.sire && setValue('sire', searchHorseSelected.sire);
    searchHorseSelected.dam && setValue('dam', searchHorseSelected.dam);
    searchHorseSelected.damsire && setValue('damsire', searchHorseSelected.damsire);
    searchHorseSelected.UELN && getValues('UELN') !== searchHorseSelected.UELN && setValue('UELN', searchHorseSelected.UELN);
    searchHorseSelected.chip_nr && getValues('chip_nr') !== searchHorseSelected.chip_nr && setValue('chip_nr', searchHorseSelected.chip_nr);
    searchHorseSelected.FEI_pass_nr && setValue('FEI_pass_nr', searchHorseSelected.FEI_pass_nr);
    searchHorseSelected.id_horsetelex && setValue('id_horsetelex', searchHorseSelected.id_horsetelex);
    searchHorseSelected.current_location_arrival_date && setValue('move_date', searchHorseSelected.current_location_arrival_date);
    searchHorseSelected.studbook && setValue('studbook', searchHorseSelected.studbook);

    // we could just pass the data to the reset function to apply this to the form
    HorsesService.horsesGetHorsetelexDetailCreate({
      organisationUid: selectedOrganizationUid ?? '',
      requestBody: {
        get_or_create_parent_horses: false,
        id_horsetelex: searchHorseSelected.id_horsetelex,
      },
    })
      .then(res => {
        setValue('damdam', res.damdam);
        setValue('damgranddam1', res.damgranddam1);
        setValue('damgranddam2', res.damgranddam2);
        setValue('damgrandsire1', res.damgrandsire1);
        setValue('damgrandsire2', res.damgrandsire2);
        setValue('date_of_birth', res.date_of_birth);
        setValue('sex', res.sex);
        setValue('siredam', res.siredam);
        setValue('siregranddam1', res.siregranddam1);
        setValue('siregranddam2', res.siregranddam2);
        setValue('siregrandsire1', res.siregrandsire1);
        setValue('siregrandsire2', res.siregrandsire2);
        setValue('siresire', res.siresire);
        setValue('withers_height', res.withers_height);
      })
      .catch(e => console.error(e));
  }, [getValues, reset, searchHorseSelected, selectedOrganizationUid, setValue]);

  /**
   * Initial load when the modal opened
   */
  useEffect(() => {
    if (!open) return;
    if (!selectedOrganizationUid) return;

    const promise = loadApiData();
    return () => promise.cancel();
  }, [loadApiData, open, selectedOrganizationUid]);

  /**
   * in case we just have one value for Stables or Locations
   * we can use that first value and hide the form field.
   */
  useEffect(() => {
    let stable: Stable | undefined = undefined;

    // With only one stable it's simple.
    if (stables.length === 1) {
      stable = stables[0];
    }

    // When we have a preferred stable, then search for that one.
    if (!stable && accountDetails?.preferred_stable) {
      stable = stables.find(stable => stable.uid === accountDetails?.preferred_stable);
    }

    // Otherwise take the first stable.
    if (!stable && stables.length > 0) {
      stable = stables[0];
    }

    // Only set the stable if we not disable the stable field
    setValue('stable_uid', !isExternalFormValue ? stable?.uid : undefined);

    // Set the move_destination_uid to the stable location uid but only when the location field is not disabled
    setValue('move_destination_uid', !isExternalFormValue ? stable?.location.uid : undefined);
  }, [groups, contacts, setValue, stables, accountDetails?.preferred_stable, isExternalFormValue]);

  /**
   * If we change the stable_uid, we should also set the move_destination_uid as the stable_uid
   */
  useEffect(() => {
    // If the stableUidFormValue is set to undefined, it has not set yet
    if (stableUidFormValue === undefined) return;

    // if it has set to null, it means the user has reset th default value
    if (stableUidFormValue === null) {
      setValue('move_destination_uid', undefined);
      return;
    }

    // find the stable with the given stable_uid
    // and update the move_destination_uid with the location uid of the stable
    const stable = contacts.find(contact => contact.stable_location_uid === stableUidFormValue);
    if (stable) {
      setValue('move_destination_uid', stable.uid);
    }
  }, [contacts, setValue, stableUidFormValue, stables]);

  /**
   * check if the user either has given a name && ueln OR name && chipNr
   * We debounce  so we don't make api calls on every keystroke
   */
  useEffect(() => {
    if (!selectedOrganizationUid) return;

    // use a timeout so we can debounced our keystroke
    const timer = setTimeout(() => {
      let params:
        | {
            uelnIexact?: string;
            chipNrIexact?: string;
            nameIcontains?: string;
          }
        | undefined = undefined;

      // check if we need to set name and UELN or name and chip_nr
      if (nameFormValue && uelnFormValue) {
        params = {
          nameIcontains: nameFormValue,
          uelnIexact: uelnFormValue,
        };
      } else if (nameFormValue && chipNrFormValue) {
        params = {
          nameIcontains: nameFormValue,
          chipNrIexact: chipNrFormValue,
        };
      }

      // Search for horse in RVO, HorseTelex, etc for auto filling the form.
      if (uelnFormValue || chipNrFormValue || nameFormValue) {
        searchHorse(1);
      }

      // if we have set the params for the request, check if the horse can be found
      if (params) {
        const promise = HorsesService.horsesList({
          organisationUid: selectedOrganizationUid,
          hidden: true,
          onUnknownLocation: false,
          ...params,
        });
        promise
          .then(res => {
            if (res.results?.length) {
              const found = res.results[0];
              HorsesService.horsesRetrieve({ organisationUid: selectedOrganizationUid, uid: found.uid })
                .then(details => {
                  setFoundHorse({ details, selected: false });
                })
                .catch(error => console.error(error));
            } else {
              setFoundHorse(undefined);
            }
          })
          .catch(error => console.error(error));
      } else {
        setFoundHorse(undefined);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [nameFormValue, uelnFormValue, chipNrFormValue, selectedOrganizationUid, setSearchingHorse, searchHorse]);

  /**
   * reset the move_arrival_is_import value once the destinationContact has been set
   */
  useEffect(() => {
    if (destinationContact) {
      setValue('move_arrival_is_import', undefined);
    }
  }, [destinationContact, setValue]);

  /**
   * set the defaultName value to the name input
   */
  useEffect(() => {
    setValue('name', defaultName ?? '');
  }, [defaultName, setValue]);

  /**
   * Reset the state where we hold the created horse when opening the modal
   */
  useEffect(() => {
    if (open) {
      setCreatedHorse(undefined);
    }
  }, [open]);

  /**
   * Watch the is_external field and set the form value
   *
   * NOTE: We are using the watch as a subscription because we need this value in the schema
   * (and both watch and schema are defined in the useForm hook)
   */
  useEffect(() => {
    const subscription = watch(({ is_external }, { name }) => {
      if (name === 'is_external') {
        setIsExternalFormValue(is_external);
        // also save the value in the form for the usage fields
        setValue('use_in_breeding', is_external ? false : true);
        setValue('use_in_care', is_external ? false : true);
        setValue('use_in_sport', is_external ? false : true);
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  /**
   * Update the isExternalFormValue when the given isExternal value changes
   */
  useEffect(() => {
    setIsExternalFormValue(isExternal);
  }, [isExternal]);

  /**
   * Make sure we clear the selected horse and searched horses when both UELN, name and chip NR are undefined
   */
  useEffect(() => {
    if (!uelnFormValue && !nameFormValue && !chipNrFormValue) {
      setSearchHorses(undefined);
      setSearchHorseSelected(undefined);
    }
  }, [chipNrFormValue, nameFormValue, reset, uelnFormValue]);

  return (
    <>
      <PageModal
        open={open}
        onClosed={onClosed}
        parentElement='form'
        parentProps={{ id: 'addHorseForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('add-horse', 'Add horse')} onClose={onRequestCloseModal} />
        <PageModalContent>
          {/* api load errors */}
          {errorString && <ErrorSection errors={errorString} />}

          {/* Form errors */}

          {/* Show stable error when the stable field is hidden */}
          {stables.length <= 1 && <ErrorSection className='mb-4' errors={fieldError('stable_uid')} />}

          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='space-y-4'>
            <div
              className={classNames({
                // use a grid layout when we have fields to show on the right-side
                // disableLocation, disableStable and disableUsage are used to hide the fields on the left-side
                'grid md:grid-cols-2 gap-4': !isExternalFormValue,
              })}
            >
              <div className='flex flex-col gap-4'>
                {!foundHorse?.selected && (
                  <>
                    <TextInput
                      error={fieldError('name')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'name')}
                      label={t('name', 'Name')}
                      {...register('name', { setValueAs: transformEmptyToUndefined() })}
                    />
                    <TextInput
                      error={fieldError('UELN')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'UELN')}
                      label={t('horse_ueln', 'UELN')}
                      {...register('UELN', { setValueAs: transformEmptyToUndefined() })}
                      postText={searchingHorse ? <Spinner size={SpinnerSize.XSmall} /> : undefined}
                    />

                    <TextInput
                      // Chrome does not respect the autocomplete="off" attribute on the input field.
                      // So in this case Chrome think that chip number (translated in NL to chipnummer) is a cc field (match to trigger the cc autofill is the label name "*nummer")
                      // To prevent Chrome from autocomplete as CC we e use the "bday-day" autocomplete. bday-day is a numeric field (chip number is also numeric)
                      // @see https://issues.chromium.org/issues/41163264#comment165
                      // @see https://issues.chromium.org/issues/339033226
                      // @see https://gitlab.qubis.nl/equinem/equiapp/-/issues/202
                      autoComplete='bday-day'
                      error={fieldError('chip_nr')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'chip_nr')}
                      label={t('horse_chip_nr', 'Chip number')}
                      {...register('chip_nr', { setValueAs: transformEmptyToUndefined() })}
                    />

                    {searchHorseSelected && (
                      <SearchResultSearchedHorseRow horse={searchHorseSelected} selected={true} onClear={clearSelectedSearchedHorse} />
                    )}

                    {searchHorses && !foundHorse && (
                      <div className='max-h-80 overflow-y-auto'>
                        {searchHorses.items.length === 1 && (
                          <SearchResultSearchedHorseRow horse={searchHorses.items[0]} onSelect={onSelecteSearchedHorse} />
                        )}

                        {searchHorses.items.length > 1 && (
                          <p className='text-sm mb-1 border border-indigo-500 bg-indigo-50 p-2 rounded-md'>
                            <Trans
                              i18nKey='horse-add-search-result'
                              defaults='Multiple horses found on HorseTelex with this name, UELN or chipnumber. Click the button below to review the results and select one. <0>View search results</0>'
                              components={[
                                <Button
                                  type='button'
                                  variant={ButtonVariant.Primary}
                                  size={ButtonSize.XSmall}
                                  key='button'
                                  className='block mt-2'
                                  onClick={showModalSearchHorses}
                                >
                                  {t('view-results', 'View results')}
                                </Button>,
                              ]}
                            />
                          </p>
                        )}
                      </div>
                    )}

                    {!foundHorse?.selected && (
                      <SelectInput
                        error={fieldError('sex')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'sex')}
                        options={genders}
                        nullable={true}
                        nullableValue=''
                        label={t('gender', 'Gender')}
                        {...register('sex', { setValueAs: transformEmptyNumber(null) })}
                      />
                    )}

                    <TogggleInput
                      name='is_external'
                      control={control}
                      error={fieldError('is_external')}
                      label={t('is-external', 'Is external')}
                      hint={t('is-external-desc', 'This horse is not part of your stable')}
                    />
                  </>
                )}

                {foundHorse && (
                  <div>
                    {!foundHorse.selected && <Label>{t('existing_horse', 'Existing Horse')}</Label>}
                    {foundHorse.selected && <Label>{t('horse', 'Horse')}</Label>}

                    <div
                      className={classNames('mt-1 border rounded-md p-3 text-gray-500 font-medium text-sm relative', {
                        'border-indigo-500 bg-indigo-50': !foundHorse.selected,
                        'border-green-500 bg-green-50': foundHorse.selected,
                      })}
                    >
                      {foundHorse.selected && (
                        <button type='button' className='absolute right-2 top-2' onClick={() => toggleSelectedHorse(false)}>
                          <X size={20} />
                        </button>
                      )}

                      <div className='space-y-3'>
                        {!foundHorse.selected && (
                          <>
                            <p>{t('existing_horse_title', 'We have found an inactive horse with the same identification.')}</p>
                            <p>{t('existing_horse_sub_title', 'Do you want to use existing information?')}</p>
                          </>
                        )}

                        {foundHorse.selected && <p>{t('existing_horse_selected_title', 'You have selected the following horse')}</p>}

                        <div className='flex'>
                          <div className='flex-1 flex gap-3'>
                            <div className='w-16 h-16 rounded-lg overflow-hidden relative bg-gray-400 flex items-center justify-center shrink-0'>
                              {foundHorse.details.avatar_file && <img className='w-full h-full' src={foundHorse.details.avatar_file} />}
                              {!foundHorse.details.avatar_file && <HorseIcon width={35} height={35} className='text-gray-50' />}
                            </div>
                            <div className='flex flex-col'>
                              <span className='text-gray-700 font-semibold'>
                                {foundHorse.details.display_name}{' '}
                                {foundHorse.details.sex ? <span className='font-light'>({gender(foundHorse.details.sex, t)})</span> : ''}
                              </span>
                              <span>
                                {t('EULN', 'EULN')}
                                {':'} {foundHorse.details.UELN}
                              </span>
                              <span>
                                {t('chip_nr', 'Chip Nr')}
                                {':'} {foundHorse.details.chip_nr}
                              </span>
                            </div>
                          </div>
                          {!foundHorse.selected && (
                            <div className='flex space-x-2'>
                              <Button
                                size={ButtonSize.XSmall}
                                onClick={() => toggleSelectedHorse(true)}
                                variant={ButtonVariant.Primary}
                                type='button'
                              >
                                {t('use', 'Use')}
                              </Button>
                              <Button variant={ButtonVariant.Default} onClick={showBasicHorseModal} size={ButtonSize.XSmall} type='button'>
                                {t('view', 'View')}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {!isExternalFormValue && (
                <div className='flex flex-col gap-4'>
                  <DateInput
                    control={control}
                    error={fieldError('move_date')}
                    required={true}
                    label={t('date_of_arrival', 'Date of arrival')}
                    name='move_date'
                  />

                  {stables.length > 1 && (
                    <>
                      <SelectInput
                        loading={loading}
                        error={fieldError('stable_uid')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'stable_uid')}
                        nullable={true}
                        nullableValue=''
                        options={stableOptions}
                        label={t('stable', 'Stable')}
                        {...register('stable_uid', {
                          // we transform the empty value to null so we know if the user has reset the value
                          setValueAs: transformEmpty(null),
                        })}
                      />

                      <CheckboxInput
                        {...register('location_differs_from_stable')}
                        error={fieldError('location_differs_from_stable')}
                        label={t('location-differs-from-stable', 'Horse location differs from stable')}
                      />
                    </>
                  )}

                  <ContactInputSelect
                    className={classNames({
                      hidden: !(locationDiffersFromStableValue || stables.length === 1),
                    })}
                    loading={loading}
                    name='move_destination_uid'
                    control={control}
                    contacts={filteredContacts}
                    onCreated={newContact => {
                      setContacts(prevState => [...prevState, newContact]); // Update the list of contacts.
                      setValue('move_destination_uid', newContact.uid);
                    }}
                    label={t('location', 'Location')}
                    error={fieldError('move_destination_uid')}
                  />

                  <TogggleInput
                    className={classNames({
                      hidden: !fieldIsImportIsVisible,
                    })}
                    name='move_arrival_is_import'
                    control={control}
                    error={fieldError('move_arrival_is_import')}
                    label={t('is-imported', 'Is imported')}
                    hint={t('is-imported-description', 'Is this horse imported from a different country?')}
                  />

                  {!disableUsage && (
                    <div className='space-y-1'>
                      <Label>{t('horse-usage', 'Usage')}</Label>
                      <CheckboxInput
                        size={InputSize.XSmall}
                        {...register('use_in_care')}
                        error={fieldError('use_in_care')}
                        labelElement={
                          <div className='flex items-center gap-1'>
                            <CareBadge size={BadgeSize.Normal} />
                          </div>
                        }
                      />
                      <CheckboxInput
                        size={InputSize.XSmall}
                        {...register('use_in_sport')}
                        error={fieldError('use_in_sport')}
                        labelElement={
                          <div className='flex items-center gap-1'>
                            <SportBadge size={BadgeSize.Normal} />
                          </div>
                        }
                      />
                      <CheckboxInput
                        size={InputSize.XSmall}
                        {...register('use_in_breeding')}
                        error={fieldError('use_in_breeding')}
                        labelElement={
                          <div className='flex items-center gap-1'>
                            <BreedingBadge size={BadgeSize.Normal} />
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            {!foundHorse?.selected && (
              <Fieldset canToggle={true} legend={t('additional-horse-details', 'Additional horse details')} classNameWrapper='!mt-10'>
                {isOpen => (
                  <div className='flex flex-col md:flex-row gap-4'>
                    <div className='space-y-4 grow'>
                      <SelectInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('color')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'color')}
                        options={colors}
                        nullable={true}
                        nullableValue=''
                        label={t('color', 'Color')}
                        {...register('color', { setValueAs: transformEmptyNumber(null) })}
                      />

                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        postText='cm'
                        error={fieldError('withers_height')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'withers_height')}
                        label={t('withers-height', 'Withers height')}
                        {...register('withers_height', { setValueAs: transformEmptyNumber(null) })}
                      />

                      <DateInput
                        name='date_of_birth'
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        control={control}
                        error={fieldError('date_of_birth')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'date_of_birth')}
                        label={t('date_of_birth', 'Date of birth')}
                      />
                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('studbook')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'studbook')}
                        label={t('studbook', 'Studbook')}
                        {...register('studbook', { setValueAs: transformEmptyToUndefined() })}
                      />
                    </div>
                    <div className='space-y-4 grow'>
                      <SelectInput
                        loading={loading}
                        error={fieldError('group_uid')}
                        nullable={true}
                        nullableValue=''
                        required={zodInputIsRequired<HorseDetail>(schema, 'group_uid')}
                        options={groupOptions}
                        label={t('group', 'Group')}
                        {...register('group_uid', { setValueAs: transformEmptyToUndefined() })}
                      />
                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('sire')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'sire')}
                        label={t('sire', 'Sire')}
                        {...register('sire', { setValueAs: transformEmptyToUndefined() })}
                      />
                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('dam')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'dam')}
                        label={t('dam', 'Dam')}
                        {...register('dam', { setValueAs: transformEmptyToUndefined() })}
                      />
                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('damsire')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'damsire')}
                        label={t('damsire', 'Damsire')}
                        {...register('damsire', { setValueAs: transformEmptyToUndefined() })}
                      />
                    </div>
                  </div>
                )}
              </Fieldset>
            )}
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              disabled: loading || errorString !== undefined,
              loading: submitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'addHorseForm',
            },
          ]}
        />
        {searchHorses && (
          <SearchResultSearchedHorsesModal
            searchHorses={searchHorses}
            isVisible={modalIsVisiblSearchHorses}
            loading={searchingHorse}
            pageNr={searchHorsesPageNr}
            onPrevPage={() => searchHorse(searchHorsesPageNr - 1)}
            onNextPage={() => searchHorse(searchHorsesPageNr + 1)}
            onRequestClose={closeModalSearchHorses}
            onSelect={onSelecteSearchedHorse}
          />
        )}

        {foundHorse && (
          <HorseBasicDetailsModal open={basicHorseModalIsVisible} closeModal={closeBasicHorseModal} horse={foundHorse.details} />
        )}
      </PageModal>

      {createdHorse && (
        <RvoConfirmReportModal
          isVisible={modalIsVisiblRvo}
          onReport={() => navigate(generatePath(`${AppRoutes.HorsesRvoList.path}?horse=${createdHorse.uid}`))}
          onRequestClose={closeModalRvo}
          successMessage={t('horse-successfull-created-desc', 'Your horse has been successfully created.')}
        />
      )}
    </>
  );
}

export default CreateHorseModal;
