import { Float, FloatProps } from '@headlessui-float/react';
import { Combobox } from '@headlessui/react';
import { CaretUpDown, Check, PlusCircle, Warning, X } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Control, FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Hint } from 'ui/Hint';
import { InputError } from 'ui/InputError';
import { Label } from 'ui/Label';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';

/**
 * Interface that wrapped the combobox props as a group of items
 */
export interface WrappedComboboxProps<T> {
  items: T[];
  // the label that will be showed above the list of options
  heading?: string;
  // the label that will be showed when no options are given
  notFoundLabel?: string;
  // A custom icon for this list
  icon?: ReactNode;
}

/**
 * Complex type that extend some magic types from React Form hooks and include own fields
 *
 * See --> https://github.com/orgs/react-hook-form/discussions/7851#discussioncomment-2219298
 */
interface Props<
  T extends object,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  name: TName; // override from UseControllerProps and make it required
  control: Control<TFieldValues>; // override from UseControllerProps and make it required
  label?: string;
  hint?: string;
  error?: string;
  required?: boolean;
  options: WrappedComboboxProps<T>[];
  // you should return a new object of type T
  // This object will be auto selected in the list
  onCreate?: (name: string) => void;
  // event that is triggered when the user select an item
  onSelected?: (item: T | undefined) => void;
  // event that is triggerd when the user is filtering
  onFilter: (serachQuery: string, items: T[]) => T[];
  // diplay function that can be used to format the option
  displayOption: (item: T, selected: boolean, active: boolean, customIcon: ReactNode | undefined) => ReactNode;
  // diplay function that can be used to format the search input
  displayInput: (item: T | undefined) => string;
  // this is the field that we are used for the selected value
  // and for the key param
  idField: keyof T;
  // the label that will be showed if the item is not found
  notFoundLabel?: string;
  // the label that will be showed when no options are given
  noOptionsLabel?: string;
  // the label that will be showed when the create option is visible
  createLabel?: string;
  loading?: boolean;
  errorMessage?: string;
  placement?: FloatProps['placement'];
  className?: string;
  // if we pass a setValueAs function, we can transform the value that will be set to the control.setValue
  // just like the setValueAs from the ReactFromHook setValueAs
  setValueAs?: (value: string | null | undefined) => string | null | undefined;
}

/**
 * Create a selectList with the headless-ui Combobox
 */
function SelectList<
  T extends object,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hint,
  error,
  required,
  options,
  onCreate,
  onSelected,
  onFilter,
  displayOption,
  displayInput,
  idField,
  notFoundLabel: givenNotFoundLabel,
  noOptionsLabel: givenNoOptionsLabel,
  createLabel: givenCreateLabel,
  loading,
  errorMessage,
  placement = 'bottom-start',
  className,
  setValueAs,
  ...props
}: Props<T, TFieldValues, TName>): JSX.Element {
  const [findQuery, setFindQuery] = useState<string>('');
  const [fieldValue, setFieldValue] = useState<string | null | undefined>();
  const [focused, setFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState<T | null>(null);

  const refButton = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();
  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  // Pass a default label if none is given
  const notFoundLabel = givenNotFoundLabel ?? t('item-not-found', 'Item not found');
  const noOptionsLabel = givenNoOptionsLabel ?? t('no-options-available', 'No options available');
  const createLabel = givenCreateLabel ?? t('create-new', 'Create new');

  const inputRootClassName = classNames(
    'group w-full border flex items-center relative space-x-1.5 rounded-md transition-all duration-200 focus:outline-none px-1',
    {
      'border-primary': focused && !props.disabled,
      'bg-gray-100 border-gray-200 cursor-not-allowed': props.disabled,
      'border-rose-400': error,
      'bg-white': !props.disabled,
    },
  );

  /**
   * Return the filtered list based on the query
   */
  const filteredOptions = useMemo(() => {
    // This should be a deep clone of the options
    // otherwise the component will not re-render
    const clonedOptions: WrappedComboboxProps<T>[] = [];
    for (const option of options) {
      clonedOptions.push({
        ...option,
        items: onFilter(findQuery, option.items),
      });
    }

    return clonedOptions;
  }, [findQuery, options]); //eslint-disable-line

  const hasOptions = filteredOptions.reduce((prev, current) => prev + current.items.length, 0) > 0;

  // flag that indicate we should show the not found item
  const showNotFoundItem = onCreate === undefined && filteredOptions.length === 0 && findQuery !== '';

  // define the default classNames
  const optionClassName = classNames('relative cursor-pointer select-none py-2 px-2 text-sm', {
    'pl-8': selectedOption,
  });
  const emptyOptionClassName = classNames(optionClassName, '!cursor-default !px-4 text-gray-700 flex items-center gap-x-2');

  /**
   * Transform the value to the given format by the user before we set the value to the field
   * or return the value as it is
   */
  const setValueAsFn = useCallback(
    (value: string | null | undefined): string | null | undefined => {
      if (setValueAs) {
        return setValueAs(value);
      }

      return value;
    },
    [setValueAs],
  );

  /**
   * Reset the state of the component
   */
  const resetState = useCallback(
    (inclFindQuery = true) => {
      field.onChange(setValueAsFn(undefined));
      if (inclFindQuery) {
        setFindQuery('');
      }
      onSelected?.(undefined);
      setSelectedOption(null); // this will reset the selected item but also trigger a re-render, so it should be the last one
    },
    [field, onSelected, setValueAsFn],
  );

  /**
   * Detect if the field value is undefined and we should reset the state
   * This way we can act when the user is doing setValue('destination_uid', undefined); to reset the state
   */
  useEffect(() => {
    // we reset the state only if the new field.value is undefined and the old
    // one was set to a value
    if (field.value !== fieldValue && (field.value === undefined || field.value === '')) {
      resetState();
    }

    // save the field for later use in the useEffect comparison
    setFieldValue(field.value);
  }, [field.value, fieldValue, resetState]);

  /**
   * detect and select a selected form.value item
   */
  useEffect(() => {
    for (const option of options) {
      const isFound = option.items.find(item => String(item[idField]) === field.value);
      if (isFound) {
        setSelectedOption(isFound);
        break;
      }
    }
  }, [field.value, idField, options]);

  /**
   * Reset the query and the selected item when the options are empty
   */
  useEffect(() => {
    if (options.length === 0) {
      setFindQuery('');
      setSelectedOption(null);
    }
  }, [options]);

  return (
    <div className={classNames('group relative', className)}>
      {label && (
        <Label className='mb-2'>
          {label} {required && '*'}
        </Label>
      )}
      <Combobox<T | null | 'create-new' | 'clear-selection'>
        disabled={props.disabled}
        value={selectedOption}
        defaultValue={null}
        onChange={value => {
          // detect if the magic name "create-new" has been selected
          // if so, fir the onCreate event
          if (value === 'create-new') {
            onCreate?.(findQuery);
            setFindQuery('');
          } else if (value === 'clear-selection') {
            resetState();
            // field.onChange(String(value[idField]));
          } else if (value !== null && value !== undefined) {
            setSelectedOption(value);
            onSelected?.(value);
            // we send a change of the idField value, mostly this will be the UID/ID
            field.onChange(setValueAsFn(String(value[idField])));
          }
        }}
      >
        {({ open }) => (
          <div className='relative mt-1'>
            <Float
              show={open}
              placement={placement}
              composable
              adaptiveWidth={true}
              portal={true}
              onHide={() => setFindQuery('')}
              as={Fragment}
              className='w-full'
              enter='transition duration-200 ease-out'
              enter-from='opacity-0 -translate-y-1'
              enter-to='opacity-100 translate-y-0'
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Float.Reference>
                <div className={inputRootClassName}>
                  <Combobox.Input<T>
                    onFocus={() => setFocused(true)}
                    onClick={() => {
                      // when the user click the input element we should show the options directly
                      // this way we mimic the behavior of a select element
                      refButton.current?.click();
                    }}
                    onBlur={() => setFocused(false)}
                    className={classNames('w-full border-none py-2 pl-3 text-sm leading-5 text-gray-900 focus:ring-0 focus:outline-none', {
                      'bg-gray-100 border-gray-200 cursor-not-allowed': props.disabled,
                      'cursor-pointer': !props.disabled && !focused,
                      'cursor-text': !props.disabled && focused,
                      'pr-10': !selectedOption,
                      'pr-12': selectedOption,
                    })}
                    autoComplete='off'
                    onChange={event => {
                      setFindQuery(event.target.value);
                      // reset the selected item when the user change the input
                      // otherwise it will be sticked to the selected item
                      resetState(false);
                    }}
                    displayValue={value => displayInput(value)}
                  />

                  {selectedOption && !required && (
                    <button
                      type='button'
                      onClick={() => resetState()}
                      className='absolute inset-y-0 right-9 flex items-center bg-gray-50 border p-1 rounded-full m-1.5 w-6 h-6'
                    >
                      <X />
                    </button>
                  )}

                  <Combobox.Button
                    ref={refButton}
                    className='absolute inset-y-0 right-0 flex items-center pr-3 pl-1'
                    onClick={e => e.stopPropagation()}
                  >
                    <CaretUpDown className='h-5 w-5 text-gray-600' aria-hidden='true' />
                  </Combobox.Button>
                </div>
              </Float.Reference>

              <Float.Content>
                <Combobox.Options className='max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  {/* Loading state */}
                  {loading && (
                    <div className='relative cursor-default select-none py-2 px-4 text-gray-700 text-sm flex gap-x-1 items-center'>
                      <Spinner size={SpinnerSize.XSmall} />
                      {t('loading', 'Loading')}...
                    </div>
                  )}

                  {/* error state */}
                  {!loading && filteredOptions.length === 0 && errorMessage && (
                    <div className={emptyOptionClassName}>
                      <Warning /> {errorMessage}
                    </div>
                  )}

                  {/* No results */}
                  {!loading && showNotFoundItem && <div className={emptyOptionClassName}>{notFoundLabel}</div>}

                  {/* No options given */}
                  {onCreate === undefined && findQuery === '' && filteredOptions.length === 0 && (
                    <div className={emptyOptionClassName}>{noOptionsLabel}</div>
                  )}

                  {!loading &&
                    !showNotFoundItem &&
                    filteredOptions.map((optionHeading, index) => (
                      <Fragment key={optionHeading.heading ?? index}>
                        {/* We only show this headings when we have more than 1 group */}
                        {filteredOptions.length > 1 && optionHeading.heading && hasOptions && (
                          <>
                            <Combobox.Option
                              disabled={true}
                              value={optionHeading.heading}
                              className={classNames(optionClassName, ' font-semibold text-gray-800')}
                            >
                              {optionHeading.heading}
                            </Combobox.Option>

                            {optionHeading.items.length === 0 && (
                              <Combobox.Option value={optionHeading.notFoundLabel} className={optionClassName} disabled={true}>
                                {optionHeading.notFoundLabel ?? t('no-options-available', 'No options available')}
                              </Combobox.Option>
                            )}
                          </>
                        )}

                        {optionHeading.items.map(option => (
                          <Combobox.Option
                            key={String(option[idField])}
                            className={({ active }) =>
                              classNames(optionClassName, {
                                'bg-gray-200 text-gray-800': active,
                                'text-gray-900': !active,
                              })
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <div className='flex gap-2 items-center'>
                                {selected && (
                                  <span
                                    className={classNames('absolute inset-y-0 left-2 flex items-center', {
                                      'text-white': active,
                                      'text-blue-600': !active,
                                    })}
                                  >
                                    <Check size={16} aria-hidden='true' />
                                  </span>
                                )}
                                {displayOption(option, selected, active, optionHeading.icon)}
                              </div>
                            )}
                          </Combobox.Option>
                        ))}
                      </Fragment>
                    ))}

                  {/* Inject the create option */}
                  {!loading && onCreate !== undefined && (
                    <Combobox.Option
                      className={classNames('font-semibold bg-blue-600 text-white flex gap-x-2 items-center', optionClassName)}
                      value='create-new'
                    >
                      <PlusCircle size={28} />
                      {findQuery && (
                        <>
                          {createLabel}: {findQuery}
                        </>
                      )}
                      {!findQuery && createLabel}
                    </Combobox.Option>
                  )}
                </Combobox.Options>
              </Float.Content>
            </Float>
          </div>
        )}
      </Combobox>
      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
}

export default SelectList;
