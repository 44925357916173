/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `full` - Full
 * * `limited for training` - Limited for training
 * * `limited for service` - Limited for service
 * * `limited for service and training` - Limited for service and training
 * * `not employable` - Not employable
 */
export enum EmployabilityStatusEnum {
  FULL = 'full',
  LIMITED_FOR_TRAINING = 'limited for training',
  LIMITED_FOR_SERVICE = 'limited for service',
  LIMITED_FOR_SERVICE_AND_TRAINING = 'limited for service and training',
  NOT_EMPLOYABLE = 'not employable',
}
