/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedCatalogueProductList } from '../models/PaginatedCatalogueProductList';
import type { PaginatedFertilizerNormList } from '../models/PaginatedFertilizerNormList';
import type { PaginatedVATPercentageList } from '../models/PaginatedVATPercentageList';
import type { PatchedPurchaserOrderUpdate } from '../models/PatchedPurchaserOrderUpdate';
import type { PaymentLink } from '../models/PaymentLink';
import type { PublicHorsePage } from '../models/PublicHorsePage';
import type { PublicInvoice } from '../models/PublicInvoice';
import type { PublicOrganisation } from '../models/PublicOrganisation';
import type { PurchaserOrderUpdate } from '../models/PurchaserOrderUpdate';
import type { Redirect } from '../models/Redirect';
import type { SupplierOrderDetail } from '../models/SupplierOrderDetail';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PublicService {
  /**
   * @returns PaginatedFertilizerNormList
   * @throws ApiError
   */
  public static apiV5AdministrationManureFertilizerNormsList({
    year,
    o,
    page,
    pageSize,
  }: {
    year: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedFertilizerNormList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/administration/manure/fertilizer_norms/{year}',
      path: {
        'year': year,
      },
      query: {
        'o': o,
        'page': page,
        'page_size': pageSize,
      },
    });
  }
  /**
   * Publicly accessible endpoint to retrieve a specific horsepage.
   * @returns PublicHorsePage
   * @throws ApiError
   */
  public static apiV5HorsepagesRetrieve({
    publicAccessUuid,
  }: {
    publicAccessUuid: string,
  }): CancelablePromise<PublicHorsePage> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/horsepages/{public_access_uuid}',
      path: {
        'public_access_uuid': publicAccessUuid,
      },
    });
  }
  /**
   * Publicly accessible endpoint to retrieve a specific invoice.
   * @returns PublicInvoice
   * @throws ApiError
   */
  public static apiV5InvoicesRetrieve({
    publicAccessUuid,
  }: {
    publicAccessUuid: string,
  }): CancelablePromise<PublicInvoice> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/invoices/{public_access_uuid}',
      path: {
        'public_access_uuid': publicAccessUuid,
      },
    });
  }
  /**
   * Publicly accessible endpoint to retrieve a specific invoice.
   * @returns Redirect
   * @throws ApiError
   */
  public static apiV5InvoicesMolliePaymentLinkCreate({
    publicAccessUuid,
    requestBody,
  }: {
    publicAccessUuid: string,
    requestBody: PaymentLink,
  }): CancelablePromise<Redirect> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/invoices/{public_access_uuid}/mollie_payment_link',
      path: {
        'public_access_uuid': publicAccessUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders _not_ made by the (logged-in) purchaser (typically a horse breeder) may not have a purchaser because
   * the supplier doesn't know the purchaser's organisation. To facilitate a purchaser lookup, the order has a
   * `public_access_uuid` field. Knowing the uuid allows any logged-in user to:
   * - look up the respective order;
   * - patch the purchaser field to any organisation accessible to that user.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static apiV5OrdersRetrieve({
    publicAccessUuid,
  }: {
    publicAccessUuid: string,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/orders/{public_access_uuid}',
      path: {
        'public_access_uuid': publicAccessUuid,
      },
    });
  }
  /**
   * Orders _not_ made by the (logged-in) purchaser (typically a horse breeder) may not have a purchaser because
   * the supplier doesn't know the purchaser's organisation. To facilitate a purchaser lookup, the order has a
   * `public_access_uuid` field. Knowing the uuid allows any logged-in user to:
   * - look up the respective order;
   * - patch the purchaser field to any organisation accessible to that user.
   * @returns PurchaserOrderUpdate
   * @throws ApiError
   */
  public static apiV5OrdersUpdate({
    publicAccessUuid,
    requestBody,
  }: {
    publicAccessUuid: string,
    requestBody: PurchaserOrderUpdate,
  }): CancelablePromise<PurchaserOrderUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/orders/{public_access_uuid}',
      path: {
        'public_access_uuid': publicAccessUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders _not_ made by the (logged-in) purchaser (typically a horse breeder) may not have a purchaser because
   * the supplier doesn't know the purchaser's organisation. To facilitate a purchaser lookup, the order has a
   * `public_access_uuid` field. Knowing the uuid allows any logged-in user to:
   * - look up the respective order;
   * - patch the purchaser field to any organisation accessible to that user.
   * @returns PurchaserOrderUpdate
   * @throws ApiError
   */
  public static apiV5OrdersPartialUpdate({
    publicAccessUuid,
    requestBody,
  }: {
    publicAccessUuid: string,
    requestBody?: PatchedPurchaserOrderUpdate,
  }): CancelablePromise<PurchaserOrderUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/orders/{public_access_uuid}',
      path: {
        'public_access_uuid': publicAccessUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders _not_ made by the (logged-in) purchaser (typically a horse breeder) may not have a purchaser because
   * the supplier doesn't know the purchaser's organisation. To facilitate a purchaser lookup, the order has a
   * `public_access_uuid` field. Knowing the uuid allows any logged-in user to:
   * - look up the respective order;
   * - patch the purchaser field to any organisation accessible to that user.
   * @returns Redirect
   * @throws ApiError
   */
  public static apiV5OrdersMolliePaymentLinkCreate({
    publicAccessUuid,
    requestBody,
  }: {
    publicAccessUuid: string,
    requestBody: PaymentLink,
  }): CancelablePromise<Redirect> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/orders/{public_access_uuid}/mollie_payment_link',
      path: {
        'public_access_uuid': publicAccessUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **PUBLIC** endpoint to retrieve the provided organisation's catalog of products that can be ordered.
   * Note that products come in various categories and that the fields will differ per category. For example a BREEDING
   * product will nest a stallion, and a SHIPPING product will have a not-null shipping_provider_name.
   *
   * POST to the organisation/products endpoint to add products to your organisation's catalog.
   * @returns PaginatedCatalogueProductList
   * @throws ApiError
   */
  public static apiV5PublicOrganisationsProductCatalogueList({
    organisationPublicAccessUuid,
    category,
    categoryDefaultIn,
    currentPriceGte,
    currentPriceLte,
    customType,
    customTypeIn,
    freshAvailable,
    frozenAvailable,
    futurePriceGte,
    futurePriceLte,
    hidden,
    isCustomProduct,
    nameIcontains,
    o,
    page,
    pageSize,
    planModule,
    planModuleIn,
    shippingCountriesIcontains,
    shippingProviderNameIcontains,
    shippingServiceType,
    stallionUid,
    stallionUidIn,
  }: {
    organisationPublicAccessUuid: string,
    category?: number,
    /**
     * Multiple values may be separated by commas.
     */
    categoryDefaultIn?: Array<string>,
    /**
     * Current price is greater than or equal to
     */
    currentPriceGte?: number,
    /**
     * Current price is smaller than or equal to
     */
    currentPriceLte?: number,
    /**
     * Some use cases require a custom product that should be configured by the supplying organisation. For example: the service of supplying a health certificate to a semen order. Select if this product belongs to a specific use case and which one. This will be typically be empty.
     *
     * * `HEALTH_CERTIFICATE` - health certificate
     * * `BREEDING_ADMINISTRATION` - breeding administration
     */
    customType?: 'BREEDING_ADMINISTRATION' | 'HEALTH_CERTIFICATE',
    /**
     * Multiple values may be separated by commas.
     */
    customTypeIn?: Array<string>,
    freshAvailable?: boolean,
    frozenAvailable?: boolean,
    /**
     * Future price is greater than or equal to
     */
    futurePriceGte?: number,
    /**
     * Future price is smaller than or equal to
     */
    futurePriceLte?: number,
    hidden?: boolean,
    isCustomProduct?: boolean,
    nameIcontains?: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * Required when the plan should restrict access to active service contracts.
     *
     * * `ACCOUNTING` - Accounting
     * * `SHOP_AND_BREEDING` - Shop and breeding
     * * `FEED_AND_MEDICINE` - Feed and medicine
     * * `FACILITY_PLANNING` - Facility planning
     * * `FINANCIAL_AND_PAYMENT` - Financial and payment
     * * `USER_ACCOUNTS` - User accounts
     * * `HORSE_SERVICES_CARE` - Horse services, care
     * * `HORSE_SERVICES_FULL` - Horse services, full
     * * `RVO_REPORTS` - RvO reports
     */
    planModule?: 'ACCOUNTING' | 'FACILITY_PLANNING' | 'FEED_AND_MEDICINE' | 'FINANCIAL_AND_PAYMENT' | 'HORSE_SERVICES_CARE' | 'HORSE_SERVICES_FULL' | 'RVO_REPORTS' | 'SHOP_AND_BREEDING' | 'USER_ACCOUNTS',
    /**
     * Multiple values may be separated by commas.
     */
    planModuleIn?: Array<string>,
    shippingCountriesIcontains?: string,
    shippingProviderNameIcontains?: string,
    /**
     * * `REGULAR` - Regular
     * * `PICK_UP` - Pick up
     * * `SAME_DAY_DELIVERY` - Same day delivery
     * * `NEXT_DAY_DELIVERY` - Next day delivery
     * * `SUNDAY_HOLIDAY_DELIVERY` - Sunday and holiday delivery
     * * `STAFF_DELIVERY` - staff delivery
     */
    shippingServiceType?: 'NEXT_DAY_DELIVERY' | 'PICK_UP' | 'REGULAR' | 'SAME_DAY_DELIVERY' | 'STAFF_DELIVERY' | 'SUNDAY_HOLIDAY_DELIVERY',
    stallionUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    stallionUidIn?: Array<string>,
  }): CancelablePromise<PaginatedCatalogueProductList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/public_organisations/{organisation__public_access_uuid}/product_catalogue',
      path: {
        'organisation__public_access_uuid': organisationPublicAccessUuid,
      },
      query: {
        'category': category,
        'category__default__in': categoryDefaultIn,
        'current_price_gte': currentPriceGte,
        'current_price_lte': currentPriceLte,
        'custom_type': customType,
        'custom_type__in': customTypeIn,
        'fresh_available': freshAvailable,
        'frozen_available': frozenAvailable,
        'future_price_gte': futurePriceGte,
        'future_price_lte': futurePriceLte,
        'hidden': hidden,
        'is_custom_product': isCustomProduct,
        'name__icontains': nameIcontains,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'plan__module': planModule,
        'plan__module__in': planModuleIn,
        'shipping_countries__icontains': shippingCountriesIcontains,
        'shipping_provider_name__icontains': shippingProviderNameIcontains,
        'shipping_service_type': shippingServiceType,
        'stallion__uid': stallionUid,
        'stallion__uid__in': stallionUidIn,
      },
    });
  }
  /**
   * **PUBLIC** endpoint to retrieve the VAT percentages set by the organization belonging to the provided uuid.
   *
   * For more info on the VAT percentage rules see `/api/v5/organisations/{organisation__uid}/vatpercentages`
   * @returns PaginatedVATPercentageList
   * @throws ApiError
   */
  public static apiV5PublicOrganisationsVatPercentagesList({
    organisationPublicAccessUuid,
    countryIexact,
    isActive,
    o,
    page,
    pageSize,
    vatCategory,
    vatLevel,
    vatLevelIn,
  }: {
    organisationPublicAccessUuid: string,
    countryIexact?: string,
    /**
     * The vat percentage is currently applicable for this country and product category
     */
    isActive?: boolean,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * * `S` - Normal sale
     * * `AE` - Reverse-charging VAT
     * * `G` - Export
     */
    vatCategory?: 'AE' | 'G' | 'S',
    /**
     * * `STANDARD` - standard
     * * `REDUCED` - reduced
     * * `SECOND_REDUCED` - second reduced
     * * `SUPER_REDUCED` - super reduced
     * * `ZERO` - zero
     */
    vatLevel?: 'REDUCED' | 'SECOND_REDUCED' | 'STANDARD' | 'SUPER_REDUCED' | 'ZERO',
    /**
     * Multiple values may be separated by commas.
     */
    vatLevelIn?: Array<string>,
  }): CancelablePromise<PaginatedVATPercentageList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/public_organisations/{organisation__public_access_uuid}/vat_percentages',
      path: {
        'organisation__public_access_uuid': organisationPublicAccessUuid,
      },
      query: {
        'country__iexact': countryIexact,
        'is_active': isActive,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'vat_category': vatCategory,
        'vat_level': vatLevel,
        'vat_level__in': vatLevelIn,
      },
    });
  }
  /**
   * **PUBLIC** endpoint to retrieve the public information (name) of an organisation.
   * @returns PublicOrganisation
   * @throws ApiError
   */
  public static apiV5PublicOrganisationsRetrieve({
    publicAccessUuid,
  }: {
    publicAccessUuid: string,
  }): CancelablePromise<PublicOrganisation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/public_organisations/{public_access_uuid}',
      path: {
        'public_access_uuid': publicAccessUuid,
      },
    });
  }
}
