import { GroupByApplied } from 'context/Calendar';
import { useOrganization } from 'context/OrganizationContext';
import { PlanningContext } from 'context/PlanningContext';
import { addDays, addWeeks, startOfDay, startOfWeek } from 'date-fns';
import {
  ActivitiesService,
  ActivityContactRole,
  ActivityType,
  ActivitytypesService,
  ActivityTypeCategoryEnum,
  Contact,
  DailyNote,
  DailynoteService,
  DailyNoteSetDoneAndUnDone,
  DatetimeOrDateField,
  DayPartStartTime,
  FacilitiesService,
  Facility,
  FacilityEvent,
  EventsToChangeEnum,
  Horse,
  HorseGroup,
  HorsegroupsService,
  ModulePermissionsEnum,
  PatchedRealActivitiesUpdate,
  RealActivities,
  Role,
  RolesService,
  Stable,
  StablesService,
  ContactsService,
} from 'openapi';
import { RealActivitiesSetDoneAndUnDone } from 'openapi/models/RealActivitiesSetDoneAndUnDone';
import { DaypartsService } from 'openapi/services/DaypartsService';
import { Dispatch, SetStateAction, useCallback, useContext, useMemo } from 'react';
import { ApiPromises } from 'utilities/ApiPromises';
import { activeContacts, activeOrganizationHorses } from 'utilities/ApiRequests';
import { formatDate } from 'utilities/date.utilities';
import {
  BluePrint,
  BluePrintState,
  CalendarActivity,
  CalendarActivityBase,
  CalendarActivityType,
  CalendarView,
  DragActivity,
  ExtraTargets,
  getActivityTypeCategory,
  GroupBy,
  Reshaping,
  SelectedActivity,
  SelectedActivityState,
  TimeScale,
  ViewType,
  WorkingHours,
} from 'utilities/Planning';
import usePermissions from './UsePermissions';
import ApiErrorParser, { ApiErrorParserBase } from 'api/ApiErrorParser';
import { useTranslation } from 'react-i18next';
import { informalHorseName } from 'utilities/Horse';

export interface PlanningHook {
  viewType: ViewType;
  setViewType: (viewType: ViewType) => void;
  timeScale: TimeScale;
  setTimeScale: (timeScale: TimeScale) => void;
  groupBy: GroupBy;
  setGroupBy: (groupBy: GroupBy) => void;
  offset: number;
  setOffset: (offset: number) => void;
  previousOffset?: number;

  apiPromises?: ApiPromises;
  activities?: CalendarActivity[];
  activityTypes?: ActivityType[]; // Sorted by usage
  unsortedActivityTypes?: ActivityType[];
  dayParts?: DayPartStartTime[];
  contacts?: Contact[];
  horses?: Horse[];
  stables?: Stable[];
  facilities?: Facility[];
  groups?: HorseGroup[];
  roles?: Role[];

  calendar?: CalendarView;

  // Load all data from the api
  loadApiData: (excludeActivities?: boolean) => ApiPromises;
  // Only reload the activies from the api.
  loadActivityApiData: (stables?: Stable[]) => ApiPromises;

  // Manually set the activities fetched from the api. This can be used when we want to
  // load a custom set of activities. Otherwise the loadActivityApiData will stand.
  setActivitiesData: Dispatch<SetStateAction<RealActivities[] | undefined>> | Dispatch<SetStateAction<RealActivities[]>>;

  markActivityAsDone: (calendarActivity: CalendarActivityBase, done: boolean) => Promise<void>;

  realActivityByUid: (uid: string) => RealActivities | undefined;

  removeActivity: (calendarActivity: CalendarActivity, eventsToChange?: EventsToChangeEnum) => Promise<void>;

  moveActivityToDayPart: (uid: string, date: Date, dayPart: number, activityIndex: number, horseUid: string | undefined) => Promise<void>;

  moveActivityToDay: (uid: string, date: Date, horseUid: string | undefined) => Promise<void>;

  moveActivityToContact: (
    uid: string,
    fromContactUid: string | undefined,
    toContactUid: string | undefined,
    date: Date | undefined,
    dayPart: number | undefined,
    activityIndex: number | undefined,
  ) => Promise<void>;

  showContactToStaffPlanning: (contactUid: string, addToPlanning: boolean) => Promise<void>;

  setLastUsedActivityType: (activityType: ActivityType) => void;
  lastUsedActivityType?: ActivityType;

  bluePrint?: BluePrint;
  reshaping?: Reshaping;
  requestBluePrint: (bluePrint: BluePrint) => void;
  requestReshaping: (reshaping?: Reshaping) => void;
  unsetBluePrint: (delay?: number) => void;

  /**
   * Set a selected activity
   *
   * @param groupByUid In the Staff planning, the same activity can be displayed more than once when we assign multiple
   * contacts to it. Therefor we can specify for which contact the activity is selected.
   */
  setSelectedActivity: (activity: CalendarActivity, state: SelectedActivityState, groupByUid?: string) => void;
  clearSelectedActivity: () => void;
  selectedActivity: SelectedActivity | undefined;

  setDragActivity: (activity?: DragActivity) => void;
  dragActivity?: DragActivity;

  // Users can click on a specific horse/contact/etc. It will be expanded into a 24h time view.
  setSelectedGroupBy: (groupBy?: GroupByApplied) => void;
  selectedGroupBy?: GroupByApplied;

  // Returns the day part index based on the start/end dates of the loaded DayParts.
  getDayPartForTime: (hours: number, minutes: number) => number;

  // Update an existing horse with a new version of it within the local horse cache.
  updateHorseInCache: (horse: Horse) => void;

  // Use this to display an error message to the user in an action modal.
  modalError: string | undefined;
  setModalError: (errorMessage: string | ApiErrorParserBase) => void;
  clearModalError: () => void;

  // The working hours so we can hide the nightly hours in our timescale view.
  workingHours: WorkingHours;
  hideNonWorkingHours: boolean;
  setHideNonWorkingHours: (hide: boolean) => void;

  // This is meant for the multi add functionality in the horse overview.
  // It saves a real activity from the blueprint with the given extra target.
  // We can test if the selected blueprint moments verify on the fly by using dryRun.
  saveActivityExtraTarget: (extraTarget: ExtraTargets, dryRun: boolean) => Promise<void>;
}

export const usePlanning = (): PlanningHook => {
  const {
    // Basic configuration options
    viewType,
    setViewType: setViewTypeHook,
    timeScale,
    setTimeScale,
    groupBy,
    setGroupBy,

    // Api promises object for loading the required api data
    apiPromises,

    // Required Api data
    setApiPromises,
    activities,
    setDailyNotes,
    setActivities,
    dayParts,
    setDayParts,
    horses,
    setHorses,
    contacts,
    setContacts,
    activityTypes,
    setActivityTypes,
    groups,
    setGroups,
    stables,
    setStables,
    facilities,
    setFacilities,
    setFacilityEvents,
    facilityEvents,
    roles,
    setRoles,

    // We keep track of which activity type is last used so we can select it by default when quickly adding new activities.
    lastUsedActivityTypeUid,
    setLastUsedActivityTypeUid,

    // The view offset (0 is today). It also keeps track of the previous offset.
    historicOffset,
    setHistoricOffset,

    processedActivities,

    // Canvas related fields and methods
    bluePrint,
    requestBluePrint,
    reshaping,
    requestReshaping,
    selectedActivity,
    setSelectedActivity,
    clearSelectedActivity,
    dragActivity,
    setDragActivity,
    selectedGroupBy,
    setSelectedGroupBy,
    dailyNotes,
    modalError,
    setModalError,

    workingHours,
    hideNonWorkingHours,
    setHideNonWorkingHours,
  } = useContext(PlanningContext);

  const { hasPermission } = usePermissions();

  const { t } = useTranslation();

  const { selectedOrganizationUid, generateCacheKey } = useOrganization();

  const setOffset = useCallback(
    (offset: number) => {
      setHistoricOffset({ current: offset, previous: historicOffset.current });
    },
    [historicOffset, setHistoricOffset],
  );

  const unsetBluePrint = useCallback(
    (delay?: number) => {
      if (delay === 0) {
        requestBluePrint(undefined);
      } else {
        requestBluePrint({ ...bluePrint, state: BluePrintState.Selected });
        setTimeout(() => {
          requestBluePrint(undefined);
        }, delay ?? 300);
      }
    },
    [requestBluePrint, bluePrint],
  );

  const setViewType = (viewTypeVal: ViewType) => {
    // Update the offset accordingly when switching between weeks and days.
    // When the offset is 1 in a dayview then it's tomorrow. But in a weekview it's next week.
    // This needs to be translated.
    if (viewType === ViewType.Week && viewTypeVal === ViewType.Day) {
      setOffset(historicOffset.current * 7);
    } else if (viewType === ViewType.Day && viewTypeVal === ViewType.Week) {
      setOffset(Math.floor(historicOffset.current / 7));
    } else {
      setOffset(0);
    }
    setViewTypeHook(viewTypeVal);
  };

  const realActivityByUid = (uid: string): RealActivities | undefined => {
    return activities?.find(realActivity => realActivity.uid === uid);
  };

  // Load data from the api/cache
  const loadActivityApiData = useCallback(
    (stables?: Stable[]): ApiPromises => {
      let startLt: Date | undefined;
      let endGt: Date | undefined;

      const today = new Date();
      if (viewType === ViewType.Day) {
        today.setDate(today.getDate() + historicOffset.current);
        startLt = today;
        endGt = today;
      } else if (viewType === ViewType.ThreeDay) {
        const today = new Date();
        endGt = addDays(startOfDay(today), historicOffset.current * 3);
        startLt = addDays(endGt, 2);
      } else if (viewType === ViewType.Week) {
        const today = new Date();
        endGt = addWeeks(startOfWeek(today, { weekStartsOn: 1 }), historicOffset.current);
        startLt = addDays(endGt, 6);
      } else {
        throw Error('Unimplemented');
      }
      const promises = new ApiPromises();
      if (!selectedOrganizationUid) {
        return promises;
      }
      // We have to add one day because it's a lower then query param.
      startLt = addDays(startLt, 1);

      if (groupBy === GroupBy.Facility || groupBy === GroupBy.FacilityAvailability) {
        promises.appendList<FacilityEvent>(
          'facility-events',
          () =>
            FacilitiesService.facilitiesEventsList({
              dtstartLt: startLt ? formatDate(startLt) : '',
              dtendGt: endGt ? formatDate(endGt) : '',
              organisationUid: selectedOrganizationUid,
              // @FIXME: Our api expects comma separated arrays. But the api code gen does ?param=a&param=b . Therefor we need to add one array item with the values comma separated.
              // See equinem/equinemcore#576
              facilityStableUidIn: stables ? [stables?.map(stable => stable.uid).join(',')] : undefined,
            }),
          setFacilityEvents,
        );
      }

      if (groupBy !== GroupBy.Facility && groupBy !== GroupBy.FacilityAvailability) {
        promises.appendList<RealActivities>(
          'activities',
          () =>
            ActivitiesService.activitiesList({
              dtstartLt: startLt ? formatDate(startLt) : '',
              dtendGt: endGt ? formatDate(endGt) : '',
              organisationUid: selectedOrganizationUid,
              // @FIXME: Our api expects comma separated arrays. But the api code gen does ?param=a&param=b . Therefor we need to add one array item with the values comma separated.
              // See equinem/equinemcore#576
              horseStableUidIn: stables ? [stables?.map(stable => stable.uid).join(',')] : undefined,
            }),
          setActivities,
        );

        promises.appendList<DailyNote>(
          'dailyNotes',
          () =>
            DailynoteService.dailynoteList({
              dtstartLt: startLt ? formatDate(startLt) : '',
              dtendGt: endGt ? formatDate(endGt) : '',
              organisationUid: selectedOrganizationUid,
              // @FIXME: Our api expects comma separated arrays. But the api code gen does ?param=a&param=b . Therefor we need to add one array item with the values comma separated.
              // See equinem/equinemcore#576
              stableUidIn: stables ? [stables?.map(stable => stable.uid).join(',')] : undefined,
            }),
          setDailyNotes,
        );
      }
      setApiPromises(promises);
      return promises;
    },
    [historicOffset, viewType, selectedOrganizationUid, setActivities, setDailyNotes, setFacilityEvents, groupBy, setApiPromises],
  );

  // Load data from the api/cache
  const loadApiData = useCallback(
    (excludeActivities?: boolean): ApiPromises => {
      let promises = new ApiPromises();
      if (!excludeActivities) {
        promises = loadActivityApiData();
      }
      if (!selectedOrganizationUid) {
        return promises;
      }

      if (groupBy !== GroupBy.Facility && groupBy !== GroupBy.FacilityAvailability) {
        promises.appendList<ActivityType>(
          'activity-types',
          () =>
            ActivitytypesService.activitytypesList({
              organisationUid: selectedOrganizationUid,
            }),
          setActivityTypes,
          generateCacheKey('activity-types'),
        );
      }

      if (hasPermission(ModulePermissionsEnum.VIEW_CONTACTS)) {
        promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganizationUid, generateCacheKey));
      } else if (groupBy !== GroupBy.Facility && groupBy !== GroupBy.FacilityAvailability) {
        promises.appendList<Contact>(
          'contacts',
          () =>
            ActivitiesService.activitiesContactsList({
              organisationUid: selectedOrganizationUid,
            }),
          setContacts,
          generateCacheKey('activity-contacts'),
        );
      }
      promises.appendList<DayPartStartTime>(
        'day-parts',
        () =>
          DaypartsService.daypartsList({
            organisationUid: selectedOrganizationUid,
          }),
        setDayParts,
        generateCacheKey('day-parts'),
      );
      promises.appendListObj<Horse>('horses', setHorses, activeOrganizationHorses(selectedOrganizationUid, generateCacheKey));

      promises.appendList<HorseGroup>(
        'horse-groups',
        () =>
          HorsegroupsService.horsegroupsList({
            organisationUid: selectedOrganizationUid,
          }),
        setGroups,
        generateCacheKey('horse-groups'),
      );
      promises.appendList<Stable>(
        'stables',
        () =>
          StablesService.stablesList({
            organisationUid: selectedOrganizationUid,
          }),
        setStables,
        generateCacheKey('stables'),
      );
      if (hasPermission([ModulePermissionsEnum.MANAGE_FACILITIES, ModulePermissionsEnum.PLAN_OWN_FACILITIES_EVENTS])) {
        promises.appendList<Facility>(
          'facilities',
          () =>
            FacilitiesService.facilitiesList({
              organisationUid: selectedOrganizationUid,
              hidden: false,
            }),
          setFacilities,
          generateCacheKey('facilities'),
        );
      }
      promises.appendList<Role>(
        'roles',
        () =>
          RolesService.rolesList({
            organisationUid: selectedOrganizationUid,
          }),
        setRoles,
        generateCacheKey('roles'),
      );
      setApiPromises(promises);
      return promises;
    },
    [
      selectedOrganizationUid,
      generateCacheKey,
      loadActivityApiData,
      setActivityTypes,
      setContacts,
      setDayParts,
      setHorses,
      setGroups,
      setStables,
      setRoles,
      setApiPromises,
      hasPermission,
      setFacilities,
      groupBy,
    ],
  );

  const markActivityAsDone = useCallback(
    async (calendarActivity: CalendarActivityBase, done: boolean) => {
      if (calendarActivity.type === CalendarActivityType.Activity) {
        let result: RealActivitiesSetDoneAndUnDone | undefined = undefined;
        if (done) {
          result = await ActivitiesService.activitiesSetDonePartialUpdate({
            organisationUid: selectedOrganizationUid ?? '',
            uid: calendarActivity.uid,
          });
        } else {
          result = await ActivitiesService.activitiesSetUndonePartialUpdate({
            organisationUid: selectedOrganizationUid ?? '',
            uid: calendarActivity.uid,
          });
        }
        setActivities((activities ?? []).map(activity => (activity.uid === result?.uid ? (result as RealActivities) : activity)));
      } else if (calendarActivity.type === CalendarActivityType.Task) {
        let result: DailyNoteSetDoneAndUnDone | undefined = undefined;
        if (done) {
          result = await DailynoteService.dailynoteSetDonePartialUpdate({
            organisationUid: selectedOrganizationUid ?? '',
            uid: calendarActivity.uid,
          });
        } else {
          result = await DailynoteService.dailynoteSetUndonePartialUpdate({
            organisationUid: selectedOrganizationUid ?? '',
            uid: calendarActivity.uid,
          });
        }
        setDailyNotes((dailyNotes ?? []).map(dailyNote => (dailyNote.uid === result?.uid ? (result as DailyNote) : dailyNote)));
      } else {
        console.error('Cannot mark this activity type as done');
      }
    },
    [selectedOrganizationUid, activities, setActivities, setDailyNotes, dailyNotes],
  );

  const removeActivity = useCallback(
    async (activity: CalendarActivity, eventsToChange?: EventsToChangeEnum): Promise<void> => {
      if (activity.type === CalendarActivityType.Activity) {
        // It's a 'real activity'
        await ActivitiesService.activitiesDeletePartialUpdate({
          organisationUid: selectedOrganizationUid ?? '',
          uid: activity.uid,
          requestBody: { events_to_change: eventsToChange },
        });
        if (activities) {
          setActivities(activities.filter(act => act.uid !== activity.uid));
        }
      } else if (activity.type === CalendarActivityType.FacilityEvent) {
        if (groupBy === GroupBy.Facility) {
          // It's a 'facility event'
          await FacilitiesService.facilitiesEventsDeletePartialUpdate({
            organisationUid: selectedOrganizationUid ?? '',
            uid: activity.uid,
            requestBody: { events_to_change: eventsToChange },
          });
        } else if (groupBy === GroupBy.FacilityAvailability) {
          // It's a 'my facility event'
          await FacilitiesService.facilitiesMyEventsDeletePartialUpdate({
            organisationUid: selectedOrganizationUid ?? '',
            uid: activity.uid,
            requestBody: { events_to_change: eventsToChange },
          });
        } else {
          console.error('Failed to remove a facility event where the groupBy not is Facility or FacilityAvailability');
          return;
        }
        if (facilityEvents) {
          setFacilityEvents(facilityEvents.filter(facilityEvent => facilityEvent.uid !== activity.uid));
        }
      } else {
        // It's a 'daily note'.
        await DailynoteService.dailynoteDeletePartialUpdate({
          organisationUid: selectedOrganizationUid ?? '',
          uid: activity.uid,
        });
        if (dailyNotes) {
          setDailyNotes(dailyNotes.filter(dailyNote => dailyNote.uid !== activity.uid));
        }
      }

      // When we remove other activities too, then reload all data.
      if (eventsToChange === EventsToChangeEnum.ALL || eventsToChange === EventsToChangeEnum.FUTURE) {
        loadActivityApiData();
      }
    },
    [
      selectedOrganizationUid,
      activities,
      setActivities,
      dailyNotes,
      setDailyNotes,
      loadActivityApiData,
      facilityEvents,
      setFacilityEvents,
      groupBy,
    ],
  );

  const moveActivity = useCallback(
    async (
      uid: string,
      date: Date,
      dayPart: number | undefined,
      activityIndex: number | undefined,
      horseUid: string | undefined,
    ): Promise<void> => {
      const found = activities?.find(activity => activity.uid === uid);
      if (!found) {
        setModalError(t('move-activity-not-found-error', 'Trying to move activity, but the activity is not found'));
        console.error('Trying to move activity, but the activity is not found');
        return;
      }

      // Check if the categories match between the activity and the horse.
      const foundHorse = horses?.find(horse => horse.uid === horseUid);
      const foundActivityType = activityTypes?.find(activityType => activityType.uid === found.activity_type_uid);
      if (foundHorse && foundActivityType && foundActivityType.category) {
        if (
          (foundActivityType.category === ActivityTypeCategoryEnum.BREEDING && !foundHorse.use_in_breeding) ||
          (foundActivityType.category === ActivityTypeCategoryEnum.CARE && !foundHorse.use_in_care) ||
          (foundActivityType.category === ActivityTypeCategoryEnum.SPORT && !foundHorse.use_in_sport)
        ) {
          setModalError(
            t(
              'move-activity-category-mismatch',
              "Trying to move a '{{category_name}}' activity to {{horse_name}}. But {{horse_name}} is not used for {{category_name}}. Please enable {{category_name}} for {{horse_name}} on the horse details page.",
              { category_name: getActivityTypeCategory(t, foundActivityType.category), horse_name: informalHorseName(foundHorse) },
            ),
          );
          return;
        }
      }

      let start: DatetimeOrDateField | undefined = undefined;
      let end: DatetimeOrDateField | undefined = undefined;
      if (found.all_day_event) {
        start = {
          date: formatDate(date),
        };
        end = {
          date: formatDate(addDays(new Date(date), 1)),
        };
      } else {
        const newStartDateTime = new Date(found.start.datetime ?? '');
        newStartDateTime.setDate(date.getDate());
        const newEndDateTime = new Date(found.end?.datetime ?? '');
        newEndDateTime.setDate(date.getDate());
        start = {
          datetime: newStartDateTime.toISOString(),
        };
        end = {
          datetime: newEndDateTime.toISOString(),
        };
      }

      const patched: PatchedRealActivitiesUpdate = {
        start,
        end,
        daypart: dayPart !== undefined ? dayPart + 1 : undefined,
        all_day_event: found.all_day_event,
        ordering: activityIndex !== undefined ? activityIndex + 1 : undefined,
        horse_uid: horseUid,
      };

      try {
        await ActivitiesService.activitiesPartialUpdate({
          organisationUid: selectedOrganizationUid ?? '',
          uid,
          requestBody: patched,
        });

        // We have to reload all data because the ordering of other items is also changed.
        await loadActivityApiData().watchAll();
      } catch (e) {
        const errorParser = new ApiErrorParser<RealActivities>(e);
        setModalError(errorParser.nonFieldErrorsStrings().join(' '));
      }
    },
    [selectedOrganizationUid, activities, t, setModalError, activityTypes, horses, loadActivityApiData],
  );

  const moveActivityToDayPart = useCallback(
    (uid: string, date: Date, dayPart: number, activityIndex: number, horseUid: string | undefined): Promise<void> => {
      return moveActivity(uid, date, dayPart, activityIndex, horseUid);
    },
    [moveActivity],
  );

  const moveActivityToDay = useCallback(
    (uid: string, date: Date, horseUid: string | undefined): Promise<void> => {
      return moveActivity(uid, date, undefined, undefined, horseUid);
    },
    [moveActivity],
  );

  const moveActivityToContact = useCallback(
    async (
      uid: string,
      fromContactUid: string | undefined,
      toContactUid: string | undefined,
      date: Date | undefined,
      dayPart: number | undefined,
      activityIndex: number | undefined,
    ): Promise<void> => {
      const found = activities?.find(activity => activity.uid === uid);
      if (!found) {
        setModalError(t('move-activity-not-found-error', 'Trying to move activity, but the activity is not found'));
        console.error('Trying to move activity, but the activity is not found');
        return;
      }

      // What is the new role of the contact id
      let toActivityContRole: ActivityContactRole | undefined;

      let actContactRoles = found.activitycontactrole_set ?? [];

      // Remove the fromContact (and remember which role it had)
      actContactRoles = actContactRoles.filter(contactRole => {
        if (contactRole.contact_uid === fromContactUid) {
          toActivityContRole = contactRole;
          return false;
        } else {
          return true;
        }
      });

      if (toActivityContRole && toContactUid) {
        // Move from one contact to the other. Keep the same role.
        actContactRoles.push({ contact_uid: toContactUid, role_uid: toActivityContRole.role_uid, primary: toActivityContRole.primary });
      }
      if (!toActivityContRole && toContactUid) {
        // Move from unassigned to an assigned. We have to find a matching role.
        const activityType = activityTypes?.find(activityType => activityType.uid === found.activity_type_uid);
        const availableRoles = roles?.filter(role => activityType?.possible_roles?.includes(role.uid)) ?? [];

        const contact = contacts?.find(contact => contact.uid === toContactUid);

        if (availableRoles.length === 0) {
          actContactRoles.push({ contact_uid: toContactUid, role_uid: null, primary: true });
        } else {
          let preferedRole = availableRoles.find(roleUid => contact?.roles?.includes(roleUid.uid));
          if (!preferedRole && availableRoles.length > 0) {
            preferedRole = availableRoles[0];
          }
          actContactRoles.push({ contact_uid: toContactUid, role_uid: preferedRole?.uid ?? '', primary: true });
        }
      }

      let start: DatetimeOrDateField | undefined = undefined;
      let end: DatetimeOrDateField | undefined = undefined;
      if (date) {
        if (found.all_day_event) {
          start = {
            date: formatDate(date),
          };
          end = {
            date: formatDate(addDays(new Date(date), 1)),
          };
        } else {
          const newStartDateTime = new Date(found.start.datetime ?? '');
          newStartDateTime.setDate(date.getDate());
          const newEndDateTime = new Date(found.end?.datetime ?? '');
          newEndDateTime.setDate(date.getDate());
          start = {
            datetime: newStartDateTime.toISOString(),
          };
          end = {
            datetime: newEndDateTime.toISOString(),
          };
        }
      }

      const patched: PatchedRealActivitiesUpdate = {
        activitycontactrole_set: actContactRoles,
        start,
        end,
        daypart: dayPart ? dayPart + 1 : undefined,
        all_day_event: found.all_day_event,
        ordering: activityIndex,
      };
      try {
        const result = await ActivitiesService.activitiesPartialUpdate({
          organisationUid: selectedOrganizationUid ?? '',
          uid,
          requestBody: patched,
        });
        setActivities([...(activities ?? []).filter(act => act.uid !== uid), result]);
      } catch (e) {
        const errorParser = new ApiErrorParser<RealActivities>(e);
        setModalError(errorParser.nonFieldErrorsStrings().join(' '));
      }
    },
    [selectedOrganizationUid, activities, setActivities, activityTypes, contacts, roles, t, setModalError],
  );

  /**
   * Returns a sorted view of the activityTypes. The sorting cached in
   * the localStorage. Sorting is based on last usage.
   */
  const sortedActivityTypes = useMemo((): ActivityType[] => {
    const result = activityTypes ?? [];
    if (!selectedOrganizationUid) {
      return result;
    }
    const id = generateCacheKey('sortedActivityTypes');
    const key = `${id.userUid}/${id.organizationUid}/${id.name}`;
    const sorted: ActivityType[] = [];
    for (const uid of (localStorage.getItem(key) ?? '').split(',')) {
      const foundIndex = result.findIndex(item => item.uid === uid);
      if (foundIndex !== -1) {
        sorted.push(result[foundIndex]);
      }
    }
    if (result.length > 0 && lastUsedActivityTypeUid && result[0]?.uid && lastUsedActivityTypeUid !== result[0]?.uid) {
      console.warn('Last used activity should be first in the sorted activity types list.');
    }
    return [...sorted, ...result.filter(item => sorted.indexOf(item) === -1)];
  }, [activityTypes, generateCacheKey, lastUsedActivityTypeUid, selectedOrganizationUid]);

  /**
   * Activity types are sorted by usage. When we create a new activity
   * the related activity type should be at the top of the list. This
   * method puts the activity type on top of the list.
   */
  const setLastUsedActivityType = useCallback(
    (activityType: ActivityType) => {
      if (selectedOrganizationUid) {
        const id = generateCacheKey('sortedActivityTypes');
        const key = `${id.userUid}/${id.organizationUid}/${id.name}`;
        let sorted = (localStorage.getItem(key) ?? '').split(',');
        const foundIndex = sorted.findIndex(uid => uid === activityType.uid);
        if (foundIndex !== -1) {
          sorted.splice(foundIndex, 1);
        }
        sorted = [activityType.uid, ...sorted];
        localStorage.setItem(key, sorted.join(','));
      }
      setLastUsedActivityTypeUid(activityType.uid);
    },
    [generateCacheKey, selectedOrganizationUid, setLastUsedActivityTypeUid],
  );

  /**
   * Returns the activity type that has been last used. This does not survive page reload.
   */
  const lastUsedActivityType = useMemo(() => {
    if (!lastUsedActivityTypeUid || !activityTypes) {
      return undefined;
    }
    return activityTypes.find(activityType => {
      return activityType.uid === lastUsedActivityTypeUid;
    });
  }, [activityTypes, lastUsedActivityTypeUid]);

  const getDayPartForTime = useCallback(
    (hours: number, minutes: number): number => {
      if (!dayParts) {
        console.error('Trying to get DayPartForTime but dayParts is not set');
        return 0;
      }
      for (let i = dayParts.length - 1; i >= 0; --i) {
        const timeParts = dayParts[i].start_time.split(':');
        const dayPartHours = Number(timeParts[0]);
        const dayPartMinutes = Number(timeParts[1]);
        if (dayPartHours < hours || (dayPartHours === hours && dayPartMinutes <= minutes)) {
          return i;
        }
      }
      return 0;
    },
    [dayParts],
  );

  const updateHorseInCache = useCallback(
    (horse: Horse) => {
      if (!horses) {
        return;
      }
      const index = horses?.findIndex(existingHorse => existingHorse.uid === horse.uid);
      if (index === -1) {
        console.error('Trying to update a horse in calender context but the horse is not found.');
      } else {
        const updatedHorses = [...horses];
        updatedHorses[index] = horse;
        setHorses(updatedHorses);
      }
    },
    [horses, setHorses],
  );

  const saveActivityExtraTarget = useCallback(
    async (extraTarget: ExtraTargets, dryRun: boolean) => {
      const activity: Partial<RealActivities> = {};
      activity.horse_uid = bluePrint?.horseUid;
      activity.activity_type_uid = bluePrint?.activityTypeUid;
      activity.start = { date: formatDate(bluePrint?.day ?? new Date()) } as DatetimeOrDateField;

      let daypartNumber = bluePrint?.dayPart ?? 0;
      // Add one because we don't work with offsets in the api
      daypartNumber = daypartNumber + 1;

      activity.daypart = daypartNumber;
      activity.all_day_event = true;
      activity.extra_info = bluePrint?.taskOrMessage;
      activity.activitycontactrole_set = bluePrint?.contactRoles;

      activity.horse_uid = extraTarget.appliedGroupBy?.subject?.uid ?? '';
      activity.start = { date: formatDate(extraTarget?.day ?? new Date()) } as DatetimeOrDateField;
      // Add one because we don't work with offsets in the api
      activity.daypart = (extraTarget.dayPart ?? 0) + 1;
      activity.dry_run = dryRun;

      //
      // TODO: Implement the pregnancy check term
      //

      // if (data.activity_type_uid) {
      //   // Set the pregnancy check term when the activity type is a Pregnancy Check.
      //   if (activityType?.default === DefaultEnum.MARE_CYCLE_CHECK && data.pregnancy_check_term) {
      //     activity.pregnancy_check_term = data.pregnancy_check_term as PregnancyCheckTermEnum;
      //   }
      // }

      await ActivitiesService.activitiesCreate({
        organisationUid: selectedOrganizationUid ?? '',
        requestBody: activity as RealActivities,
      });
    },
    [bluePrint, selectedOrganizationUid],
  );

  const showContactToStaffPlanning = useCallback(
    async (contactUid: string, addToPlanning: boolean): Promise<void> => {
      try {
        const contact = await ContactsService.contactsPartialUpdate({
          organisationUid: selectedOrganizationUid ?? '',
          uid: contactUid,
          requestBody: {
            show_in_daily: addToPlanning,
          },
        });
        if (contacts) {
          const contactList = contacts?.filter(cont => cont.uid !== contactUid);
          contactList?.push(contact);
          setContacts(contactList);
        }
      } catch (e) {
        setModalError(new ApiErrorParser<Contact>(e));
      }
    },
    [contacts, selectedOrganizationUid, setContacts, setModalError],
  );

  return {
    viewType,
    setViewType,
    timeScale,
    setTimeScale,
    groupBy,
    setGroupBy,
    offset: historicOffset.current,
    previousOffset: historicOffset.previous,
    setOffset,
    apiPromises,
    activities: processedActivities,
    activityTypes: sortedActivityTypes,
    unsortedActivityTypes: activityTypes,
    dayParts,
    horses,
    contacts,
    roles,
    loadApiData,
    loadActivityApiData,
    setActivitiesData: setActivities,
    markActivityAsDone,
    stables,
    facilities,
    groups,
    realActivityByUid,
    removeActivity,
    moveActivityToDayPart,
    moveActivityToDay,
    moveActivityToContact,
    setLastUsedActivityType,
    lastUsedActivityType,
    bluePrint,
    requestBluePrint,
    unsetBluePrint,
    reshaping,
    requestReshaping,
    selectedActivity,
    setSelectedActivity,
    clearSelectedActivity,
    dragActivity,
    setDragActivity,
    selectedGroupBy,
    setSelectedGroupBy,
    getDayPartForTime,
    updateHorseInCache,
    modalError,
    setModalError,
    clearModalError: () => setModalError(undefined),
    workingHours,
    setHideNonWorkingHours,
    hideNonWorkingHours,
    saveActivityExtraTarget,
    showContactToStaffPlanning,
  };
};
