import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AllColors } from 'utilities/colors';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import { SupplierOrder } from 'openapi';
import { useAccount } from 'context/AccountContext';
import { orderTotals } from 'components/Breeding/Helpers';
import { useOrganization } from 'context/OrganizationContext';
import { getDefaultCurrency } from 'utilities/DefaultCurrency';

interface Props {
  order: SupplierOrder;
}

/**
 * Small component to show in the order list table that shows the payment status.
 */
export default function OrderListPaymentStatus({ order }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationDetails } = useOrganization();
  const { parseAndFormatMoney, formatMoney } = useAccount();

  const totals = useMemo(() => {
    return orderTotals(order, getDefaultCurrency(selectedOrganizationDetails));
  }, [order, selectedOrganizationDetails]);

  const badgeColor = useMemo((): AllColors => {
    const paid = Number(order.payment_summary.paid);
    const total = totals.total;
    if (paid === 0) {
      return AllColors.Red;
    } else if (paid < total) {
      return AllColors.Amber;
    } else if (paid === total) {
      return AllColors.Green;
    } else if (paid > total) {
      return AllColors.Yellow;
    }
    return AllColors.Gray;
  }, [order, totals]);

  const badgeText = useMemo((): string => {
    const paid = Number(order.payment_summary.paid);
    const total = totals.total;
    if (paid === 0) {
      return t('not-paid', 'Not paid');
    } else if (paid < total) {
      return t('partly-paid', 'Partly paid');
    } else if (paid === total) {
      return t('fully-paid', 'Fully paid');
    } else if (paid > total) {
      return t('over-paid', 'Overpaid');
    }
    return t('unknown', 'Unknown');
  }, [order, totals, t]);

  // Get the currency from the order.
  const currency = useMemo((): string => {
    if (!order.order_items || order.order_items.length === 0 || !order.order_items[0].unit_price_currency) {
      return getDefaultCurrency(selectedOrganizationDetails);
    }
    return order.order_items[0].unit_price_currency;
  }, [order.order_items, selectedOrganizationDetails]);

  return (
    <div className='flex flex-col gap-1 items-end md:items-start'>
      <Badge size={BadgeSize.Normal} color={badgeColor}>
        {badgeText}
      </Badge>
      <p className='hidden sm:block text-xs'>{`${parseAndFormatMoney(order.payment_summary.paid, currency)} / ${formatMoney(totals.total, currency)}`}</p>
    </div>
  );
}
