/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ml` - Millilitre
 * * `l` - Litre
 * * `g` - Gram
 * * `kg` - Kilogram
 * * `lb` - Pound
 * * `oz` - Ounce (weight)
 * * `imperial_oz` - Imperial Ounce (fluid)
 * * `imperial_pint` - Imperial Pint
 * * `imperial_qt` - Imperial Quart
 * * `imperial_g` - Imperial Gallon
 * * `imperial_tsp` - Imperial Teaspoon
 * * `us_oz` - US Ounce (fluid)
 * * `us_pint` - US Pint
 * * `us_qt` - US Quart
 * * `us_g` - US Gallon
 * * `us_tsp` - US Teaspoon
 * * `custom` - Custom
 */
export enum QuantityMeasureEnum {
  ML = 'ml',
  L = 'l',
  G = 'g',
  KG = 'kg',
  LB = 'lb',
  OZ = 'oz',
  IMPERIAL_OZ = 'imperial_oz',
  IMPERIAL_PINT = 'imperial_pint',
  IMPERIAL_QT = 'imperial_qt',
  IMPERIAL_G = 'imperial_g',
  IMPERIAL_TSP = 'imperial_tsp',
  US_OZ = 'us_oz',
  US_PINT = 'us_pint',
  US_QT = 'us_qt',
  US_G = 'us_g',
  US_TSP = 'us_tsp',
  CUSTOM = 'custom',
}
