import { Stool } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import { Horse, SemenTypeEnum, StallionMount } from 'openapi';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { formalHorseName } from 'utilities/Horse';

interface Props {
  mount: StallionMount;
  initialAmount: number;
  semenType: SemenTypeEnum;
  stallion?: Horse;
  setCount: (count: number) => void;
}

export default function PickSemenOrderItem({ mount, initialAmount = 0, semenType, stallion, setCount }: Props): JSX.Element {
  const { t } = useTranslation();
  const [itemCount, setItemCount] = useState<number>(initialAmount);
  const { formatDate } = useAccount();
  const inputRef = useRef<HTMLInputElement>(null);

  const amountAvailable = useMemo(() => {
    if (semenType === SemenTypeEnum.FRESH) {
      return mount.portions_fresh_available ?? 0;
    } else {
      return mount.straws_frozen_available ?? 0;
    }
  }, [mount, semenType]);

  const updateAmount = useCallback(
    (amount: number, isRelative: boolean) => {
      let newItemCount = amount;
      if (isRelative) {
        newItemCount = itemCount + amount;
      }

      // Out of bounds
      if (newItemCount < 0 || isNaN(amount)) {
        newItemCount = 0;
      }

      // Out of bounds
      if (newItemCount > amountAvailable) {
        newItemCount = amountAvailable;
      }

      setItemCount(newItemCount);
      setCount(newItemCount);

      if (inputRef.current) {
        inputRef.current.value = newItemCount.toString();
      }
    },
    [itemCount, amountAvailable, setCount],
  );

  const buttonStyle = 'w-10 h-10 rounded-full bg-blue-600 text-white text-xl disabled:opacity-50';

  return (
    <div className='flex border-t py-2 items-center gap-4'>
      <Stool size={24} weight='light' className='hidden md:inline' />
      <div className='flex flex-col grow justify-center'>
        <p>
          {mount.collection_date && formatDate(new Date(Date.parse(mount.collection_date)))}
          {stallion && <>{` (${formalHorseName(stallion)})`}</>}
        </p>
        <div className='flex items-start flex-col md:flex-row gap-1'>
          {mount.storage_container && semenType === SemenTypeEnum.FROZEN && (
            <Badge>
              {t('semen-storage-container-short', 'Container')}: {mount.storage_container}
            </Badge>
          )}
          {mount.storage_container && semenType === SemenTypeEnum.FROZEN && (
            <Badge>
              {t('semen-storage-canister-short', 'Canister')}: {mount.storage_canister}
            </Badge>
          )}
          {mount.storage_container && semenType === SemenTypeEnum.FROZEN && <Badge>{mount.NIFA_straw_color}</Badge>}
        </div>
      </div>
      <div className='flex flex-col md:flex-row items-center gap-2 md:gap-4'>
        <p>
          {t('pick-mount-in-stock', 'Stock')}: {amountAvailable}
        </p>
        <div className='flex items-center'>
          <button disabled={itemCount === 0} className={buttonStyle} onClick={() => updateAmount(-1, true)}>
            -
          </button>
          <input
            ref={inputRef}
            type={'number'}
            defaultValue={itemCount}
            className='w-12 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:ring-0 focus:ring-offset-0 focus:outline-none'
            onChange={e => {
              const number = parseInt(e.currentTarget.value);
              updateAmount(number, false);
            }}
          />
          <button disabled={itemCount === amountAvailable} className={buttonStyle} onClick={() => updateAmount(1, true)}>
            +
          </button>
        </div>
      </div>
    </div>
  );
}
