import { usePlanning } from 'hooks/UsePlanning';
import React, { CSSProperties, Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { Float } from '@headlessui-float/react';
import { ButtonVariant } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { Check, PencilSimple, Trash } from '@phosphor-icons/react';
import { ActionModal } from 'ui/Modals';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import TitleBarButtons, { TitleBarActionProps } from 'ui/Modals/TitleBarButtons';
import { ActionButton, ActionProps } from 'ui/Modals/ActionModal';
import ApiErrorParser from 'api/ApiErrorParser';
import { CalendarActivity, CalendarActivityType, SelectedActivityState } from 'utilities/Planning';
import { DefaultEnum } from 'openapi';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';
import ActivityModalContent from './ActivityModalContent';
import { ErrorSection } from 'ui/Error';
import SaveStallionMount from 'components/Breeding/SaveStallionMount';
import { SaveMareCycleCheck } from 'components/Breeding/SaveMareCycleCheck';
import useModal from 'ui/Modals/UseModal';
import DeleteActivityModal from './DeleteActivityModal';

interface Props {
  children: ReactNode;
  activity: CalendarActivity;
  // It could be that an activity is shown more than once in a view (staff view only).
  // Therefor we need to know for which contact we have to show the modal.
  groupByUid?: string;
  style?: CSSProperties | undefined;
}

export default function ActivityModal({ children, activity, groupByUid, style }: Props): JSX.Element {
  const { t } = useTranslation();
  const { width } = useScreenSize();
  const [isMarkingAsFinished, setIsMarkingAsFinished] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<void> | undefined>();
  const { markActivityAsDone, removeActivity, selectedActivity, setSelectedActivity, loadActivityApiData, contacts } = usePlanning();
  const { showModal: showDeleteModal, closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible } = useModal();
  const navigate = useNavigate();

  const title = () => {
    if (selectedActivity) {
      if (selectedActivity.activity.type === CalendarActivityType.Activity) {
        return t('activity', 'Activity');
      } else if (selectedActivity.activity.type === CalendarActivityType.FacilityEvent) {
        return t('reservation', 'Reservation');
      } else {
        if (selectedActivity.activity.executable) {
          return t('task', 'Task');
        } else {
          return t('message', 'Message');
        }
      }
    } else {
      return '';
    }
  };

  const markAsFinished = useCallback(
    (done = true) => {
      const typeCanFinalize =
        activity.activityType?.default === DefaultEnum.MARE_CYCLE_CHECK || activity.activityType?.default === DefaultEnum.STALLION_MOUNT;

      const alreadyFinalized: boolean = activity.mareCycleCheckUid !== undefined;

      // TODO: Check if we have breeding module enabled.
      if (done === true && typeCanFinalize && !alreadyFinalized) {
        setSelectedActivity({ ...activity }, SelectedActivityState.Finalize, selectedActivity?.groupByUid);
      } else {
        setIsMarkingAsFinished(true);
        markActivityAsDone(activity, done)
          .then(() => {
            setApiError(undefined);
            setSelectedActivity({ ...activity }, SelectedActivityState.Selected, selectedActivity?.groupByUid);
          })
          .catch(e => setApiError(new ApiErrorParser<void>(e)))
          .finally(() => setIsMarkingAsFinished(false));
      }
    },
    [markActivityAsDone, activity, setSelectedActivity, selectedActivity],
  );

  const titleBarActions = useMemo((): TitleBarActionProps[] => {
    return [
      {
        text: t('remove', 'Remove'),
        icon: <Trash />,
        onClick: () => {
          if (activity.rRule) {
            setSelectedActivity({ ...activity }, SelectedActivityState.Selected);
            showDeleteModal();
          } else {
            removeActivity(activity).catch(e => setApiError(new ApiErrorParser<void>(e)));
          }
        },
      },
      {
        text: t('edit', 'Edit'),
        icon: <PencilSimple />,
        onClick: () => setSelectedActivity({ ...activity }, SelectedActivityState.Edit),
      },
    ];
  }, [t, setSelectedActivity, removeActivity, activity, showDeleteModal]);

  const modalActions = useMemo((): ActionProps[] => {
    if (!activity.executable) {
      return [];
    }

    const array: ActionProps[] = [];
    if (activity.type !== CalendarActivityType.FacilityEvent) {
      if (activity.done) {
        array.push({
          variant: ButtonVariant.Default,
          text: t('mark-as-undone', 'Mark as undone'),
          onClick: () => markAsFinished(false),
          loading: isMarkingAsFinished,
        });
      } else {
        array.push({
          variant: ButtonVariant.Primary,
          text: t('mark-as-done', 'Mark as done'),
          onClick: () => markAsFinished(true),
          loading: isMarkingAsFinished,
          icon: <Check />,
        });
      }
    }
    if (activity.mareCycleCheckUid) {
      array.push({
        variant: ButtonVariant.Default,
        text: t('show-mare-cycle-check-report', 'Show mare cycle check'),
        onClick: () => navigate(generatePath(AppRoutes.BreedingMareCycleCheckDetails.path, { uid: activity.mareCycleCheckUid })),
      });
    }
    if (activity.stallionMountUid) {
      array.push({
        variant: ButtonVariant.Default,
        text: t('show-stallion-mount-report', 'Show stallion mount'),
        onClick: () => {
          navigate(generatePath(AppRoutes.MountDetails.path, { uid: activity.stallionMountUid }));
        },
      });
    }
    return array;
  }, [t, activity, isMarkingAsFinished, markAsFinished, navigate]);

  const showModal = useMemo(() => {
    if (selectedActivity?.activity.uid !== activity.uid) {
      return false;
    }
    // Don't show when we're having the full modal open.
    if (selectedActivity.selectedActivityState !== SelectedActivityState.Info) {
      return false;
    }

    // It could be that an activity is shown more than once in a view (staff view only).
    // Therefor we need to know for which contact we have to show the modal.
    if (selectedActivity.groupByUid && groupByUid) {
      return selectedActivity.groupByUid === groupByUid;
    }
    return true;
  }, [selectedActivity, activity, groupByUid]);

  const onSaved = (reload: boolean, isSkipped: boolean) => {
    if (selectedActivity) {
      if (isSkipped) {
        setIsMarkingAsFinished(true);
        markActivityAsDone(activity, true)
          .catch(e => setApiError(new ApiErrorParser<void>(e)))
          .finally(() => setIsMarkingAsFinished(false));
      }
      if (reload) {
        loadActivityApiData();
      }
    }
  };

  return (
    <>
      {width <= ScreenSize.md && (
        <div style={style}>
          {children}
          <ActionModal actions={modalActions} open={showModal}>
            <TitleBarButtons
              title={title()}
              actions={titleBarActions}
              onClose={() => {
                if (selectedActivity) {
                  setSelectedActivity(selectedActivity.activity, SelectedActivityState.Selected, selectedActivity?.groupByUid);
                }
              }}
            />
            <ErrorSection errors={apiError} />
            <ActivityModalContent activity={activity} />
          </ActionModal>
        </div>
      )}
      {width > ScreenSize.md && (
        <Float
          offset={4}
          show={showModal}
          placement={selectedActivity?.activity.type !== CalendarActivityType.Activity ? 'bottom-start' : 'right-start'}
          flip={true}
          composable
          portal={true}
          as={Fragment}
          enter='transition duration-200 ease-out'
          enter-from='opacity-0 -translate-y-1'
          enter-to='opacity-100 translate-y-0'
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          onHide={() => setApiError(undefined)}
        >
          <Float.Reference>
            <div style={style}>{children}</div>
          </Float.Reference>
          <Float.Content>
            <div
              className='w-64 rounded-lg bg-white pl-4 pb-4 pt-2 pr-2 shadow-[0_8px_30px_rgb(0,0,0,0.22)]'
              onClick={e => {
                // Don't hide when the modal is clicked
                e.stopPropagation();
              }}
            >
              <TitleBarButtons
                actions={titleBarActions}
                title={title()}
                onClose={() => {
                  if (selectedActivity) {
                    setSelectedActivity(selectedActivity.activity, SelectedActivityState.Selected, selectedActivity?.groupByUid);
                  }
                }}
              />
              <ErrorSection errors={apiError} />
              <ActivityModalContent activity={activity} />
              <div className='pr-2 mt-2 gap-2 flex flex-col'>
                {modalActions.map((action, index) => (
                  <ActionButton className='w-full' key={index + action.text} action={action} />
                ))}
              </div>
            </div>
          </Float.Content>
        </Float>
      )}
      <DeleteActivityModal
        isVisible={deleteModalIsVisible}
        onRequestClose={closeDeleteModal}
        onDelete={removeActivity}
        activity={activity}
      />

      {selectedActivity?.activity.activityType?.default === DefaultEnum.MARE_CYCLE_CHECK && (
        <SaveMareCycleCheck
          skippable={true}
          open={
            selectedActivity?.activity.uid === activity.uid &&
            selectedActivity.groupByUid === groupByUid &&
            selectedActivity?.selectedActivityState === SelectedActivityState.Finalize
          }
          mareCycleCheckActivity={activity}
          selectedMare={activity.horse}
          onSaved={onSaved}
          onRequestClose={() => setSelectedActivity(selectedActivity.activity, SelectedActivityState.Info, selectedActivity?.groupByUid)}
        />
      )}
      {selectedActivity?.activity.activityType?.default === DefaultEnum.STALLION_MOUNT && (
        <SaveStallionMount
          skippable={true}
          calendarActivity={activity}
          open={
            selectedActivity?.activity.uid === activity.uid &&
            selectedActivity.groupByUid === groupByUid &&
            selectedActivity?.selectedActivityState === SelectedActivityState.Finalize
          }
          selectedStallion={activity.horse}
          date={selectedActivity.activity.startTime}
          stallions={[]}
          contacts={contacts ?? []}
          onSaved={onSaved}
          onRequestClose={() => {
            setSelectedActivity(selectedActivity.activity, SelectedActivityState.Info, selectedActivity?.groupByUid);
          }}
        />
      )}
    </>
  );
}
