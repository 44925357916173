import { zodResolver } from '@hookform/resolvers/zod';
import { Plus, Trash } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import {
  ActivitiesService,
  CancelablePromise,
  DefaultEnum,
  Horse,
  IsPregnantEnum,
  LocationEnum,
  MareCycleCheck,
  MarecyclecheckService,
  PregnancyCheckTermEnum,
  RealActivities,
  SoftnessEnum,
  UterusEnum,
} from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, DateInput, SelectInput, TextAreaInput, TextInput } from 'ui/Inputs';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import {
  eggLocationEnumToString,
  pregnancyCheckIsPregnantEnumToString,
  pregnancyCheckTermEnumToString,
  softnessEnumToString,
  uterusEnumToString,
} from 'utilities/Breeding';
import { transformEmptyToUndefined } from 'utilities/zod';
import CystMap from './CystMap';
import { useAccount } from 'context/AccountContext';
import { CalendarActivity, realActivityToDate } from 'utilities/Planning';
import { formatDate, today } from 'utilities/date.utilities';
import { addDays, differenceInCalendarDays, startOfToday } from 'date-fns';
import { z } from 'zod';
import RadioButtonGroup, { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import classNames from 'classnames';
import DropdownMenu from 'ui/DropdownMenu';
import { usePlanning } from 'hooks/UsePlanning';
import { Alert } from 'ui/Alert';
import { Severity } from 'utilities/severity';
import { formalHorseName } from 'utilities/Horse';

interface Props {
  mares?: Horse[];
  skippable?: boolean;
  selectedMare?: Horse;
  mareCycleCheckActivity?: CalendarActivity;
  existingMareCycleCheck?: MareCycleCheck;
  open: boolean;
  onSaved: (reload: boolean, isSkipped: boolean, mareCycleCheck?: MareCycleCheck) => void;
  onRequestClose: () => void;
}

// Simplify the add_followup_insemination field of MareCycleCheck to a date.
// This is translated to a FollowUpInseminationCheck in the submit handler.
interface ExtendedMareCycleCheck extends MareCycleCheck {
  add_insemination_date: string;
}

// A mare cycle check is checks if the mare is ready for insemination.
export function SaveMareCycleCheck({
  mares,
  skippable,
  selectedMare,
  existingMareCycleCheck,
  mareCycleCheckActivity,
  open,
  onRequestClose,
  onSaved,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization, selectedOrganizationUid, selectedOrganizationDetails } = useOrganization();
  const { accountDetails, formatDate: formatDatePretty } = useAccount();
  const { activityTypes } = usePlanning();

  const [relatedInsemination, setRelatedInsemination] = useState<RealActivities | undefined>(undefined);

  // This means that we found an insemination to relate to but it's not marked as done.
  const [relatedInseminationNotDone, setRelatedInseminationNotDone] = useState<RealActivities | undefined>(undefined);

  const schema = useMemo(() => {
    return schemas.MareCycleCheck.extend({ add_insemination_date: z.string().optional().nullable() }).omit({
      uid: true,
      activity_uid: true,
      created_by: true,
      egg_set: true,
      cyst_set: true,
      add_followup_marecyclechecks: true,
      date: mareCycleCheckActivity ? true : undefined,
      mare_uid: mareCycleCheckActivity ? true : undefined,
      add_insemination_date: mareCycleCheckActivity ? undefined : true,
      add_followup_insemination: true,
      order_item_uid: true, // @TODO Optionally connect to the order.
      created_on: true,
      last_modified_on: true,
      pregnancy_check_term: mareCycleCheckActivity ? true : undefined,
    });
  }, [mareCycleCheckActivity]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<ExtendedMareCycleCheck>({
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
  });

  // Use a fieldArray for the egg items.
  const {
    fields: eggFields,
    append: appendEgg,
    remove: removeEgg,
  } = useFieldArray({
    name: 'egg_set',
    control,
  });

  // Use a fieldArray for the cyst items.
  const {
    fields: cystFields,
    append: appendCyst,
    remove: removeCyst,
    update: updateCyst,
  } = useFieldArray({
    name: 'cyst_set',
    control,
  });

  const {
    fields: followupFields,
    append: followupAppend,
    remove: followupRemove,
  } = useFieldArray({
    name: 'add_followup_marecyclechecks',
    control,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: ExtendedMareCycleCheck) => {
    if (!selectedOrganization) {
      console.error('Save mare cycle check without selected organization.');
      return;
    }

    if (data.add_insemination_date) {
      data.add_followup_insemination = {
        date: data.add_insemination_date,
        // TODO: The order_item_uid can be assigned to connect this MareCycleCheck to an Order.
        // order_item_uid?: 'id'
      };
    }

    if (mareCycleCheckActivity) {
      data.activity_uid = mareCycleCheckActivity.uid;
      data.date = formatDate(mareCycleCheckActivity.startTime);
    }

    let promise: CancelablePromise<MareCycleCheck>;
    if (existingMareCycleCheck) {
      promise = MarecyclecheckService.marecyclecheckPartialUpdate({
        mareOrganisationUid: selectedOrganization.uid,
        uid: existingMareCycleCheck.uid,
        requestBody: data,
      });
    } else {
      promise = MarecyclecheckService.marecyclecheckCreate({
        mareOrganisationUid: selectedOrganization.uid,
        requestBody: data,
      });
    }

    try {
      onSaved(true, false, await promise);
      onRequestClose();
    } catch (e) {
      setApiError(new ApiErrorParser<MareCycleCheck>(e));
    }
  };

  const findRelatedInsemination = useCallback(async (): Promise<RealActivities | undefined> => {
    const insemeniationType = activityTypes?.find(type => type.default === DefaultEnum.INSEMINATION);
    if (!insemeniationType) {
      return;
    }

    if (!selectedMare) {
      console.log('Cannot distill last insemination activity because no mare is selected');
      return;
    }

    const inseminationActivities = await ActivitiesService.activitiesList({
      organisationUid: selectedOrganizationUid ?? '',
      lastDoneAndLastAndFirstFuturePerHorseAndActivityType: true,
      activityTypeUidIn: [insemeniationType.uid],
      horseUidIn: [selectedMare.uid],
    });

    // Filter for the lastDone activity.
    const foundInsemination = inseminationActivities.results.find(
      activity => activity.done_on && realActivityToDate(activity) < new Date(),
    );

    if (!foundInsemination) {
      // We might have an insemination to relate to but it's not marked as done.
      const notDone = inseminationActivities.results.find(activity => !activity.done_on && realActivityToDate(activity) < new Date());
      if (notDone) {
        setRelatedInseminationNotDone(notDone);
      }

      // No historic insemination found.
      return;
    }

    // We only search 100 days in history. Otherwise we might end up with an insemination from previous season.
    if (differenceInCalendarDays(new Date(), realActivityToDate(foundInsemination)) > 100) {
      console.log('Previous insemination found, but it is too old');
      return;
    }

    return foundInsemination;
  }, [activityTypes, selectedMare, selectedOrganizationUid]);

  const resetToDefaults = useCallback(() => {
    if (existingMareCycleCheck) {
      reset(existingMareCycleCheck);
    } else {
      if (mareCycleCheckActivity) {
        reset({
          mare_uid: mareCycleCheckActivity.horseUid,
          date: formatDate(mareCycleCheckActivity.startTime),
          extra_info: mareCycleCheckActivity.extraInfo,
        });
      } else {
        reset({
          mare_uid: selectedMare?.uid,
          date: today(),
        });
      }
    }
  }, [reset, existingMareCycleCheck, selectedMare, mareCycleCheckActivity]);

  // Event that will be fired after the modal has been closed
  const resetForm = () => {
    setApiError(undefined);
    removeEgg(0); // Because the default value is {null, null} we need to manually trigger a remove.
    removeCyst(0); // Because the default value is {null, null} we need to manually trigger a remove.

    resetToDefaults();
  };

  const uterusEnumOptions = useMemo(() => {
    const options: OptionItemInterface[] = [];
    Object.values(UterusEnum).forEach(value => {
      options.push({ id: value, name: uterusEnumToString(value) });
    });
    return options;
  }, []);

  const softnessEnumOptions = useMemo(() => {
    return Object.values(SoftnessEnum).reduce<OptionItemInterface[]>((prevVal, currentVal) => {
      prevVal.push({ id: currentVal, name: softnessEnumToString(currentVal) });
      return prevVal;
    }, []);
  }, []);

  const eggLocationEnumOptions = useMemo(() => {
    return Object.values(LocationEnum).reduce<OptionItemInterface[]>((prevVal, currentVal) => {
      prevVal.push({ id: currentVal, name: eggLocationEnumToString(currentVal) });
      return prevVal;
    }, []);
  }, []);

  const mareOptions = useMemo((): OptionItemInterface[] | undefined => {
    if (!mares) {
      return undefined;
    }
    return mares
      .filter(mare => !mare.hidden || mare.uid === existingMareCycleCheck?.mare_uid)
      .map(horse => {
        return { id: horse.uid, name: formalHorseName(horse) ?? '-' };
      });
  }, [mares, existingMareCycleCheck]);

  const planInseminationOptions = useMemo((): OptionItemInterface[] | undefined => {
    const today = startOfToday();
    const tomorrow = addDays(today, 1);
    const dayAfterTomorrow = addDays(today, 2);
    const threeDays = addDays(today, 3);
    return [
      {
        id: formatDate(today),
        name: t('today', 'Today'),
      },
      {
        id: formatDate(tomorrow),
        name: t('tomorrow', 'Tomorrow'),
      },
      {
        id: formatDate(dayAfterTomorrow),
        name: t('day-after-tomorrow', 'Day after tomorrow'),
      },
      {
        id: formatDate(threeDays),
        name: t('three-days', 'Three days'),
      },
    ];
  }, [t]);

  const suggestDateForFollowup = useCallback(
    (term: PregnancyCheckTermEnum): Date => {
      if (!relatedInsemination) {
        return new Date();
      }
      const date = realActivityToDate(relatedInsemination);
      switch (term) {
        case PregnancyCheckTermEnum.FIRST:
          // This can be determined in the planning settings. By default it's 18 days.
          return addDays(date, selectedOrganizationDetails?.days_between_insemination_and_pregnancy_check ?? 18);
        case PregnancyCheckTermEnum.SECOND:
          return addDays(date, 40);
        case PregnancyCheckTermEnum.THIRD:
          return addDays(date, 84); // 12 weeks
      }
    },
    [relatedInsemination, selectedOrganizationDetails],
  );

  const modalActions = useMemo((): ActionProps[] => {
    const array: ActionProps[] = [];

    if (skippable) {
      array.push({
        variant: ButtonVariant.Default,
        text: t('skip', 'Skip'),
        onClick: () => {
          onSaved(false, true);
          onRequestClose();
        },
      });
    }

    array.push({
      variant: ButtonVariant.Primary,
      text: t('save', 'Save'),
      type: 'submit',
      formId: 'addMareCycleCheck',
      loading: isSubmitting,
    });

    return array;
  }, [t, isSubmitting, skippable, onRequestClose, onSaved]);

  // Reset when the modal visibility is changed or when the existingCategory is changed.
  useEffect(() => {
    resetToDefaults();
  }, [reset, existingMareCycleCheck, open, selectedMare, resetToDefaults]);

  useEffect(() => {
    if (open) {
      findRelatedInsemination()
        .then(activity => setRelatedInsemination(activity))
        .catch(e => {
          console.error('Failed to get related Insemination', e);
          setRelatedInsemination(undefined);
          setRelatedInseminationNotDone(undefined);
        });
    } else {
      setRelatedInsemination(undefined);
      setRelatedInseminationNotDone(undefined);
    }
  }, [open, findRelatedInsemination]);

  const pregnancyCheckIsPregnantEnumOptions = useMemo((): RadioButtonGroupOption[] => {
    return [
      { id: IsPregnantEnum.YES, name: pregnancyCheckIsPregnantEnumToString(IsPregnantEnum.YES) },
      { id: IsPregnantEnum.DUAL_PREGNANCY, name: pregnancyCheckIsPregnantEnumToString(IsPregnantEnum.DUAL_PREGNANCY) },
      { id: IsPregnantEnum.NO, name: pregnancyCheckIsPregnantEnumToString(IsPregnantEnum.NO) },
    ];
  }, []);

  const pregnancyCheckTermEnumOptions = useMemo(() => {
    return Object.values(PregnancyCheckTermEnum).reduce<OptionItemInterface[]>((prevVal, currentVal) => {
      prevVal.push({ id: currentVal, name: pregnancyCheckTermEnumToString(currentVal) });
      return prevVal;
    }, []);
  }, []);

  return (
    <PageModal
      open={open}
      onClosed={resetForm}
      parentElement='form'
      parentProps={{ id: 'addMareCycleCheck', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('new-mare-cycle-check', 'New mare cycle check')} onClose={() => onRequestClose()} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />
        <div className='flex flex-col md:flex-row gap-4 mb-4'>
          <div className='flex flex-col gap-4 grow'>
            {mareOptions && (
              <SelectInput
                label={t('mare', 'Mare')}
                nullable={true}
                required={true}
                options={mareOptions}
                error={fieldError('mare_uid')}
                {...register('mare_uid', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
              />
            )}
            {!mareOptions && (
              <div>
                <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('mare', 'Mare')} *</p>
                <p>{formalHorseName(selectedMare) ?? t('unknown-mare', 'Unknown mare')}</p>
              </div>
            )}
            {mareCycleCheckActivity && (
              <div>
                <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('date', 'Date')} *</p>
                <p>{formatDatePretty(mareCycleCheckActivity.startTime)}</p>
              </div>
            )}
            {!mareCycleCheckActivity && (
              <DateInput control={control} required={true} label={t('date', 'Date')} name='date' error={fieldError('date')} />
            )}
            <RadioButtonGroup<ExtendedMareCycleCheck>
              name='is_pregnant'
              required={true}
              control={control}
              options={pregnancyCheckIsPregnantEnumOptions}
              error={fieldError('is_pregnant')}
              label={t('is-pregnant', 'Is pregnant')}
            />
            {relatedInsemination && (
              <div>
                <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
                  {t('related-insemination', 'Related insemination')}
                </p>
                <p>{formatDatePretty(realActivityToDate(relatedInsemination))}</p>
              </div>
            )}
            {relatedInseminationNotDone && (
              <div>
                <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
                  {t('related-insemination', 'Related insemination')}
                </p>
                <Alert
                  severity={Severity.Warning}
                  message={t(
                    'found-related-insemination-not-done-warning',
                    'Found a related insemination at {{date}} but it is not marked as finished.',
                    { date: formatDatePretty(realActivityToDate(relatedInseminationNotDone)) },
                  )}
                />
              </div>
            )}

            {!mareCycleCheckActivity && (
              <SelectInput
                nullable={true}
                options={pregnancyCheckTermEnumOptions}
                error={fieldError('pregnancy_check_term')}
                label={t('pregnancy-check-term', 'Pregnancy check term')}
                {...register('pregnancy_check_term', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
              />
            )}
            <SelectInput
              required={true}
              nullable={true}
              options={uterusEnumOptions}
              error={fieldError('uterus')}
              label={t('uterus', 'Uterus')}
              {...register('uterus', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
            />
            <CheckboxInput
              label={t('uterus_has_fluid', 'Uterus has fluid')}
              {...register('uterus_has_fluid')}
              error={fieldError('uterus_has_fluid')}
            />
            <SelectInput
              required={true}
              nullable={true}
              options={softnessEnumOptions}
              error={fieldError('cervix')}
              label={t('cervix', 'Cervix')}
              {...register('cervix', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
            />
            <TextAreaInput
              label={t('note', 'Note')}
              error={fieldError('extra_info')}
              {...register('extra_info', { setValueAs: transformEmptyToUndefined() })}
            />
            <div>
              <p className='text-sm font-medium text-gray-600 pb-1'>{t('eggs', 'Eggs')}</p>
              <div className='p-2 rounded-lg border'>
                {eggFields.length === 0 && <p className='text-gray-500 italic text-center py-4'>{t('no-eggs', 'No eggs')}</p>}
                <table className='w-full'>
                  {eggFields.length > 0 && (
                    <thead>
                      <tr>
                        <td className='text-sm font-medium text-gray-600 pb-1'>{t('egg-location', 'Location')}</td>
                        <td className='text-sm font-medium text-gray-600 pl-2 pb-1'>{t('egg-size', 'Size')}</td>
                        <td className='text-sm font-medium text-gray-600 pl-2 pb-1'>{t('egg-hardness', 'Hardness')}</td>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {eggFields.map((field, index) => (
                      <tr key={field.id} className='border-t'>
                        <td className='py-2'>
                          <SelectInput
                            nullable={true}
                            options={eggLocationEnumOptions}
                            required={true}
                            nullableValue=''
                            error={get(errors, `egg_set.[${index}].location`)?.message}
                            {...register(`egg_set.${index}.location`, { setValueAs: transformEmptyToUndefined() })}
                          />
                        </td>
                        <td>
                          <TextInput
                            error={get(errors, `egg_set.[${index}].size`)?.message}
                            style={{ width: 50 }}
                            type='number'
                            lang={accountDetails?.language}
                            step='0.01'
                            {...register(`egg_set.${index}.size_metric`, { setValueAs: transformEmptyToUndefined() })}
                            postText={t('cm-metric', 'cm')}
                          />
                        </td>
                        <td className='p-2'>
                          <SelectInput
                            nullable={true}
                            options={softnessEnumOptions}
                            required={true}
                            nullableValue=''
                            error={get(errors, `egg_set.[${index}].hardness`)?.message}
                            {...register(`egg_set.${index}.hardness`, { setValueAs: transformEmptyToUndefined() })}
                          />
                        </td>
                        <td>
                          <button
                            type='button'
                            onClick={() => removeEgg(index)}
                            className='inline text-center cursor-pointer rounded hover:bg-red-700 hover:text-white px-1 py-1 focus:ring-0 focus:ring-offset-0'
                          >
                            <Trash className='inline' />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={3} className='pt-2'>
                        <Button
                          icon={<Plus />}
                          type='button'
                          onClick={() => {
                            // Ignore ts error to have the newly added line show empty dropdown values.
                            // eslint-disable-next-line
                            // @ts-ignore
                            appendEgg({});
                          }}
                        >
                          {t('add-egg', 'Add egg')}
                        </Button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <p>{fieldError('egg_set')}</p>
            </div>
          </div>
          <div className='space-y-4'>
            <div>
              <p className='text-sm font-medium text-gray-600 pb-1'>{t('cyst-map', 'Cyst map')}</p>
              <CystMap cysts={cystFields} cystAdded={appendCyst} cystRemoved={removeCyst} cystUpdated={updateCyst} />
              <p className='text-xs text-red-500'>{fieldError('cyst_set')}</p>
            </div>
            {!existingMareCycleCheck && (
              <SelectInput
                nullable={true}
                options={planInseminationOptions}
                error={fieldError('add_insemination_date')}
                label={t('plan-insemination', 'Plan insemination')}
                nullableLabel={t('no', 'No')}
                {...register('add_insemination_date', { setValueAs: (val: unknown): unknown => (val === t('no', 'No') ? null : val) })}
              />
            )}
            {!existingMareCycleCheck && (
              <div>
                <p className='text-sm font-medium text-gray-600 mb-2'>{t('plan-next-pregnancy-check', 'Followup pregnancy check(s)')}</p>
                <div className={classNames('rounded-lg space-y-4', { 'border p-4 mb-2': followupFields.length > 0 })}>
                  {followupFields.map((field, index) => (
                    <div key={field.id}>
                      <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
                        {t('add-followup-pregnancy-check-date', 'Pregnancy check ({{term}})', {
                          term: field.pregnancy_check_term ? pregnancyCheckTermEnumToString(field.pregnancy_check_term) : 'unknown',
                        })}
                      </p>
                      <div className='flex gap-4 justify-center'>
                        <DateInput
                          className='grow'
                          control={control}
                          error={get(errors, `add_followup_marecyclechecks.[${index}].date`)?.message}
                          {...register(`add_followup_marecyclechecks.${index}.date`, { setValueAs: transformEmptyToUndefined() })}
                        />
                        <button
                          type='button'
                          onClick={() => followupRemove(index)}
                          className='inline rounded text-center cursor-pointer hover:bg-red-700 hover:text-white px-1 py-1 focus:ring-0 focus:ring-offset-0'
                        >
                          <Trash className='inline' />
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className='flex'>
                    <DropdownMenu
                      menuPlacement='bottom-start'
                      align='left'
                      menuItems={[
                        [
                          {
                            element: t('first-pregnancy-check', 'First pregnancy check'),
                            onClick: () =>
                              followupAppend({
                                date: formatDate(suggestDateForFollowup(PregnancyCheckTermEnum.FIRST)),
                                pregnancy_check_term: PregnancyCheckTermEnum.FIRST,
                                order_item_uid: relatedInsemination?.order_item_uid,
                              }),
                          },
                          {
                            element: t('second-pregnancy-check', 'Second pregnancy check'),
                            onClick: () =>
                              followupAppend({
                                date: formatDate(suggestDateForFollowup(PregnancyCheckTermEnum.SECOND)),
                                pregnancy_check_term: PregnancyCheckTermEnum.SECOND,
                                order_item_uid: relatedInsemination?.order_item_uid,
                              }),
                          },
                          {
                            element: t('third-pregnancy-check', 'Third pregnancy check'),
                            onClick: () =>
                              followupAppend({
                                date: formatDate(suggestDateForFollowup(PregnancyCheckTermEnum.THIRD)),
                                pregnancy_check_term: PregnancyCheckTermEnum.THIRD,
                                order_item_uid: relatedInsemination?.order_item_uid,
                              }),
                          },
                        ],
                      ]}
                    >
                      <Button icon={<Plus />} type='button'>
                        {followupFields.length === 0 ? t('add', 'Add') : t('add-another', 'Add another')}
                      </Button>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </PageModalContent>
      <PageModalActions actions={modalActions} />
    </PageModal>
  );
}
