import { CaretRight, Plus, ShoppingCart } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import { Horse, PurchaserOrder, HorsesService, OrdersService, Redirect, Contact, CatalogueProduct, PublicService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonVariant } from 'ui/Button';
import {
  defaultApiPageSize,
  table,
  tableHiddenColumnMd,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableThead,
  tableTheadTd,
} from 'ui/Const';
import { ErrorSection } from 'ui/Error';
import Page from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ActionModal } from 'ui/Modals';
import useModal from 'ui/Modals/UseModal';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import NewOrderModal from './NewOrder';
import { ApiPromises } from 'utilities/ApiPromises';
import { activeContacts } from 'utilities/ApiRequests';
import { formalHorseName } from 'utilities/Horse';

export default function WebshopOrderListPage(): JSX.Element {
  const [catalogue, setCatalogue] = useState<CatalogueProduct[]>();
  const [horses, setHorses] = useState<Horse[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [orders, setOrders] = useState<PurchaserOrder[]>();
  const [addOrderModalOpen, setAddOrderModalOpen] = useState<boolean>(false);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { formatDate } = useAccount();
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const navigate = useNavigate();
  const { publicAccessUuid } = useParams();
  const { t } = useTranslation();

  const [newOrderPaymentLinkError, setNewOrderPaymentLinkError] = useState<
    { createdOrderUid: string; error: ApiErrorParser<Redirect> } | undefined
  >();
  const {
    closeModal: closePaymentLinkErrorModal,
    modalIsVisible: paymentLinkErrorModalVisible,
    showModal: showPaymentLinkErrorModal,
  } = useModal();

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add-order', 'New order'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          setAddOrderModalOpen(true);
        },
      },
    ];
  }, [t]);

  const descriptionForOrder = useCallback(
    (order: PurchaserOrder) => {
      const horseNames: string[] = [];

      order.order_items.forEach(value => {
        if (value.mare_uid) {
          // find the stallion name
          let stallionName: string | undefined = '';
          const stallion = catalogue?.find(product => product.uid === value.product_uid);
          if (stallion && stallion.stallion) {
            stallionName = stallion.stallion.display_name;
          }

          // get the Mare as well
          let mareName: string | undefined = '';
          const mare = horses?.find(horse => horse.uid === value.mare_uid);
          if (mare) {
            mareName = formalHorseName(mare);
          }

          const description = stallionName && mareName ? `${stallionName} x ${mareName}` : stallionName;
          horseNames.push(description);
        }
      });
      if (horseNames.length === 0) {
        // TODO We might get other order types without a mare. Give other descriptions
        // once we support other types. For now, leave empty
        return '';
      }

      if (order.order_items.find(item => item.parent_semen_order_item_uid)) {
        return `${t('repeat-semen-order-for', 'Repeat semen order for')} ${horseNames.join(', ')}`;
      } else {
        return `${t('semen-order-for', 'Semen order for')} ${horseNames.join(', ')}`;
      }
    },
    [catalogue, horses, t],
  );

  // Load data from the api/cache
  const loadApiData = useCallback(
    ({
      loadOrders = false,
      loadHorses = false,
      loadContacts,
    }: {
      loadOrders?: boolean;
      loadHorses?: boolean;
      loadContacts?: boolean;
    }): ApiPromises => {
      const promises = new ApiPromises();
      if (!selectedOrganizationUid) {
        return promises;
      }

      if ((!loadOrders && !loadHorses && !loadContacts) || loadHorses) {
        promises.appendList<Horse>(
          'webshop-horses',
          () => {
            return HorsesService.horsesList({
              organisationUid: selectedOrganizationUid,
              hidden: false,
            });
          },
          setHorses,
          generateCacheKey('webshop-horses'),
        );
      }

      if ((!loadOrders && !loadHorses && !loadContacts) || loadContacts) {
        promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganizationUid, generateCacheKey));
      }

      if ((!loadOrders && !loadHorses && !loadContacts) || loadOrders) {
        promises.setPaginated<PurchaserOrder>(
          'webshop-orders',
          page => {
            return OrdersService.ordersPurchasedList({
              purchaserUid: selectedOrganizationUid,
              supplierPublicAccessUuid: publicAccessUuid,
              page: page,
              pageSize: defaultApiPageSize,
              o: '-created_on,-id',
            });
          },
          setOrders,
        );
      }

      // Load the stallion semen products from api
      if (!loadOrders && !loadHorses && !loadContacts) {
        promises.appendList<CatalogueProduct>(
          'webshop-catalogue',
          () =>
            PublicService.apiV5PublicOrganisationsProductCatalogueList({
              organisationPublicAccessUuid: publicAccessUuid ?? '',
            }),
          setCatalogue,
          generateCacheKey('webshop-catalogue'),
        );
      }

      setApiPromises(promises);

      return promises;
    },
    [selectedOrganizationUid, generateCacheKey, publicAccessUuid],
  );

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData({});
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  return (
    <>
      <Page title={t('orders', 'Orders')} loading={apiPromises} actions={pageActions} showEmptyListPlaceholder={orders?.length === 0}>
        <PullScrollWrapper apiPromises={apiPromises}>
          <Tile noBoxOnMobile={true}>
            <table className={table}>
              <thead className={tableThead}>
                <tr className={tableHiddenHeaderSm}>
                  <td className='w-10' />
                  <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('order-date', 'Date')}</td>
                  <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('description', 'Description')}</td>
                  <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>
                    {t('requested-shipping-date', 'Requested shipping date')}
                  </td>
                  <td className='w-10 md:hidden' />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {(orders ?? []).map(order => (
                  <tr className={tableTbodyTr} key={order.uid} onClick={() => navigate(order.uid)}>
                    <td className='text-center w-10'>
                      <ShoppingCart size={24} weight='light' className='inline' />
                    </td>
                    <td className={tableHiddenColumnMd}>{order.created_on && formatDate(new Date(Date.parse(order.created_on)))}</td>
                    <td>{descriptionForOrder(order)}</td>
                    <td className={tableHiddenColumnMd}>{order.shipping_date && formatDate(new Date(Date.parse(order.shipping_date)))}</td>
                    <td className={classNames('w-10 text-center md:hidden')}>
                      <CaretRight size={22} weight='light' className='inline' />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Tile>
        </PullScrollWrapper>
      </Page>
      {horses && contacts && (
        <NewOrderModal
          catalogue={catalogue}
          open={addOrderModalOpen}
          horses={horses}
          contacts={contacts}
          onContactUpdate={() => loadApiData({ loadContacts: true })}
          onHorsesUpdate={() => {
            loadApiData({
              loadHorses: true,
            });
          }}
          onPaymentLinkFailure={(newOrderUid, error) => {
            setNewOrderPaymentLinkError({ createdOrderUid: newOrderUid, error });
            showPaymentLinkErrorModal();
          }}
          onRequestClose={(newOrderUid?: string) => {
            if (newOrderUid) {
              loadApiData({
                loadOrders: true,
                loadHorses: true,
              });
            }
            setAddOrderModalOpen(false);
          }}
        />
      )}
      <ActionModal
        open={paymentLinkErrorModalVisible}
        onClose={() => {
          setNewOrderPaymentLinkError(undefined);
        }}
        actions={[
          {
            text: t('close', 'Close'),
            variant: ButtonVariant.Default,
            onClick: closePaymentLinkErrorModal,
          },
          {
            text: t('go-to-order', 'Go to order'),
            variant: ButtonVariant.Primary,
            onClick: () => {
              navigate(newOrderPaymentLinkError?.createdOrderUid ?? '');
              closePaymentLinkErrorModal();
            },
          },
        ]}
        title={t('failed-to-create-payment-link-title', 'Failed to create payment link')}
      >
        <div className='space-y-4'>
          <p>
            {t(
              'failed-to-create-payment-link-message',
              'Failed to create a payment request for your order. Your order has been created but has not been payed. Please go to the order and try again.',
            )}
          </p>
          <ErrorSection errors={newOrderPaymentLinkError?.error} />
        </div>
      </ActionModal>
    </>
  );
}
