import { usePlanning } from 'hooks/UsePlanning';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LightningA, Warning } from '@phosphor-icons/react';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import { AvatarInitials, AvatarSize } from 'ui/Avatar';
import { contactInitials, contactName } from 'utilities/Contact';
import { activityName, CalendarActivity, CalendarActivityType } from 'utilities/Planning';
import { DefaultEnum } from 'openapi';
import { BadgeSize } from 'ui/Badge/Badge';
import { pregnancyCheckTermEnumToString } from 'utilities/Breeding';
import { RRuleSet, rrulestr } from 'rrule';
import { informalHorseName } from 'utilities/Horse';

interface Props {
  activity: CalendarActivity;
}

/**
 * An inline modal for showing an existing calendar item.
 */
export default function ActivityModalContent({ activity }: Props): JSX.Element {
  const { t } = useTranslation();
  const { stables } = usePlanning();

  const targetText = useMemo(() => {
    if (activity.stableUid) {
      const stable = stables?.find(stable => stable.uid === activity.stableUid);
      if (stable) {
        return contactName(stable.location);
      } else {
        return t('unknown-stable', 'Unknown stable');
      }
    } else {
      return t('all-staff', 'All staff');
    }
  }, [t, stables, activity]);

  const recurrenceText = useMemo(() => {
    if (!activity.rRule) {
      return undefined;
    }
    // The RRule may come with e.g. EXDATE. Use rrulestr method to be safe.
    const rRuleSet = rrulestr(activity.rRule, {
      forceset: true,
    }) as RRuleSet;
    const rRule = rRuleSet.rrules()[0];
    return rRule.toText();
  }, [activity]);

  if (activity.type === CalendarActivityType.Activity) {
    return (
      <div className='space-y-2'>
        <p className='font-medium mr-1'>
          {activityName(activity)}{' '}
          {activity.isAutomaticallyPlanned && (
            <Badge size={BadgeSize.Small} color={AllColors.Gray} className='inline-block'>
              <LightningA className='inline' /> {t('automatically-planned', 'Auto planned')}
            </Badge>
          )}
        </p>
        {activity.activityType?.default === DefaultEnum.MARE_CYCLE_CHECK && activity.pregnancyCheckTerm && (
          <div>
            <p className='text-sm font-medium text-gray-600'>{t('pregnancy-check-term', 'Pregnancy check term')}</p>
            <p>{activity.pregnancyCheckTerm ? pregnancyCheckTermEnumToString(activity.pregnancyCheckTerm) : t('unknown', 'Unknown')}</p>
          </div>
        )}
        <div>
          <p className='text-sm font-medium text-gray-600'>{t('horse', 'Horse')}</p>
          <p>{informalHorseName(activity.horse)}</p>
          {activity.horse?.warning && <ActivityModalWarning warning={activity.horse.warning} />}
        </div>
        <div>
          <p className='text-sm font-medium text-gray-600'>{t('time', 'Time')}</p>
          <p>{activity.startEndText}</p>
          {recurrenceText && <p className='text-gray-600 text-xs'>{recurrenceText}</p>}
        </div>
        <div>
          <p className='text-sm font-medium text-gray-600'>{t('status', 'Status')}</p>
          <p>{activity.done && <Badge color={AllColors.Green}>{t('activity-done', 'Done')}</Badge>}</p>
          <p>{!activity.done && <Badge color={AllColors.Blue}>{t('activity-not-done', 'Not done')}</Badge>}</p>
        </div>
        {activity.assignedTo.length > 0 && (
          <div>
            <p className='text-sm font-medium text-gray-600'>{t('assigned-to', 'Assigned to')}</p>
            {activity.assignedTo.map((assignee, index) => (
              <div key={assignee.contact?.uid ?? index} className='flex flex-row gap-1.5 mt-1.5'>
                <AvatarInitials
                  size={AvatarSize.XSmall}
                  initials={assignee.contact ? contactInitials(assignee.contact) : '?'}
                  uuid={assignee.contact?.uid}
                  color={assignee.contact?.color}
                />
                <div className='-mt-1.5'>
                  <p>{assignee.contact ? contactName(assignee.contact) : 'unknown contact'}</p>
                  <p className='text-sm -mt-1.5'>{assignee.role?.name}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        <p className='line-clamp-6'>{activity.extraInfo}</p>
      </div>
    );
  } else if (activity.type === CalendarActivityType.FacilityEvent) {
    return (
      <div className='space-y-2'>
        <div>
          <p className='text-sm font-medium text-gray-600'>{t('facility', 'Facility')}</p>
          <p>{activity.facilities.length > 0 ? activity.facilities[0].name : t('no-facility', 'No facility')}</p>
        </div>
        <div>
          <p className='text-sm font-medium text-gray-600'>{t('contact', 'Contact')}</p>
          <p>{activity.contact ? contactName(activity.contact) : t('no-contact', 'No contact')}</p>
        </div>
        <div>
          <p className='text-sm font-medium text-gray-600'>{t('horses', 'Horses')}</p>
          <p>{activity.reservation_horses.map(horse => informalHorseName(horse)).join(', ')}</p>
          {activity.is_private_reservation && <Badge color={AllColors.Fuchsia}>{t('private-reservation', 'Private reservation')}</Badge>}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className='flex pr-2'>
          <p className='grow text-sm'>{activity.startEndText}</p>
          <Badge>{targetText}</Badge>
        </div>
        {activity.title && <p className='font-medium'>{activity.title}</p>}
        {activity.text && <p className='whitespace-pre-line max-h-36 overflow-y-auto'>{activity.text}</p>}
      </div>
    );
  }
}

interface ActivityModalWarningProps {
  warning: string;
}

/**
 * Small helper component to show a warning with a read more option.
 */
function ActivityModalWarning({ warning }: ActivityModalWarningProps): JSX.Element {
  const [showAll, setShowAll] = useState<boolean>();

  const { t } = useTranslation();

  const maxChars = 100;
  const warningText = showAll ? warning : warning.slice(0, maxChars);
  const showReadMore = !showAll && warning.length > maxChars;

  return (
    <p className='text-red-500 text-sm'>
      <Warning className='inline -translate-y-0.5' /> {warningText}
      {showReadMore && (
        <>
          ...{' '}
          <button className='block text-primary underline' type='button' onClick={() => setShowAll(!showAll)}>
            {t('read-more-lowercase', 'read more')}
          </button>
        </>
      )}
    </p>
  );
}
