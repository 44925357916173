import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Hint } from 'ui/Hint';
import { Label } from 'ui/Label';
import { InputError } from 'ui/InputError';

export type RadioColorGroupOption = {
  color: string;
  secondaryColor?: string;
};

export const predefinedColors: string[] = [
  '#fa2c37', // Red 500 tailwind
  '#ff6900', // Orange 500 tailwind
  '#e17100', // Amber 500 tailwind
  '#f0b000', // Yellow 500 tailwind
  '#7cce00', // Lime 500 tailwind
  '#00c950', // Green 500 tailwind
  '#00bc7d', // Emerald 500 tailwind
  '#00bba7', // Teal 500 tailwind
  '#00b8db', // Cyan 500 tailwind
  '#00a6f5', // Sky 500 tailwind
  '#2c7fff', // Blue 500 tailwind
  '#615fff', // Indigo 500 tailwind
  '#8d51ff', // Violet 500 tailwind
  '#ad47ff', // Purple 500 tailwind
  '#e12bfb', // Fuchsia 500 tailwind
  '#f7339a', // Pink 500 tailwind
  '#ff1f57', // Rose 500 tailwind
];

export type RadioColorGroupProps<T extends FieldValues> = {
  control?: Control<T>;
  name: Path<T>;
  options: RadioColorGroupOption[];
  label?: string;
  error?: string;
  required?: boolean;
  hint?: ReactNode;
  className?: string;
};

export default function RadioColorGroup<T extends FieldValues>({
  name,
  options,
  control,
  label,
  error,
  required,
  hint,
  className,
}: RadioColorGroupProps<T>): JSX.Element {
  return (
    <div className={classNames('group relative', className)}>
      {label && (
        <Label className='mb-2'>
          {label} {required && '*'}
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <div className='flex flex-wrap relative'>
              {options.map(opt => {
                return (
                  <label key={opt.color} htmlFor={opt.color}>
                    <input
                      id={opt.color}
                      type='radio'
                      name={name}
                      value={opt.color}
                      className='opacity-0 w-0 h-0 absolute'
                      onChange={() => field.onChange(opt.color)}
                    />
                    <div
                      className={classNames('border-2 rounded-full p-0.5 cursor-pointer', {
                        'border-blue-500': field.value === opt.color,
                        'border-transparent': field.value !== opt.color,
                      })}
                    >
                      <div
                        className='text-sm w-9 h-9 bg-white'
                        style={{
                          borderRadius: '50%',
                          background: opt.secondaryColor
                            ? `linear-gradient( -45deg, ${opt.secondaryColor}, ${opt.secondaryColor} 50%, ${opt.color} 50% )`
                            : opt.color,
                        }}
                      />
                    </div>
                  </label>
                );
              })}
            </div>
          );
        }}
      />
      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
}
