// This file is generated by openapi-zod-client based in the equinem openapi file. import { z } from "zod";

import { z } from 'zod';

const Authorize = z.object({ code: z.string(), state: z.string(), KoppelSleutel: z.string(), ReferenceKey: z.string() }).passthrough();
const CountryEnum = z.enum([
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'CV',
  'KH',
  'CM',
  'CA',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'SZ',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'KP',
  'MK',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'KR',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'UM',
  'US',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
]);
const Account = z
  .object({
    uid: z.string(),
    lookup: z.string(),
    email: z.string().email(),
    agreed_to_terms_and_privacy: z.union([z.string(), z.null()]),
    username: z.string(),
    first_name: z.string().max(150),
    last_name: z.string().max(150),
    date_joined: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    country: CountryEnum,
    phone_number: z.string().max(128).optional(),
    disable_help_popup_app: z.boolean().optional(),
    disable_help_popup_portal: z.boolean().optional(),
    initials: z.string().max(3).optional(),
    last_activity: z.union([z.string(), z.null()]),
    language: z.string().max(10).optional(),
    preferred_organisation: z.union([z.string(), z.null()]).optional(),
    preferred_stable: z.union([z.string(), z.null()]).optional(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
  })
  .passthrough();
const PatchedAccount = z
  .object({
    uid: z.string(),
    lookup: z.string(),
    email: z.string().email(),
    agreed_to_terms_and_privacy: z.union([z.string(), z.null()]),
    username: z.string(),
    first_name: z.string().max(150),
    last_name: z.string().max(150),
    date_joined: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]),
    date_of_birth: z.union([z.string(), z.null()]),
    country: CountryEnum,
    phone_number: z.string().max(128),
    disable_help_popup_app: z.boolean(),
    disable_help_popup_portal: z.boolean(),
    initials: z.string().max(3),
    last_activity: z.union([z.string(), z.null()]),
    language: z.string().max(10),
    preferred_organisation: z.union([z.string(), z.null()]),
    preferred_stable: z.union([z.string(), z.null()]),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  })
  .passthrough();
const FertilizerNormTypeEnum = z.enum(['HORSE', 'PONY']);
const FertilizerNorm = z
  .object({
    start_year: z.number().int(),
    country: CountryEnum.default('NL'),
    type: FertilizerNormTypeEnum,
    nitrogen_kg: z.union([z.string(), z.null()]),
    phosphate_kg: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PaginatedFertilizerNormList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(FertilizerNorm),
  })
  .passthrough();
const Email = z.object({ email: z.string().email() }).passthrough();
const SSO = z.object({ providers: z.union([z.string(), z.null()]) }).passthrough();
const NotificationSettings = z
  .object({
    daily_unchecked_enabled: z.boolean(),
    push_silent_start: z.string(),
    push_silent_end: z.string(),
    daily_tomorrow_not_planned_enabled: z.boolean(),
    task_assigned_enabled: z.boolean(),
    daily_timing: z.string(),
    activity_done_own_horse_enabled: z.boolean(),
    daily_horse_incomplete_enabled: z.boolean(),
    feed_change_enabled: z.boolean(),
    enable_push_notifications: z.union([z.boolean(), z.null()]),
    horse_media_enabled: z.boolean(),
    enable_notify_before: z.boolean(),
  })
  .passthrough();
const CustomFCMDeviceTypeEnum = z.enum(['ios', 'android', 'web']);
const ApplicationIdEnum = z.literal('fcm_equinem');
const CustomFCMDevice = z
  .object({
    uid: z.string(),
    name: z.union([z.string(), z.null()]).optional(),
    registration_id: z.string().max(600),
    device_id: z.union([z.string(), z.null()]).optional(),
    active: z.boolean().optional().default(true),
    date_created: z.union([z.string(), z.null()]),
    type: CustomFCMDeviceTypeEnum,
    application_id: ApplicationIdEnum,
  })
  .passthrough();
const PatchedCustomFCMDevice = z
  .object({
    uid: z.string(),
    name: z.union([z.string(), z.null()]),
    registration_id: z.string().max(600),
    device_id: z.union([z.string(), z.null()]),
    active: z.boolean().default(true),
    date_created: z.union([z.string(), z.null()]),
    type: CustomFCMDeviceTypeEnum,
    application_id: ApplicationIdEnum,
  })
  .passthrough();
const PatchedNotificationSettings = z
  .object({
    daily_unchecked_enabled: z.boolean(),
    push_silent_start: z.string(),
    push_silent_end: z.string(),
    daily_tomorrow_not_planned_enabled: z.boolean(),
    task_assigned_enabled: z.boolean(),
    daily_timing: z.string(),
    activity_done_own_horse_enabled: z.boolean(),
    daily_horse_incomplete_enabled: z.boolean(),
    feed_change_enabled: z.boolean(),
    enable_push_notifications: z.union([z.boolean(), z.null()]),
    horse_media_enabled: z.boolean(),
    enable_notify_before: z.boolean(),
  })
  .passthrough();
const ColorEnum = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
  z.literal(8),
  z.literal(9),
  z.literal(10),
  z.literal(11),
  z.literal(12),
  z.literal(13),
]);
const SexEnum = z.union([z.literal(1), z.literal(2), z.literal(3)]);
const PrivateEnum = z.union([z.literal(1), z.literal(2), z.literal(3)]);
const PublicHorseMediaSet = z
  .object({
    uid: z.string(),
    url: z.string().url(),
    filename: z.string(),
    description: z.string(),
    YTVim: z.union([z.string(), z.null()]),
    content_type: z.string(),
    private: PrivateEnum,
  })
  .passthrough();
const OrganisationNAW = z
  .object({
    email: z.string().max(254).email(),
    phone_number: z.string().max(128),
    address_line1: z.string().max(255),
    address_line2: z.string().max(255),
    address_line3: z.string().max(255),
    city: z.string().max(255),
    state: z.string().max(255),
    postcode: z.string().max(64),
    country: CountryEnum,
  })
  .passthrough();
const PublicHorsePageOrganisation = z
  .object({
    public_access_uuid: z.string().uuid(),
    primary_stable_contact: OrganisationNAW,
    name: z.string(),
    logo: z.union([z.string(), z.null()]),
    website: z.string().url(),
  })
  .passthrough();
const PublicHorsePageHorse = z
  .object({
    uid: z.string(),
    display_name: z.string(),
    name: z.string(),
    date_of_birth: z.union([z.string(), z.null()]),
    color: ColorEnum,
    sex: SexEnum,
    breeder: z.string(),
    studbook: z.string(),
    UELN: z.string(),
    chip_nr: z.string(),
    id_horsetelex: z.string(),
    avatar_file: z.union([z.string(), z.null()]),
    horsemedia_set: z.array(PublicHorseMediaSet),
    organisation: PublicHorsePageOrganisation,
    withers_height: z.union([z.number(), z.null()]),
    FEI_pass_nr: z.string(),
    sire: z.string(),
    dam: z.string(),
    damdam: z.string(),
    damsire: z.string(),
    siredam: z.string(),
    siresire: z.string(),
    damgranddam1: z.string(),
    damgranddam2: z.string(),
    damgrandsire1: z.string(),
    damgrandsire2: z.string(),
    siregranddam1: z.string(),
    siregranddam2: z.string(),
    siregrandsire1: z.string(),
    siregrandsire2: z.string(),
  })
  .passthrough();
const PublicHorsePage = z
  .object({
    public_access_uuid: z.string().uuid(),
    horse: PublicHorsePageHorse,
    expires_at: z.union([z.string(), z.null()]),
    include_medical_documents: z.boolean(),
  })
  .passthrough();
const InviterOrganisation = z.object({ name: z.string() }).passthrough();
const Inviter = z.object({ first_name: z.string(), last_name: z.string() }).passthrough();
const ContactData = z.object({ first_name: z.string().max(255), last_name: z.string().max(255) }).passthrough();
const ReceivedInvitation = z
  .object({
    uuid: z.string().uuid(),
    created_on: z.string().datetime({ offset: true }),
    sent_on: z.union([z.string(), z.null()]),
    resend_on: z.union([z.string(), z.null()]),
    resend_attempts: z.number().int(),
    accepted_on: z.union([z.string(), z.null()]),
    declined_on: z.union([z.string(), z.null()]),
    declined_reason: z.string(),
    inviter_organisation: InviterOrganisation,
    inviter: Inviter,
    contact: z.union([ContactData, z.null()]),
    invitee_email: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PatchedDeclinedInvitation = z.object({ declined_reason: z.string().max(255) }).passthrough();
const DeclinedInvitation = z.object({ declined_reason: z.string().max(255) }).passthrough();
const PaymentStatus = z.enum(['OPEN', 'PROCESSING', 'PAID']);
const PaymentSummary = z.object({ paid: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/) }).passthrough();
const NestedOrder = z
  .object({ uid: z.string(), number: z.string().max(32).optional(), public_access_uuid: z.union([z.string(), z.null()]).optional() })
  .passthrough();
const TitleEnum = z.enum(['Mr', 'Miss', 'Mrs', 'Ms', 'Dr']);
const SupplierNameAndAddress = z
  .object({
    initials: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    business_name: z.string(),
    phone_number: z.string(),
    email: z.string().email(),
    address_line1: z.string(),
    address_line2: z.string(),
    address_line3: z.string(),
    country: CountryEnum,
    state: z.string(),
    postcode: z.string(),
    city: z.string(),
    title: TitleEnum,
    vat_number: z.string(),
    vat_number_checked: z.union([z.boolean(), z.null()]),
    account_number: z.string(),
    IBAN: z.string(),
    bank_code: z.string(),
    bank_name: z.string(),
    company_registration_number: z.string(),
    UBN: z.string(),
  })
  .passthrough();
const VatCategoryEnum = z.enum(['S', 'AE', 'G']);
const NestedVATPercentage = z
  .object({ percentage: z.string().regex(/^-?\d{0,2}(?:\.\d{0,2})?$/), vat_category: VatCategoryEnum })
  .passthrough();
const NamedHorse = z.object({ uid: z.string(), name: z.string().max(45) }).passthrough();
const Money = z.object({ amount: z.string(), currency: z.string() }).passthrough();
const StudbookEnum = z.enum([
  'ACE',
  'AHHA',
  'RHEIN US',
  'ATA',
  'AWR',
  'AES',
  'AWÖ',
  'AAFE',
  'AECCAá',
  'PRE',
  'CDE',
  'BH',
  'PSH',
  'LUSIT',
  'AA',
  'RHEIN AU',
  'AWHA',
  'BWB',
  'BWP',
  'BE/SIES',
  'RHEIN GB',
  'BSH',
  'CSHA',
  'CWHBA',
  'CCDM',
  'CABSH',
  'DWB',
  'EBHA',
  'ESH',
  'HANN',
  'HHSA',
  'HSH',
  'ISR-OLD NA',
  'TB',
  'ISH',
  'MASAF',
  'KFPS',
  'KWPN',
  'KWPN NA',
  'DSP (BAVAR)',
  'LWB',
  'LHBA',
  'NRPS',
  'NZHS',
  'RHEIN NZ',
  'NZWB',
  'NAS',
  'NDWB',
  'NWB',
  'DSP (BAD-WÜ)',
  'DSP (BRAND)',
  'DSP (ZWEIB)',
  'SA',
  'SACHS',
  'DSP (SATHÜ)',
  'RHEIN',
  'SAWHS',
  'SHBGB',
  'OS',
  'SF',
  'SCSL',
  'SBS',
  'ZANG',
  'ChS',
  'PZHK (M)',
  'PZHK (SP)',
  'PZHK (WLKP)',
  'SSW',
  'SLS',
  'CZEWB',
  'SWB',
  'AHS',
  'BHHS',
  'FWB',
  'ZCHKS',
  'TAF',
  'MECKL',
  'THU',
  'HOLST',
  'OLDBG',
  'TRAK',
  'HESS',
  'BWBS',
  'WSI',
  'WESTF',
  'CH',
  'ZfDP',
]);
const PublicOrderItem = z.object({ uid: z.string(), studbook: StudbookEnum.optional() }).passthrough();
const PublicInvoiceItem = z
  .object({
    uid: z.string(),
    description: z.string(),
    unit_price: z.union([z.string(), z.null()]),
    unit_price_currency: z.union([z.string(), z.null()]),
    category_uid: z.string(),
    quantity: z.union([z.string(), z.null()]),
    qualifier: z.string(),
    vat_percentage_uid: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    vat_included_in_price: z.boolean(),
    deferred: z.boolean(),
    realactivities: z.array(z.string()),
    service_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    transactionitems: z.array(z.string()),
    last_modified_on: z.string().datetime({ offset: true }),
    horse_uid: z.union([z.string(), z.null()]),
    horse: NamedHorse,
    total_vat: Money,
    order_item: PublicOrderItem,
  })
  .passthrough();
const PurchaserNameAndAddress = z
  .object({
    initials: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    business_name: z.string(),
    phone_number: z.string(),
    email: z.string().email(),
    address_line1: z.string(),
    address_line2: z.string(),
    address_line3: z.string(),
    country: CountryEnum,
    state: z.string(),
    postcode: z.string(),
    city: z.string(),
    title: TitleEnum,
    vat_number: z.string(),
    vat_number_checked: z.union([z.boolean(), z.null()]),
    studbook_preference: StudbookEnum,
    customer_id: z.string(),
  })
  .passthrough();
const PublicInvoice = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    customer_uid: z.string(),
    supplier_uid: z.string(),
    total: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    currency: z.string(),
    total_incl_vat: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    invoice_no: z.string(),
    draft: z.boolean(),
    finalized_on: z.union([z.string(), z.null()]),
    date: z.string(),
    sent_on: z.union([z.string(), z.null()]),
    moneybird_invoice_id: z.union([z.number(), z.null()]),
    exactnl_invoice_id: z.string(),
    snelstart_invoice_id: z.string(),
    yuki_invoice_id: z.string(),
    payment_status: PaymentStatus,
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    start_date_activities: z.union([z.string(), z.null()]),
    end_date_activities: z.union([z.string(), z.null()]),
    start_date_services: z.union([z.string(), z.null()]),
    end_date_services: z.union([z.string(), z.null()]),
    created_by_uid: z.string(),
    expiration_date: z.union([z.string(), z.null()]),
    first_due_interval: z.number().int(),
    payment_summary: PaymentSummary,
    order: NestedOrder.optional(),
    historic_supplier: SupplierNameAndAddress,
    language: z.string(),
    items: z.array(PublicInvoiceItem),
    historic_customer: PurchaserNameAndAddress,
    exactnl_url: z.union([z.string(), z.null()]),
    exactnl_entry_no: z.string(),
    snelstart_entry_no: z.string(),
    can_create_payment_link: z.boolean(),
    logo: z.string(),
    website: z.string(),
  })
  .passthrough();
const PaymentLink = z.object({ redirectUrl: z.string().url() }).passthrough();
const Redirect = z.object({ redirect_uri: z.string().url() }).passthrough();
const TokenObtainPair = z.object({ username: z.string(), password: z.string(), access: z.string(), refresh: z.string() }).passthrough();
const TokenRefresh = z.object({ access: z.string(), refresh: z.string() }).passthrough();
const OrganisationOAuth = z.object({ organisation_uid: z.string(), provider_name: z.string() }).passthrough();
const SocialLogin = z.object({ access_token: z.string(), code: z.string(), id_token: z.string() }).passthrough();
const UserDetails = z
  .object({
    pk: z.number().int(),
    username: z
      .string()
      .max(150)
      .regex(/^[\w.@+-]+$/),
    email: z.string().email(),
    first_name: z.string().max(150).optional(),
    last_name: z.string().max(150).optional(),
  })
  .passthrough();
const JWT = z.object({ access: z.string(), refresh: z.string(), user: UserDetails }).passthrough();
const TimeFrameEnum = z.enum(['DAY', 'MONTH', 'YEAR']);
const SemenTypeEnum = z.enum(['FRESH', 'FROZEN', 'UNDILUTED']);
const UsageTypeEnum = z.enum(['KI', 'ET', 'ICSI']);
const PickedStallionMount = z.object({ stallion_mount: z.string(), amount: z.number().int().gte(0).lte(65535) }).passthrough();
const SupplierOrderItemDetail = z
  .object({
    uid: z.string().optional(),
    order_uid: z.string(),
    product_uid: z.string().optional(),
    price_point_unit_count: z.union([z.number(), z.null()]).optional(),
    unit_price: z.union([z.string(), z.null()]).optional(),
    unit_price_currency: z.union([z.string(), z.null()]),
    upfront_payment: z.union([z.string(), z.null()]).optional(),
    upfront_payment_currency: z.union([z.string(), z.null()]),
    invoice_period: TimeFrameEnum.optional(),
    vat_percentage: NestedVATPercentage,
    quantity: z.number().int().gte(0).lte(65535).optional(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    picked_on: z.union([z.string(), z.null()]),
    picked_by_uid: z.union([z.string(), z.null()]),
    mare_uid: z.union([z.string(), z.null()]).optional(),
    semen_type: SemenTypeEnum.optional(),
    usage_type: UsageTypeEnum.optional(),
    studbook: StudbookEnum.optional(),
    parent_semen_order_item_uid: z.union([z.string(), z.null()]).optional(),
    total_vat: Money,
    variable_cost_estimate: z.union([Money, z.null()]),
    semen_collection_station: z.union([z.string(), z.null()]).optional(),
    pickedstallionmount_set: z.array(PickedStallionMount),
  })
  .passthrough();
const ParentSemenOrder = z.object({ uid: z.string(), number: z.string() }).passthrough();
const SupplierOrderDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.union([z.string(), z.null()]).optional(),
    requester_uid: z.union([z.string(), z.null()]).optional(),
    supplier_note: z.string().optional(),
    customer_note: z.string(),
    traces_certificate_number: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    last_modified_by: z.string(),
    shipping_date: z.union([z.string(), z.null()]).optional(),
    payment_summary: PaymentSummary,
    dry_run: z.boolean().optional(),
    supplier_uid: z.string(),
    order_items: z.array(SupplierOrderItemDetail).optional(),
    shipping_name: z.string().max(255).optional(),
    shipping_address_line1: z.string().max(255).optional(),
    shipping_address_line2: z.string().max(255).optional(),
    shipping_address_line3: z.string().max(255).optional(),
    shipping_city: z.string().max(255).optional(),
    shipping_state: z.string().max(255).optional(),
    shipping_postcode: z.string().max(64).optional(),
    shipping_country: CountryEnum.optional(),
    shipping_search_text: z.string(),
    shipping_location_traces_number: z.string().max(64).optional(),
    terms_accepted_on: z.union([z.string(), z.null()]),
    historic_customer: PurchaserNameAndAddress,
    send_order_confirmation: z.boolean().optional(),
    parent_semen_order: ParentSemenOrder,
  })
  .passthrough();
const PurchaserOrderItem = z
  .object({
    uid: z.string(),
    order_uid: z.string(),
    product_uid: z.string(),
    price_point_unit_count: z.union([z.number(), z.null()]).optional(),
    unit_price: z.union([z.string(), z.null()]),
    unit_price_currency: z.union([z.string(), z.null()]),
    upfront_payment: z.union([z.string(), z.null()]),
    upfront_payment_currency: z.union([z.string(), z.null()]),
    invoice_period: TimeFrameEnum.optional(),
    vat_percentage: NestedVATPercentage,
    quantity: z.number().int().gte(0).lte(65535).optional(),
    last_modified_by_uid: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    picked_on: z.union([z.string(), z.null()]),
    picked_by_uid: z.string(),
    mare_uid: z.string().optional(),
    semen_type: SemenTypeEnum.optional(),
    usage_type: UsageTypeEnum.optional(),
    studbook: StudbookEnum.optional(),
    parent_semen_order_item_uid: z.string().optional(),
    total_vat: Money,
    variable_cost_estimate: z.union([Money, z.null()]),
  })
  .passthrough();
const PurchaserOrderUpdate = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    purchaser_uid: z.union([z.string(), z.null()]).optional(),
    supplier: z.string().uuid(),
    customer_note: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    payment_summary: PaymentSummary,
    shipping_date: z.union([z.string(), z.null()]),
    order_items: z.array(PurchaserOrderItem),
    dry_run: z.boolean().optional(),
    shipping_name: z.string(),
    shipping_address_line1: z.string(),
    shipping_address_line2: z.string(),
    shipping_address_line3: z.string(),
    shipping_city: z.string(),
    shipping_state: z.string(),
    shipping_postcode: z.string(),
    shipping_country: CountryEnum,
    shipping_location_traces_number: z.string(),
    shipping_search_text: z.string(),
    parent_semen_order: ParentSemenOrder,
  })
  .passthrough();
const PatchedPurchaserOrderUpdate = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    purchaser_uid: z.union([z.string(), z.null()]),
    supplier: z.string().uuid(),
    customer_note: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    payment_summary: PaymentSummary,
    shipping_date: z.union([z.string(), z.null()]),
    order_items: z.array(PurchaserOrderItem),
    dry_run: z.boolean(),
    shipping_name: z.string(),
    shipping_address_line1: z.string(),
    shipping_address_line2: z.string(),
    shipping_address_line3: z.string(),
    shipping_city: z.string(),
    shipping_state: z.string(),
    shipping_postcode: z.string(),
    shipping_country: CountryEnum,
    shipping_location_traces_number: z.string(),
    shipping_search_text: z.string(),
    parent_semen_order: ParentSemenOrder,
  })
  .passthrough();
const RoleNames = z.object({ uid: z.string(), name: z.string(), default_id: z.union([z.number(), z.null()]) }).passthrough();
const StableNames = z.object({ uid: z.string(), name: z.string() }).passthrough();
const ModuleEnum = z.enum([
  'ACCOUNTING',
  'SHOP_AND_BREEDING',
  'FEED_AND_MEDICINE',
  'FACILITY_PLANNING',
  'FINANCIAL_AND_PAYMENT',
  'USER_ACCOUNTS',
  'HORSE_SERVICES_CARE',
  'HORSE_SERVICES_FULL',
  'RVO_REPORTS',
]);
const PricingModelEnum = z.enum(['FLAT_FEE', 'STAIR_STEP', 'TIERED', 'CREDITS']);
const PurchaserServiceContract = z
  .object({
    uid: z.string(),
    customer_uid: z.string().optional(),
    product_uid: z.string(),
    module: ModuleEnum,
    pricing_model: PricingModelEnum,
    start_dt: z.string().datetime({ offset: true }),
    duration_amount: z.number().int(),
    duration_unit: TimeFrameEnum,
    notice_period: TimeFrameEnum,
    end_dt: z.union([z.string(), z.null()]),
    is_suspended: z.boolean(),
    currency: z.union([z.string(), z.null()]),
    fixed_price: z.union([z.string(), z.null()]),
    estimated_cost: z.union([z.string(), z.null()]),
    next_tiered_instance_cost: z.union([z.string(), z.null()]),
    total_vat: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    discount_percentage: z.number().int(),
    applied_unit_count: z.union([z.number(), z.null()]),
    available_unit_count: z.union([z.number(), z.null()]),
    maximum_unit_count: z.union([z.number(), z.null()]),
    extend_automatically: z.boolean(),
    invoice_period: TimeFrameEnum,
    followup_contract_uid: z.string(),
    purchaser: z.union([z.string(), z.null()]),
  })
  .passthrough();
const OrganisationContact = z
  .object({
    uid: z.string(),
    roles: z.array(RoleNames),
    stables: z.array(StableNames),
    service_contracts: z.array(PurchaserServiceContract),
  })
  .passthrough();
const OrganisationList = z
  .object({ uid: z.string(), name: z.string().max(45), is_in_trial: z.boolean(), me: OrganisationContact })
  .passthrough();
const TimezoneEnum = z.enum([
  'Pacific/Niue',
  'Pacific/Pago_Pago',
  'Pacific/Rarotonga',
  'Pacific/Honolulu',
  'America/Adak',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'America/Metlakatla',
  'America/Juneau',
  'America/Sitka',
  'America/Anchorage',
  'America/Yakutat',
  'America/Nome',
  'Pacific/Gambier',
  'US/Pacific',
  'America/Los_Angeles',
  'America/Tijuana',
  'Pacific/Pitcairn',
  'America/Vancouver',
  'America/Cambridge_Bay',
  'America/Dawson_Creek',
  'America/Mazatlan',
  'America/Hermosillo',
  'America/Ciudad_Juarez',
  'America/Dawson',
  'America/Whitehorse',
  'America/Phoenix',
  'America/Boise',
  'America/Edmonton',
  'America/Inuvik',
  'America/Fort_Nelson',
  'America/Denver',
  'Canada/Mountain',
  'US/Mountain',
  'America/El_Salvador',
  'America/North_Dakota/Beulah',
  'America/Belize',
  'America/Menominee',
  'America/Managua',
  'America/Bahia_Banderas',
  'America/Indiana/Knox',
  'America/Tegucigalpa',
  'Pacific/Galapagos',
  'America/Winnipeg',
  'America/North_Dakota/Center',
  'America/Chihuahua',
  'America/Indiana/Tell_City',
  'America/Costa_Rica',
  'America/Ojinaga',
  'America/North_Dakota/New_Salem',
  'US/Central',
  'America/Matamoros',
  'America/Chicago',
  'America/Regina',
  'America/Guatemala',
  'America/Merida',
  'America/Swift_Current',
  'America/Monterrey',
  'America/Resolute',
  'America/Mexico_City',
  'America/Rankin_Inlet',
  'America/Detroit',
  'America/Cancun',
  'America/Kentucky/Louisville',
  'America/Havana',
  'America/Indiana/Marengo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Petersburg',
  'America/Indiana/Winamac',
  'America/Toronto',
  'America/Grand_Turk',
  'Pacific/Easter',
  'Canada/Eastern',
  'America/Guayaquil',
  'America/Lima',
  'America/Rio_Branco',
  'America/Port-au-Prince',
  'America/Kentucky/Monticello',
  'US/Eastern',
  'America/Jamaica',
  'America/Iqaluit',
  'America/Bogota',
  'America/Indiana/Vincennes',
  'America/Indiana/Vevay',
  'America/Panama',
  'America/Eirunepe',
  'America/New_York',
  'America/Porto_Velho',
  'America/Cuiaba',
  'America/Santo_Domingo',
  'America/Goose_Bay',
  'America/Manaus',
  'America/Halifax',
  'America/Martinique',
  'America/Puerto_Rico',
  'America/La_Paz',
  'America/Moncton',
  'America/Glace_Bay',
  'America/Thule',
  'America/Campo_Grande',
  'America/Barbados',
  'Atlantic/Bermuda',
  'America/Guyana',
  'America/Caracas',
  'America/Boa_Vista',
  'America/St_Johns',
  'America/Cayenne',
  'America/Santiago',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Bahia',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'America/Asuncion',
  'America/Fortaleza',
  'America/Sao_Paulo',
  'America/Argentina/Buenos_Aires',
  'Antarctica/Rothera',
  'America/Maceio',
  'Antarctica/Palmer',
  'America/Punta_Arenas',
  'America/Santarem',
  'America/Argentina/San_Luis',
  'America/Araguaina',
  'America/Argentina/Catamarca',
  'America/Recife',
  'America/Argentina/Cordoba',
  'America/Miquelon',
  'America/Argentina/La_Rioja',
  'Atlantic/Stanley',
  'America/Paramaribo',
  'America/Belem',
  'America/Argentina/Mendoza',
  'America/Montevideo',
  'America/Argentina/San_Juan',
  'America/Argentina/Tucuman',
  'Atlantic/South_Georgia',
  'America/Scoresbysund',
  'America/Nuuk',
  'America/Noronha',
  'Atlantic/Cape_Verde',
  'Atlantic/Azores',
  'America/Danmarkshavn',
  'GMT-0',
  'Africa/Bissau',
  'Europe/London',
  'Europe/Lisbon',
  'GMT',
  'Atlantic/Faroe',
  'Atlantic/Canary',
  'Africa/Sao_Tome',
  'Africa/Monrovia',
  'Africa/Abidjan',
  'Antarctica/Troll',
  'Europe/Dublin',
  'Atlantic/Madeira',
  'Europe/Vienna',
  'Africa/Ndjamena',
  'Europe/Andorra',
  'Europe/Madrid',
  'Europe/Budapest',
  'localtime',
  'Europe/Rome',
  'Europe/Prague',
  'Africa/Casablanca',
  'Europe/Brussels',
  'Africa/El_Aaiun',
  'Europe/Malta',
  'Africa/Lagos',
  'Europe/Paris',
  'Europe/Oslo',
  'Europe/Warsaw',
  'Europe/Stockholm',
  'Europe/Gibraltar',
  'Europe/Berlin',
  'Africa/Ceuta',
  'Europe/Zurich',
  'Africa/Tunis',
  'Europe/Belgrade',
  'Europe/Tirane',
  'Europe/Amsterdam',
  'Africa/Algiers',
  'Asia/Jerusalem',
  'Europe/Kaliningrad',
  'Europe/Chisinau',
  'Europe/Athens',
  'Africa/Juba',
  'Europe/Vilnius',
  'Asia/Beirut',
  'Europe/Bucharest',
  'Asia/Famagusta',
  'Asia/Nicosia',
  'Europe/Helsinki',
  'Asia/Hebron',
  'Africa/Johannesburg',
  'Africa/Maputo',
  'Africa/Tripoli',
  'Europe/Tallinn',
  'Asia/Gaza',
  'Europe/Sofia',
  'Africa/Cairo',
  'Africa/Khartoum',
  'Europe/Kyiv',
  'Europe/Riga',
  'Africa/Windhoek',
  'Europe/Volgograd',
  'Europe/Istanbul',
  'Africa/Nairobi',
  'Europe/Simferopol',
  'Asia/Kuwait',
  'Asia/Riyadh',
  'Europe/Minsk',
  'Asia/Baghdad',
  'Europe/Moscow',
  'Asia/Qatar',
  'Asia/Amman',
  'Europe/Kirov',
  'Asia/Damascus',
  'Asia/Tehran',
  'Europe/Saratov',
  'Europe/Astrakhan',
  'Asia/Tbilisi',
  'Asia/Baku',
  'Asia/Dubai',
  'Indian/Mauritius',
  'Europe/Samara',
  'Europe/Ulyanovsk',
  'Asia/Yerevan',
  'Asia/Kabul',
  'Asia/Qostanay',
  'Asia/Yekaterinburg',
  'Asia/Almaty',
  'Asia/Karachi',
  'Asia/Oral',
  'Asia/Aqtau',
  'Asia/Ashgabat',
  'Asia/Samarkand',
  'Indian/Maldives',
  'Asia/Dushanbe',
  'Asia/Atyrau',
  'Antarctica/Mawson',
  'Asia/Qyzylorda',
  'Asia/Tashkent',
  'Antarctica/Vostok',
  'Asia/Aqtobe',
  'Asia/Kolkata',
  'Asia/Colombo',
  'Asia/Kathmandu',
  'Asia/Thimphu',
  'Indian/Chagos',
  'Asia/Dhaka',
  'Asia/Omsk',
  'Asia/Bishkek',
  'Asia/Urumqi',
  'Asia/Yangon',
  'Asia/Jakarta',
  'Asia/Hovd',
  'Asia/Ho_Chi_Minh',
  'Asia/Bangkok',
  'Asia/Pontianak',
  'Asia/Krasnoyarsk',
  'Asia/Tomsk',
  'Antarctica/Davis',
  'Asia/Barnaul',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Antarctica/Casey',
  'Asia/Singapore',
  'Asia/Makassar',
  'Australia/Perth',
  'Asia/Irkutsk',
  'Asia/Macau',
  'Asia/Manila',
  'Asia/Shanghai',
  'Asia/Ulaanbaatar',
  'Asia/Kuching',
  'Asia/Hong_Kong',
  'Asia/Taipei',
  'Australia/Eucla',
  'Pacific/Palau',
  'Asia/Pyongyang',
  'Asia/Tokyo',
  'Asia/Chita',
  'Asia/Jayapura',
  'Asia/Yakutsk',
  'Asia/Dili',
  'Asia/Seoul',
  'Asia/Khandyga',
  'Australia/Darwin',
  'Pacific/Guam',
  'Australia/Brisbane',
  'Asia/Ust-Nera',
  'Pacific/Port_Moresby',
  'Australia/Lindeman',
  'Asia/Vladivostok',
  'Australia/Adelaide',
  'Australia/Broken_Hill',
  'Pacific/Bougainville',
  'Pacific/Guadalcanal',
  'Asia/Sakhalin',
  'Antarctica/Macquarie',
  'Asia/Magadan',
  'Australia/Canberra',
  'Australia/Hobart',
  'Asia/Srednekolymsk',
  'Pacific/Efate',
  'Australia/Sydney',
  'Australia/Melbourne',
  'Australia/Lord_Howe',
  'Pacific/Noumea',
  'Pacific/Kosrae',
  'Pacific/Tarawa',
  'Pacific/Norfolk',
  'Pacific/Nauru',
  'Asia/Kamchatka',
  'Pacific/Fiji',
  'Asia/Anadyr',
  'Pacific/Kwajalein',
  'Pacific/Kanton',
  'Pacific/Fakaofo',
  'Pacific/Tongatapu',
  'Pacific/Auckland',
  'Pacific/Apia',
  'Pacific/Chatham',
  'Pacific/Kiritimati',
]);
const TypeOfOrganisationEnum = z.union([z.literal(0), z.literal(1), z.literal(2)]);
const EnabledModulesEnum = z.enum(['horses', 'activities', 'breeding', 'shop', 'financial', 'contacts', 'feed', 'facilities']);
const Organisation = z
  .object({
    uid: z.string(),
    created_by_uid: z.string(),
    last_modified_by_uid: z.string(),
    public_access_uuid: z.string().uuid(),
    name: z.string().max(45),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    is_paid: z.boolean(),
    is_bp: z.boolean(),
    shop_terms_conditions: z.union([z.string(), z.null()]).optional(),
    use_one_stop_shop: z.boolean().optional(),
    timezone: TimezoneEnum.optional(),
    expected_no_horses: z.union([z.number(), z.null()]),
    weeks_between_insemination_and_vaccination: z.union([z.number(), z.null()]).optional(),
    days_between_insemination_and_pregnancy_check: z.union([z.number(), z.null()]).optional(),
    equiboard_refresh_rate: z.number().int().gte(0).lte(60).optional(),
    equiboard_screensaver_time: z.number().gte(0).lte(60).optional(),
    equiboard_lockscreendarkness: z.number().gte(0).lte(1).optional(),
    also_send_invoice_to_email: z.string().max(254).email().optional(),
    website: z.string().max(200).url().optional(),
    logo: z.union([z.string(), z.null()]).optional(),
    invoice_subject: z.string().max(45).optional(),
    invoice_text: z.string().optional(),
    currency: z.string().max(3).optional(),
    invoice_footer_1: z.string().optional(),
    invoice_footer_2: z.string().optional(),
    max_no_horses: z.union([z.number(), z.null()]).optional(),
    last_information_send: z.union([z.string(), z.null()]),
    number_of_information_send_last_day: z.union([z.number(), z.null()]),
    relatienummer_rvo: z.string(),
    type_of_organisation: TypeOfOrganisationEnum,
    enabled_modules: z.array(EnabledModulesEnum),
    invoice_email_by_equinem: z.boolean().optional(),
    primary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    secondary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    show_logo_on_invoice: z.boolean().optional(),
    suggested_breeding_product_down_payment: z
      .string()
      .regex(/^-?\d{0,8}(?:\.\d{0,2})?$/)
      .optional(),
    down_payment_permitted_countries: z.array(CountryEnum).max(249),
    invoice_first_due_interval: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    can_create_payment_link: z.boolean(),
    only_use_ad: z.boolean(),
    microsoft_ad_group_id: z.string(),
    exactnl_sales_journal: z.string().max(100).optional(),
    no_breeding_boxes: z.union([z.number(), z.null()]).optional(),
  })
  .passthrough();
const ActivityTypePrice = z
  .object({
    uid: z.string(),
    activity_type_uid: z.string(),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    category_uid: z.string(),
    vat_included_in_price: z.boolean().optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const PatchedActivityTypePrice = z
  .object({
    uid: z.string(),
    activity_type_uid: z.string(),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    category_uid: z.string(),
    vat_included_in_price: z.boolean(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const FertilizerBudgetTypeEnum = z.enum(['ANIMAL_NITROGEN', 'TOTAL_NITROGEN', 'TOTAL_PHOSPHATE']);
const LandTypeNitrogenEnum = z.enum(['FARM_LAND', 'NATURE_LAND', 'WATER_BARRIER', 'OTHER']);
const LandTypePhosphateEnum = z.enum(['FARM_LAND', 'GRASS_LAND']);
const FertilizerConsumer = z
  .object({
    uid: z.string(),
    contact_uid: z.string(),
    description: z.string().max(64),
    fertilizer_budget_type: FertilizerBudgetTypeEnum,
    land_type_nitrogen: LandTypeNitrogenEnum.optional(),
    land_type_phosphate: LandTypePhosphateEnum.optional(),
    is_follow_up_crop: z.boolean().optional(),
    year: z.number().int().gte(2000).lte(2099),
    has_derogation: z.boolean().optional(),
    area: z.number().int().gte(0).lte(65535),
    consumption_norm: z.number().int().gte(0).lte(65535),
    total: z.number().int(),
  })
  .passthrough();
const PaginatedFertilizerConsumerList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(FertilizerConsumer),
  })
  .passthrough();
const PatchedFertilizerConsumer = z
  .object({
    uid: z.string(),
    contact_uid: z.string(),
    description: z.string().max(64),
    fertilizer_budget_type: FertilizerBudgetTypeEnum,
    land_type_nitrogen: LandTypeNitrogenEnum,
    land_type_phosphate: LandTypePhosphateEnum,
    is_follow_up_crop: z.boolean(),
    year: z.number().int().gte(2000).lte(2099),
    has_derogation: z.boolean(),
    area: z.number().int().gte(0).lte(65535),
    consumption_norm: z.number().int().gte(0).lte(65535),
    total: z.number().int(),
  })
  .passthrough();
const FertilizerOriginEnum = z.enum([
  'ANIMAL_MANURE_SUPPLY',
  'MILK_PRODUCING_COWS',
  'GRAZING_ANIMALS',
  'EQUINEM_HORSES',
  'STABLE_ANIMALS_FEED',
  'OTHER_ORGANIC_SUPPLY',
  'ANORGANIC_SUPPLY',
]);
const MutationTypeEnum = z.enum(['BEGIN_AMOUNT', 'IMPORT', 'PRODUCTION', 'EXPORT', 'END_AMOUNT']);
const OperatorEnum = z.enum(['ADD', 'SUBTRACT']);
const ProductionBalanceItem = z
  .object({
    uid: z.string(),
    name: z.string().max(255),
    contact_uid: z.string(),
    year: z.number().int().gte(2000).lte(2099),
    fertilizer_origin: FertilizerOriginEnum,
    mutation_type: MutationTypeEnum,
    nitrogen_weight: z.union([z.number(), z.null()]).optional(),
    phosphate_weight: z.union([z.number(), z.null()]).optional(),
    operator: OperatorEnum,
    is_animal_manure: z.boolean(),
  })
  .passthrough();
const PaginatedProductionBalanceItemList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(ProductionBalanceItem),
  })
  .passthrough();
const PatchedProductionBalanceItem = z
  .object({
    uid: z.string(),
    name: z.string().max(255),
    contact_uid: z.string(),
    year: z.number().int().gte(2000).lte(2099),
    fertilizer_origin: FertilizerOriginEnum,
    mutation_type: MutationTypeEnum,
    nitrogen_weight: z.union([z.number(), z.null()]),
    phosphate_weight: z.union([z.number(), z.null()]),
    operator: OperatorEnum,
    is_animal_manure: z.boolean(),
  })
  .passthrough();
const NitrogenApplication = z
  .object({
    uid: z.string(),
    contact_uid: z.string(),
    year: z.number().int().gte(2000).lte(2099),
    fertilizer_description: z.string().max(128),
    application_description: z.string().max(128).optional(),
    is_animal_manure: z.boolean(),
    nitrogen_weight: z.number().int().gte(0).lte(65535),
    application_coefficient: z.number().int().gte(0).lte(100).optional(),
    effective_nitrogen_weight: z.number().int(),
  })
  .passthrough();
const PaginatedNitrogenApplicationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(NitrogenApplication),
  })
  .passthrough();
const PatchedNitrogenApplication = z
  .object({
    uid: z.string(),
    contact_uid: z.string(),
    year: z.number().int().gte(2000).lte(2099),
    fertilizer_description: z.string().max(128),
    application_description: z.string().max(128),
    is_animal_manure: z.boolean(),
    nitrogen_weight: z.number().int().gte(0).lte(65535),
    application_coefficient: z.number().int().gte(0).lte(100),
    effective_nitrogen_weight: z.number().int(),
  })
  .passthrough();
const ServiceContact = z
  .object({
    uid: z.string(),
    start_date: z.string(),
    end_date: z.union([z.string(), z.null()]).optional(),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    customer_uid: z.string(),
    service_uid: z.string(),
    vat_included_in_price: z.boolean().optional(),
    horse_uid: z.union([z.string(), z.null()]).optional(),
    created_by_uid: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const PatchedServiceContact = z
  .object({
    uid: z.string(),
    start_date: z.string(),
    end_date: z.union([z.string(), z.null()]),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    customer_uid: z.string(),
    service_uid: z.string(),
    vat_included_in_price: z.boolean(),
    horse_uid: z.union([z.string(), z.null()]),
    created_by_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const HorseMedia = z
  .object({
    uid: z.string(),
    file: z.union([z.string(), z.null()]).optional(),
    file_download_url: z.string(),
    filename: z.string().max(255),
    description: z.string().max(255).optional(),
    YTVim: z.union([z.string(), z.null()]).optional(),
    private: PrivateEnum.optional(),
    horse_uid: z.string(),
    content_type: z.string().max(255).optional(),
    created_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const PaginatedHorseMediaList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(HorseMedia) })
  .passthrough();
const HorseMediaUpdate = z
  .object({
    uid: z.string(),
    description: z.string().max(255).optional(),
    content_type: z.string().max(255).optional(),
    filename: z.string().max(255),
  })
  .passthrough();
const PatchedHorseMediaUpdate = z
  .object({ uid: z.string(), description: z.string().max(255), content_type: z.string().max(255), filename: z.string().max(255) })
  .passthrough();
const HorseOwner = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    contact_uid: z.string(),
    percentage: z
      .string()
      .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
      .optional(),
    owner_since: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const PaginatedHorseOwnerList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(HorseOwner) })
  .passthrough();
const PatchedHorseOwner = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    contact_uid: z.string(),
    percentage: z.string().regex(/^-?\d{0,3}(?:\.\d{0,2})?$/),
    owner_since: z.union([z.string(), z.null()]),
  })
  .passthrough();
const EmployabilityStatusEnum = z.enum([
  'full',
  'limited for training',
  'limited for service',
  'limited for service and training',
  'not employable',
]);
const HorseEmployabilityStatus = z
  .object({
    uid: z.string(),
    employability_status: EmployabilityStatusEnum.optional(),
    motivation: z.string(),
    created_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const HorsePage = z
  .object({
    uid: z.string(),
    public_access_uuid: z.string().uuid(),
    expires_at: z.union([z.string(), z.null()]).optional(),
    include_medical_documents: z.boolean().optional(),
  })
  .passthrough();
const PhaseStatusEnum = z.enum(['F1', 'F2', 'F3', 'F4', 'F5', 'trial', 'lesson']);
const HorsePhaseStatus = z
  .object({ uid: z.string(), phase_status: PhaseStatusEnum.optional(), created_on: z.string().datetime({ offset: true }) })
  .passthrough();
const ReportTypeEnum = z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5), z.literal(6), z.literal(7)]);
const ReportedHorseRelocationStatusEnum = z.enum(['IC', 'DG', 'VG', 'IT', 'ID', 'HE']);
const ReportedHorseRelocation = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    horselocation_uid: z.union([z.string(), z.null()]).optional(),
    report_type: ReportTypeEnum,
    meldingnummer: z.string().max(30).optional(),
    last_modified_by_uid: z.union([z.string(), z.null()]).optional(),
    created_by_uid: z.union([z.string(), z.null()]).optional(),
    last_modified_on: z.string().datetime({ offset: true }),
    external_location: z.string().max(100).optional(),
    status: ReportedHorseRelocationStatusEnum.optional(),
    statusOms: z.string().optional(),
    created_on: z.string().datetime({ offset: true }),
    date: z.string(),
  })
  .passthrough();
const PaginatedReportedHorseRelocationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(ReportedHorseRelocation),
  })
  .passthrough();
const CreateReportedHorseRelocation = z
  .object({
    horselocation_uid: z.string(),
    report_type: ReportTypeEnum,
    submit: z.boolean().optional().default(false),
    dierHaarKleur: z.string().max(3).optional(),
    dierHerkomstLandCode: z.string().max(3).optional(),
    dierBestemmingLandCode: z.string().max(3).optional(),
  })
  .passthrough();
const RvOEUCountry = z
  .object({ code: z.string(), naam: z.string(), isoNummerCode: z.string(), datumIngang: z.string(), datumEinde: z.string().optional() })
  .passthrough();
const RvOHorseHairColor = z
  .object({
    code: z.string(),
    omschrijving: z.string(),
    dierSoort: z.number().int(),
    datumIngang: z.string(),
    datumEinde: z.string().optional(),
  })
  .passthrough();
const SentInvitation = z
  .object({
    uuid: z.string().uuid(),
    created_on: z.string().datetime({ offset: true }),
    sent_on: z.union([z.string(), z.null()]),
    resend_on: z.union([z.string(), z.null()]),
    resend_attempts: z.number().int(),
    accepted_on: z.union([z.string(), z.null()]),
    declined_on: z.union([z.string(), z.null()]),
    declined_reason: z.string(),
    contact: z.union([ContactData, z.null()]),
    invitee_email: z.union([z.string(), z.null()]),
    contact_uid: z.union([z.string(), z.null()]).optional(),
    roles: z.array(z.string()),
    stables: z.array(z.string()),
    inviter_organisation_uid: z.string(),
    inviter_uid: z.string(),
  })
  .passthrough();
const InvoiceItem = z
  .object({
    uid: z.string().optional(),
    description: z.string(),
    unit_price: z.union([z.string(), z.null()]).optional(),
    unit_price_currency: z.union([z.string(), z.null()]),
    category_uid: z.string(),
    quantity: z.union([z.string(), z.null()]).optional(),
    qualifier: z.string().max(15).optional(),
    vat_percentage_uid: z.union([z.string(), z.null()]).optional(),
    vat_percentage: NestedVATPercentage,
    vat_included_in_price: z.boolean().optional(),
    deferred: z.boolean().optional(),
    realactivities: z.array(z.string()).optional(),
    service_uid: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    transactionitems: z.array(z.string()).optional(),
    last_modified_on: z.string().datetime({ offset: true }),
    horse_uid: z.union([z.string(), z.null()]).optional(),
    horse: NamedHorse,
    total_vat: Money,
    invoice_uid: z.string(),
  })
  .passthrough();
const PatchedInvoiceItem = z
  .object({
    uid: z.string(),
    description: z.string(),
    unit_price: z.union([z.string(), z.null()]),
    unit_price_currency: z.union([z.string(), z.null()]),
    category_uid: z.string(),
    quantity: z.union([z.string(), z.null()]),
    qualifier: z.string().max(15),
    vat_percentage_uid: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    vat_included_in_price: z.boolean(),
    deferred: z.boolean(),
    realactivities: z.array(z.string()),
    service_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    transactionitems: z.array(z.string()),
    last_modified_on: z.string().datetime({ offset: true }),
    horse_uid: z.union([z.string(), z.null()]),
    horse: NamedHorse,
    total_vat: Money,
    invoice_uid: z.string(),
  })
  .passthrough();
const DepartureReasonEnum = z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5), z.literal(6)]);
const BestemmingLandCodeEnum = z.enum([
  'BE',
  'BG',
  'CY',
  'DK',
  'DE',
  'EE',
  'FI',
  'FR',
  'EL',
  'UK',
  'HU',
  'IE',
  'IT',
  'HR',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'XI',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'ES',
  'ES2',
  'CZ',
  'SE',
]);
const HorseLocation = z
  .object({
    uid: z.string(),
    location_uid: z.string(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    arrival_date: z.string(),
    arrival_is_import: z.boolean().optional(),
    departure_reason: DepartureReasonEnum.optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    departure_is_export: z.boolean().optional(),
    departure_is_dead: z.boolean().optional(),
    departure_date: z.union([z.string(), z.null()]).optional(),
    horse_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    arrival_connected_to_departure: z.union([z.string(), z.null()]),
    departure_connected_to_arrival: z.union([z.string(), z.null()]),
    hide_arrival_report_notification: z.boolean().optional(),
    hide_departure_report_notification: z.boolean().optional(),
    herkomstLandCode: BestemmingLandCodeEnum.optional(),
    bestemmingLandCode: BestemmingLandCodeEnum.optional(),
    reportedhorserelocation_set: z.array(ReportedHorseRelocation),
  })
  .passthrough();
const PaginatedHorseLocationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(HorseLocation),
  })
  .passthrough();
const HorseLocationUpdate = z
  .object({
    arrival_date: z.string(),
    departure_date: z.union([z.string(), z.null()]).optional(),
    herkomstLandCode: BestemmingLandCodeEnum.optional(),
    bestemmingLandCode: BestemmingLandCodeEnum.optional(),
  })
  .passthrough();
const PatchedHorseLocationUpdate = z
  .object({
    arrival_date: z.string(),
    departure_date: z.union([z.string(), z.null()]),
    herkomstLandCode: BestemmingLandCodeEnum,
    bestemmingLandCode: BestemmingLandCodeEnum,
  })
  .passthrough();
const ArrivalOrDepartureEnum = z.enum(['arrival', 'departure']);
const HorseLocationMutation = z
  .object({
    uid: z.string(),
    location_uid: z.string(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    arrival_date: z.string(),
    departure_date: z.union([z.string(), z.null()]).optional(),
    horse_uid: z.string(),
    arrival_goal_exception: z.boolean().optional(),
    days_left_for_registration: z.string(),
    arrival_or_departure: ArrivalOrDepartureEnum,
  })
  .passthrough();
const PaginatedHorseLocationMutationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(HorseLocationMutation),
  })
  .passthrough();
const IsPregnantEnum = z.enum(['YES', 'NO', 'DUAL_PREGNANCY']);
const UterusEnum = z.enum(['LARGE EDEMA', 'MEDIUM EDEMA', 'SMALL EDEMA', 'NO EDEMA']);
const LocationEnum = z.enum(['LEFT', 'RIGHT']);
const SoftnessEnum = z.enum(['HARD', 'QUITE HARD', 'QUITE SOFT', 'SOFT']);
const NestedEgg = z
  .object({
    uid: z.string().optional(),
    location: LocationEnum,
    size_metric: z.string().regex(/^-?\d{0,1}(?:\.\d{0,1})?$/),
    hardness: SoftnessEnum,
  })
  .passthrough();
const NestedCyst = z
  .object({
    uid: z.string().optional(),
    size_metric: z.string().regex(/^-?\d{0,1}(?:\.\d{0,1})?$/),
    x: z.number().int().gte(-2147483648).lte(2147483647),
    y: z.number().int().gte(-2147483648).lte(2147483647),
  })
  .passthrough();
const PregnancyCheckTermEnum = z.enum(['FIRST', 'SECOND', 'THIRD']);
const FollowUpInseminationCheck = z
  .object({
    date: z.string(),
    order_item_uid: z.union([z.string(), z.null()]).optional(),
    pregnancy_check_term: PregnancyCheckTermEnum.optional(),
    extra_info: z.string().optional(),
  })
  .passthrough();
const FollowUpMareCycleCheck = z
  .object({
    date: z.string(),
    order_item_uid: z.union([z.string(), z.null()]).optional(),
    pregnancy_check_term: PregnancyCheckTermEnum.optional(),
    extra_info: z.string().optional(),
  })
  .passthrough();
const MareCycleCheck = z
  .object({
    uid: z.string(),
    is_pregnant: IsPregnantEnum,
    uterus: UterusEnum,
    uterus_has_fluid: z.boolean().optional(),
    egg_set: z.array(NestedEgg).optional(),
    cyst_set: z.array(NestedCyst).optional(),
    cervix: SoftnessEnum,
    mare_uid: z.string(),
    date: z.string(),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    add_followup_insemination: FollowUpInseminationCheck.optional(),
    add_followup_marecyclechecks: z.array(FollowUpMareCycleCheck).optional(),
    activity_uid: z.string().optional(),
    order_item_uid: z.union([z.string(), z.null()]).optional(),
    pregnancy_check_term: PregnancyCheckTermEnum.optional(),
    extra_info: z.string().optional(),
  })
  .passthrough();
const PaginatedMareCycleCheckList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(MareCycleCheck),
  })
  .passthrough();
const PatchedMareCycleCheck = z
  .object({
    uid: z.string(),
    is_pregnant: IsPregnantEnum,
    uterus: UterusEnum,
    uterus_has_fluid: z.boolean(),
    egg_set: z.array(NestedEgg),
    cyst_set: z.array(NestedCyst),
    cervix: SoftnessEnum,
    mare_uid: z.string(),
    date: z.string(),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    add_followup_insemination: FollowUpInseminationCheck,
    add_followup_marecyclechecks: z.array(FollowUpMareCycleCheck),
    activity_uid: z.string(),
    order_item_uid: z.union([z.string(), z.null()]),
    pregnancy_check_term: PregnancyCheckTermEnum,
    extra_info: z.string(),
  })
  .passthrough();
const OrderItemStallionMountPicker = z.object({ pickedstallionmount_set: z.array(PickedStallionMount) }).passthrough();
const EventStatus = z.enum(['confirmed', 'tentative', 'cancelled']);
const ActivityContactRole = z
  .object({ role_uid: z.union([z.string(), z.null()]).optional(), contact_uid: z.string(), primary: z.boolean().optional() })
  .passthrough();
const WorkloadEnum = z.union([z.literal(1), z.literal(2), z.literal(3)]);
const ShowStatusEnum = z.enum(['PLA', 'SIG', 'OK', 'NOK', 'POS']);
const DatetimeOrDateField = z.object({ datetime: z.string().datetime({ offset: true }), date: z.string() }).passthrough();
const RealActivities = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    activity_type_uid: z.string(),
    remind: z.boolean().optional(),
    done_on: z.union([z.string(), z.null()]),
    set_done: z.boolean().optional().default(false),
    done_by_uid: z.union([z.string(), z.null()]),
    parent_uid: z.union([z.string(), z.null()]),
    dry_run: z.boolean().optional(),
    daypart: z.union([z.number(), z.null()]).optional(),
    ordering: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    status: EventStatus.optional().default('confirmed'),
    action_holder_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    activitycontactrole_set: z.array(ActivityContactRole).optional(),
    stallionmount_uid: z.string(),
    marecyclecheck_uid: z.string(),
    order_item_uid: z.union([z.string(), z.null()]).optional(),
    stallion_uid: z.union([z.string(), z.null()]),
    facility_uids: z.array(z.string()).optional(),
    breeding_box_no: z.union([z.number(), z.null()]).optional(),
    level: z.string().max(45).optional(),
    extra_info: z.string().optional(),
    intensity: z.union([z.number(), z.null()]).optional(),
    estimated_intensity: WorkloadEnum.optional(),
    show: z.union([z.string(), z.null()]).optional(),
    sire: z.string().max(60).optional(),
    external_url: z.string().max(2000).url().optional(),
    heart_rate: z.union([z.number(), z.null()]).optional(),
    race_time: z.union([z.string(), z.null()]).optional(),
    show_status: ShowStatusEnum.optional(),
    workload: WorkloadEnum.optional(),
    pregnancy_check_term: PregnancyCheckTermEnum.optional(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    recurrence: z.string().optional(),
  })
  .passthrough();
const PaginatedRealActivitiesList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(RealActivities),
  })
  .passthrough();
const EventsToChangeEnum = z.enum(['ONE', 'FUTURE', 'ALL']);
const RealActivitiesUpdate = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    activity_type_uid: z.string(),
    remind: z.boolean().optional(),
    done_on: z.union([z.string(), z.null()]),
    set_done: z.boolean().optional().default(false),
    done_by_uid: z.union([z.string(), z.null()]),
    parent_uid: z.union([z.string(), z.null()]),
    dry_run: z.boolean().optional(),
    daypart: z.union([z.number(), z.null()]).optional(),
    ordering: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    status: EventStatus.optional().default('confirmed'),
    action_holder_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    activitycontactrole_set: z.array(ActivityContactRole).optional(),
    stallionmount_uid: z.string(),
    marecyclecheck_uid: z.string(),
    order_item_uid: z.union([z.string(), z.null()]).optional(),
    stallion_uid: z.union([z.string(), z.null()]),
    facility_uids: z.array(z.string()).optional(),
    breeding_box_no: z.union([z.number(), z.null()]).optional(),
    level: z.string().max(45).optional(),
    extra_info: z.string().optional(),
    intensity: z.union([z.number(), z.null()]).optional(),
    estimated_intensity: WorkloadEnum.optional(),
    show: z.union([z.string(), z.null()]).optional(),
    sire: z.string().max(60).optional(),
    external_url: z.string().max(2000).url().optional(),
    heart_rate: z.union([z.number(), z.null()]).optional(),
    race_time: z.union([z.string(), z.null()]).optional(),
    show_status: ShowStatusEnum.optional(),
    workload: WorkloadEnum.optional(),
    pregnancy_check_term: PregnancyCheckTermEnum.optional(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    recurrence: z.string().optional(),
    events_to_change: EventsToChangeEnum.optional().default('ONE'),
  })
  .passthrough();
const PatchedRealActivitiesUpdate = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    activity_type_uid: z.string(),
    remind: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    set_done: z.boolean().default(false),
    done_by_uid: z.union([z.string(), z.null()]),
    parent_uid: z.union([z.string(), z.null()]),
    dry_run: z.boolean(),
    daypart: z.union([z.number(), z.null()]),
    ordering: z.number().int().gte(-2147483648).lte(2147483647),
    status: EventStatus.default('confirmed'),
    action_holder_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    activitycontactrole_set: z.array(ActivityContactRole),
    stallionmount_uid: z.string(),
    marecyclecheck_uid: z.string(),
    order_item_uid: z.union([z.string(), z.null()]),
    stallion_uid: z.union([z.string(), z.null()]),
    facility_uids: z.array(z.string()),
    breeding_box_no: z.union([z.number(), z.null()]),
    level: z.string().max(45),
    extra_info: z.string(),
    intensity: z.union([z.number(), z.null()]),
    estimated_intensity: WorkloadEnum,
    show: z.union([z.string(), z.null()]),
    sire: z.string().max(60),
    external_url: z.string().max(2000).url(),
    heart_rate: z.union([z.number(), z.null()]),
    race_time: z.union([z.string(), z.null()]),
    show_status: ShowStatusEnum,
    workload: WorkloadEnum,
    pregnancy_check_term: PregnancyCheckTermEnum,
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    recurrence: z.string(),
    events_to_change: EventsToChangeEnum.default('ONE'),
  })
  .passthrough();
const PatchedEventDestroy = z.object({ events_to_change: EventsToChangeEnum.default('ONE') }).passthrough();
const PatchedRealActivitiesSetDoneAndUnDone = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    activity_type_uid: z.string(),
    remind: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    set_done: z.boolean().default(false),
    done_by_uid: z.union([z.string(), z.null()]),
    parent_uid: z.union([z.string(), z.null()]),
    dry_run: z.boolean(),
    daypart: z.union([z.number(), z.null()]),
    ordering: z.number().int(),
    status: EventStatus.default('confirmed'),
    action_holder_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    activitycontactrole_set: z.array(ActivityContactRole),
    stallionmount_uid: z.string(),
    marecyclecheck_uid: z.string(),
    order_item_uid: z.union([z.string(), z.null()]),
    stallion_uid: z.union([z.string(), z.null()]),
    facility_uids: z.array(z.string()),
    breeding_box_no: z.union([z.number(), z.null()]),
    level: z.string().max(45),
    extra_info: z.string(),
    intensity: z.union([z.number(), z.null()]),
    estimated_intensity: WorkloadEnum,
    show: z.union([z.string(), z.null()]),
    sire: z.string().max(60),
    external_url: z.string().max(2000).url(),
    heart_rate: z.union([z.number(), z.null()]),
    race_time: z.union([z.string(), z.null()]),
    show_status: ShowStatusEnum,
    workload: WorkloadEnum,
    pregnancy_check_term: PregnancyCheckTermEnum,
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    recurrence: z.string(),
    events_to_change: EventsToChangeEnum.default('ONE'),
  })
  .passthrough();
const RealActivitiesSetDoneAndUnDone = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    activity_type_uid: z.string(),
    remind: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    set_done: z.boolean().default(false),
    done_by_uid: z.union([z.string(), z.null()]),
    parent_uid: z.union([z.string(), z.null()]),
    dry_run: z.boolean(),
    daypart: z.union([z.number(), z.null()]),
    ordering: z.number().int(),
    status: EventStatus.default('confirmed'),
    action_holder_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    activitycontactrole_set: z.array(ActivityContactRole),
    stallionmount_uid: z.string(),
    marecyclecheck_uid: z.string(),
    order_item_uid: z.union([z.string(), z.null()]),
    stallion_uid: z.union([z.string(), z.null()]),
    facility_uids: z.array(z.string()),
    breeding_box_no: z.union([z.number(), z.null()]).optional(),
    level: z.string().max(45),
    extra_info: z.string(),
    intensity: z.union([z.number(), z.null()]),
    estimated_intensity: WorkloadEnum,
    show: z.union([z.string(), z.null()]),
    sire: z.string().max(60),
    external_url: z.string().max(2000).url(),
    heart_rate: z.union([z.number(), z.null()]),
    race_time: z.union([z.string(), z.null()]),
    show_status: ShowStatusEnum,
    workload: WorkloadEnum,
    pregnancy_check_term: PregnancyCheckTermEnum,
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    recurrence: z.string(),
    events_to_change: EventsToChangeEnum.default('ONE'),
  })
  .passthrough();
const InvoiceLanguageEnum = z.enum(['nl', 'en', 'de']);
const BusinessTypeEnum = z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]);
const Contact = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])).optional(),
    initials: z.string().max(3).optional(),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])).optional(),
    first_name: z.string().max(255).optional(),
    last_name: z.string().max(255).optional(),
    studbook_preference: StudbookEnum.optional(),
    business_name: z.string().max(255).optional(),
    phone_number: z.string().max(128).optional(),
    email: z.string().max(254).email().optional(),
    external_location: z.boolean().optional(),
    customer_id: z.string().max(7).optional(),
    moneybird_contact_id: z.union([z.number(), z.null()]).optional(),
    exactnl_contact_id: z.string().max(100).optional(),
    snelstart_contact_id: z.string().max(150).optional(),
    is_semen_collection_station: z.boolean().optional(),
    is_insemination_station: z.boolean().optional(),
    use_for_picking: z.boolean().optional(),
    vat_number: z.string().max(19).optional(),
    vat_number_checked: z.union([z.boolean(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255).optional(),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    country: CountryEnum.optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64).optional(),
    city: z.string().max(255).optional(),
    title: TitleEnum.optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    show_in_daily: z.boolean().optional(),
    show_in_work_schedule: z.boolean().optional(),
    note: z.string().optional(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30).optional(),
    invoice_language: InvoiceLanguageEnum.optional(),
    business_type: BusinessTypeEnum.optional(),
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().optional().default(false),
    parent_stable: z.union([z.string(), z.null()]).optional(),
    IBAN: z.string().max(34).optional(),
    bank_code: z.string().max(255).optional(),
    account_number: z.string().max(60).optional(),
    bank_name: z.string().max(255).optional(),
    second_phone_number: z.string().max(128).optional(),
    company_registration_number: z.string().max(20).optional(),
  })
  .passthrough();
const PaginatedContactList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Contact) })
  .passthrough();
const DefaultEnum = z.enum([
  'RIDING',
  'PADDOCK',
  'LUNGING',
  'FARRIER',
  'VACCINATION',
  'VETERINARY',
  'SHOW',
  'CUSTOMER',
  'DEWORM',
  'DENTIST',
  'LESSON',
  'INSEMINATION',
  'FOALING',
  'MARE_CYCLE_CHECK',
  'RHINO',
  'STALLION_MOUNT',
]);
const ActivityTypeCategoryEnum = z.enum(['SPORT', 'BREEDING', 'CARE']);
const ExtraInfoFieldsEnum = z.enum([
  'level',
  'extra_info',
  'intensity',
  'estimated_intensity',
  'show',
  'sire',
  'external_url',
  'heart_rate',
  'race_time',
  'show_status',
  'workload',
  'pregnancy_check_term',
]);
const ActivityType = z
  .object({
    uid: z.string(),
    name: z.string().max(45).optional(),
    order: z.union([z.number(), z.null()]).optional(),
    default: DefaultEnum.optional(),
    hide_order: z.boolean().optional(),
    hidden: z.boolean().optional(),
    enable_quick_add: z.boolean().optional(),
    stable_settings_activity_types_general: z.array(z.string()).optional(),
    reminder_enabled_by_default: z.boolean().optional(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    category: ActivityTypeCategoryEnum.optional(),
    notify_before: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    facility_uids: z.array(z.string()).optional(),
    extra_info_fields: z.array(ExtraInfoFieldsEnum).optional(),
    possible_roles: z.array(z.union([z.string(), z.null()])).optional(),
  })
  .passthrough();
const PatchedActivityType = z
  .object({
    uid: z.string(),
    name: z.string().max(45),
    order: z.union([z.number(), z.null()]),
    default: DefaultEnum,
    hide_order: z.boolean(),
    hidden: z.boolean(),
    enable_quick_add: z.boolean(),
    stable_settings_activity_types_general: z.array(z.string()),
    reminder_enabled_by_default: z.boolean(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    category: ActivityTypeCategoryEnum,
    notify_before: z.number().int().gte(-2147483648).lte(2147483647),
    facility_uids: z.array(z.string()),
    extra_info_fields: z.array(ExtraInfoFieldsEnum),
    possible_roles: z.array(z.union([z.string(), z.null()])),
  })
  .passthrough();
const AbscenceTypeEnum = z.enum(['available', 'sick', 'leave']);
const AvailabilityStatusEnum = z.enum(['confirmed', 'tentative', 'cancelled', 'rejected']);
const Availability = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    employment: z.string(),
    stable: z.union([z.string(), z.null()]).optional(),
    contact: z.string(),
    abscence_type: AbscenceTypeEnum.optional(),
    description: z.union([z.string(), z.null()]).optional(),
    has_leave_allowance_impact: z.boolean().optional(),
    status: AvailabilityStatusEnum.optional().default('tentative'),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    recurrence: z.string().optional(),
  })
  .passthrough();
const PatchedAvailability = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    employment: z.string(),
    stable: z.union([z.string(), z.null()]),
    contact: z.string(),
    abscence_type: AbscenceTypeEnum,
    description: z.union([z.string(), z.null()]),
    has_leave_allowance_impact: z.boolean(),
    status: AvailabilityStatusEnum.default('tentative'),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    recurrence: z.string(),
  })
  .passthrough();
const PatchedAvailabilityApproveDecline = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    employment: z.string(),
    stable: z.union([z.string(), z.null()]),
    contact: z.string(),
    abscence_type: AbscenceTypeEnum,
    description: z.union([z.string(), z.null()]),
    has_leave_allowance_impact: z.boolean(),
    status: AvailabilityStatusEnum.default('tentative'),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    recurrence: z.string(),
  })
  .passthrough();
const AvailabilityApproveDecline = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    employment: z.string(),
    stable: z.union([z.string(), z.null()]),
    contact: z.string(),
    abscence_type: AbscenceTypeEnum,
    description: z.union([z.string(), z.null()]),
    has_leave_allowance_impact: z.boolean(),
    status: AvailabilityStatusEnum.optional().default('tentative'),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    recurrence: z.string().optional(),
  })
  .passthrough();
const PSTypeEnum = z.enum(['PURCHASE', 'SALE']);
const VatLevelCountryVatLevelEnum = z.enum(['REDUCED', 'SECOND_REDUCED', 'SUPER_REDUCED', 'ZERO']);
const VatLevelCountry = z.object({ vat_level: VatLevelCountryVatLevelEnum, country: CountryEnum }).passthrough();
const Category = z
  .object({
    uid: z.string(),
    p_s_type: PSTypeEnum.optional(),
    name: z.string().max(255),
    is_subscription_product: z.boolean().optional(),
    exactnl_ledger_code: z.string().max(90).optional(),
    moneybird_ledger_code: z.string().max(90).optional(),
    yuki_ledger_code: z.string().max(90).optional(),
    default: z.union([z.string(), z.null()]),
    vatLevelCountry: z.array(VatLevelCountry),
  })
  .passthrough();
const PatchedCategory = z
  .object({
    uid: z.string(),
    p_s_type: PSTypeEnum,
    name: z.string().max(255),
    is_subscription_product: z.boolean(),
    exactnl_ledger_code: z.string().max(90),
    moneybird_ledger_code: z.string().max(90),
    yuki_ledger_code: z.string().max(90),
    default: z.union([z.string(), z.null()]),
    vatLevelCountry: z.array(VatLevelCountry),
  })
  .passthrough();
const ContactDetail = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])).optional(),
    initials: z.string().max(3).optional(),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])).optional(),
    first_name: z.string().max(255).optional(),
    last_name: z.string().max(255).optional(),
    studbook_preference: StudbookEnum.optional(),
    business_name: z.string().max(255).optional(),
    phone_number: z.string().max(128).optional(),
    email: z.string().max(254).email().optional(),
    external_location: z.boolean().optional(),
    customer_id: z.string().max(7).optional(),
    moneybird_contact_id: z.union([z.number(), z.null()]).optional(),
    exactnl_contact_id: z.string().max(100).optional(),
    snelstart_contact_id: z.string().max(150).optional(),
    is_semen_collection_station: z.boolean().optional(),
    is_insemination_station: z.boolean().optional(),
    use_for_picking: z.boolean().optional(),
    vat_number: z.string().max(19).optional(),
    vat_number_checked: z.union([z.boolean(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255).optional(),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    country: CountryEnum.optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64).optional(),
    city: z.string().max(255).optional(),
    title: TitleEnum.optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    show_in_daily: z.boolean().optional(),
    show_in_work_schedule: z.boolean().optional(),
    note: z.string().optional(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30).optional(),
    invoice_language: InvoiceLanguageEnum.optional(),
    business_type: BusinessTypeEnum.optional(),
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().optional().default(false),
    parent_stable: z.union([z.string(), z.null()]).optional(),
    IBAN: z.string().max(34).optional(),
    bank_code: z.string().max(255).optional(),
    account_number: z.string().max(60).optional(),
    bank_name: z.string().max(255).optional(),
    second_phone_number: z.string().max(128).optional(),
    company_registration_number: z.string().max(20).optional(),
    duplicates: z.array(z.string()),
  })
  .passthrough();
const PatchedContactDetail = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])),
    initials: z.string().max(3),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])),
    first_name: z.string().max(255),
    last_name: z.string().max(255),
    studbook_preference: StudbookEnum,
    business_name: z.string().max(255),
    phone_number: z.string().max(128),
    email: z.string().max(254).email(),
    external_location: z.boolean(),
    customer_id: z.string().max(7),
    moneybird_contact_id: z.union([z.number(), z.null()]),
    exactnl_contact_id: z.string().max(100),
    snelstart_contact_id: z.string().max(150),
    is_semen_collection_station: z.boolean(),
    is_insemination_station: z.boolean(),
    use_for_picking: z.boolean(),
    vat_number: z.string().max(19),
    vat_number_checked: z.union([z.boolean(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255),
    address_line2: z.string().max(255),
    address_line3: z.string().max(255),
    country: CountryEnum,
    state: z.string().max(255),
    postcode: z.string().max(64),
    city: z.string().max(255),
    title: TitleEnum,
    date_of_birth: z.union([z.string(), z.null()]),
    show_in_daily: z.boolean(),
    show_in_work_schedule: z.boolean(),
    note: z.string(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30),
    invoice_language: InvoiceLanguageEnum,
    business_type: BusinessTypeEnum,
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().default(false),
    parent_stable: z.union([z.string(), z.null()]),
    IBAN: z.string().max(34),
    bank_code: z.string().max(255),
    account_number: z.string().max(60),
    bank_name: z.string().max(255),
    second_phone_number: z.string().max(128),
    company_registration_number: z.string().max(20),
    duplicates: z.array(z.string()),
  })
  .passthrough();
const PatchedMergeContacts = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])),
    initials: z.string().max(3),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])),
    first_name: z.string().max(255),
    last_name: z.string().max(255),
    studbook_preference: StudbookEnum,
    business_name: z.string().max(255),
    phone_number: z.string().max(128),
    email: z.string().max(254).email(),
    external_location: z.boolean(),
    customer_id: z.string().max(7),
    moneybird_contact_id: z.union([z.number(), z.null()]),
    exactnl_contact_id: z.string().max(100),
    snelstart_contact_id: z.string().max(150),
    is_semen_collection_station: z.boolean(),
    is_insemination_station: z.boolean(),
    use_for_picking: z.boolean(),
    vat_number: z.string().max(19),
    vat_number_checked: z.union([z.boolean(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255),
    address_line2: z.string().max(255),
    address_line3: z.string().max(255),
    country: CountryEnum,
    state: z.string().max(255),
    postcode: z.string().max(64),
    city: z.string().max(255),
    title: TitleEnum,
    date_of_birth: z.union([z.string(), z.null()]),
    show_in_daily: z.boolean(),
    show_in_work_schedule: z.boolean(),
    note: z.string(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30),
    invoice_language: InvoiceLanguageEnum,
    business_type: BusinessTypeEnum,
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().default(false),
    parent_stable: z.union([z.string(), z.null()]),
    IBAN: z.string().max(34),
    bank_code: z.string().max(255),
    account_number: z.string().max(60),
    bank_name: z.string().max(255),
    second_phone_number: z.string().max(128),
    company_registration_number: z.string().max(20),
    merge_with_uid: z.string(),
  })
  .passthrough();
const MergeContacts = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])).optional(),
    initials: z.string().max(3).optional(),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])).optional(),
    first_name: z.string().max(255).optional(),
    last_name: z.string().max(255).optional(),
    studbook_preference: StudbookEnum.optional(),
    business_name: z.string().max(255).optional(),
    phone_number: z.string().max(128).optional(),
    email: z.string().max(254).email().optional(),
    external_location: z.boolean().optional(),
    customer_id: z.string().max(7).optional(),
    moneybird_contact_id: z.union([z.number(), z.null()]).optional(),
    exactnl_contact_id: z.string().max(100).optional(),
    snelstart_contact_id: z.string().max(150).optional(),
    is_semen_collection_station: z.boolean().optional(),
    is_insemination_station: z.boolean().optional(),
    use_for_picking: z.boolean().optional(),
    vat_number: z.string().max(19).optional(),
    vat_number_checked: z.union([z.boolean(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255).optional(),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    country: CountryEnum.optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64).optional(),
    city: z.string().max(255).optional(),
    title: TitleEnum.optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    show_in_daily: z.boolean().optional(),
    show_in_work_schedule: z.boolean().optional(),
    note: z.string().optional(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30).optional(),
    invoice_language: InvoiceLanguageEnum.optional(),
    business_type: BusinessTypeEnum.optional(),
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().optional().default(false),
    parent_stable: z.union([z.string(), z.null()]).optional(),
    IBAN: z.string().max(34).optional(),
    bank_code: z.string().max(255).optional(),
    account_number: z.string().max(60).optional(),
    bank_name: z.string().max(255).optional(),
    second_phone_number: z.string().max(128).optional(),
    company_registration_number: z.string().max(20).optional(),
    merge_with_uid: z.string(),
  })
  .passthrough();
const PaginatedContactDetailList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(ContactDetail),
  })
  .passthrough();
const DailyNote = z
  .object({
    uid: z.string(),
    title: z.string().max(80).optional(),
    text: z.string().max(340),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    executable: z.boolean().optional(),
    done_on: z.union([z.string(), z.null()]).optional(),
    done_by_uid: z.union([z.string(), z.null()]).optional(),
    color: z.union([z.string(), z.null()]).optional(),
    order: z.union([z.number(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    status: EventStatus.optional().default('confirmed'),
    recurrence: z.string().optional(),
  })
  .passthrough();
const PatchedDailyNote = z
  .object({
    uid: z.string(),
    title: z.string().max(80),
    text: z.string().max(340),
    stable_uid: z.union([z.string(), z.null()]),
    executable: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    done_by_uid: z.union([z.string(), z.null()]),
    color: z.union([z.string(), z.null()]),
    order: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    status: EventStatus.default('confirmed'),
    recurrence: z.string(),
  })
  .passthrough();
const PatchedDailyNoteSetDoneAndUnDone = z
  .object({
    uid: z.string(),
    title: z.string(),
    text: z.string(),
    stable_uid: z.union([z.string(), z.null()]),
    executable: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    done_by_uid: z.union([z.string(), z.null()]),
    color: z.union([z.string(), z.null()]),
    order: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    status: EventStatus.default('confirmed'),
    recurrence: z.string(),
    events_to_change: EventsToChangeEnum.default('ONE'),
  })
  .passthrough();
const DailyNoteSetDoneAndUnDone = z
  .object({
    uid: z.string(),
    title: z.string(),
    text: z.string(),
    stable_uid: z.union([z.string(), z.null()]),
    executable: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    done_by_uid: z.union([z.string(), z.null()]),
    color: z.union([z.string(), z.null()]),
    order: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    status: EventStatus.optional().default('confirmed'),
    recurrence: z.string().optional(),
    events_to_change: EventsToChangeEnum.optional().default('ONE'),
  })
  .passthrough();
const DayPartStartTime = z.object({ uid: z.string(), start_time: z.string(), name: z.string().max(255).optional() }).passthrough();
const PatchedDayPartStartTime = z.object({ uid: z.string(), start_time: z.string(), name: z.string().max(255) }).passthrough();
const Employment = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    contact: z.string(),
    dstart: z.string().optional(),
    dend: z.union([z.string(), z.null()]).optional(),
    work_week_hours: z.union([z.string(), z.null()]).optional(),
    work_schedule: z.union([z.unknown(), z.null()]).optional(),
    leave_days_per_year: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    leave_allowance: z.object({}).passthrough(),
  })
  .passthrough();
const PatchedEmployment = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    contact: z.string(),
    dstart: z.string(),
    dend: z.union([z.string(), z.null()]),
    work_week_hours: z.union([z.string(), z.null()]),
    work_schedule: z.union([z.unknown(), z.null()]),
    leave_days_per_year: z.number().int().gte(-2147483648).lte(2147483647),
    leave_allowance: z.object({}).passthrough(),
  })
  .passthrough();
const PossibleCalendarHashObjects = z.object({ activity_type_uids: z.string(), horse_uids: z.string() }).passthrough();
const EventFeeds = z
  .object({
    uid: z.string(),
    stable: z.union([z.string(), z.null()]),
    user: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    possible_calendar_hash_objs: PossibleCalendarHashObjects.optional(),
    created_on: z.string().datetime({ offset: true }),
    url: z.string(),
  })
  .passthrough();
const PatchedEventFeeds = z
  .object({
    uid: z.string(),
    stable: z.union([z.string(), z.null()]),
    user: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    possible_calendar_hash_objs: PossibleCalendarHashObjects,
    created_on: z.string().datetime({ offset: true }),
    url: z.string(),
  })
  .passthrough();
const FacilityTypeEnum = z.enum([
  'TRANSPORT',
  'WATER_TREADMILL',
  'INDOOR_ARENA',
  'OUTDOOR_ARENA',
  'GALLOPING_TRACK',
  'LUNGING_RING',
  'PADDOCK',
  'SOLARIUM',
  'JUMPING_YARD',
  'TREADMILL',
  'VIBRATION_PLATE',
  'PASTURES',
  'WALKER',
  'OTHER',
]);
const AvailableWeekdaysEnum = z.enum(['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']);
const Facility = z
  .object({
    uid: z.string(),
    name: z.string().max(255),
    description: z.string().optional(),
    facility_type: FacilityTypeEnum,
    planning_window_opens_days_ahead: z.union([z.number(), z.null()]).optional(),
    planning_window_closes_hours_before: z.union([z.string(), z.null()]).optional(),
    slot_capacity: z.union([z.number(), z.null()]).optional(),
    max_hours_per_event: z.union([z.string(), z.null()]).optional(),
    min_hours_per_event: z.union([z.string(), z.null()]).optional(),
    available_weekdays: z.array(AvailableWeekdaysEnum),
    available_from: z.union([z.string(), z.null()]).optional(),
    available_to: z.union([z.string(), z.null()]).optional(),
    available: z.boolean().optional(),
    hidden: z.boolean().optional(),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by: z.union([z.string(), z.null()]),
    last_modified_by: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PaginatedFacilityList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Facility) })
  .passthrough();
const PatchedFacility = z
  .object({
    uid: z.string(),
    name: z.string().max(255),
    description: z.string(),
    facility_type: FacilityTypeEnum,
    planning_window_opens_days_ahead: z.union([z.number(), z.null()]),
    planning_window_closes_hours_before: z.union([z.string(), z.null()]),
    slot_capacity: z.union([z.number(), z.null()]),
    max_hours_per_event: z.union([z.string(), z.null()]),
    min_hours_per_event: z.union([z.string(), z.null()]),
    available_weekdays: z.array(AvailableWeekdaysEnum),
    available_from: z.union([z.string(), z.null()]),
    available_to: z.union([z.string(), z.null()]),
    available: z.boolean(),
    hidden: z.boolean(),
    stable_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by: z.union([z.string(), z.null()]),
    last_modified_by: z.union([z.string(), z.null()]),
  })
  .passthrough();
const FacilityEvent = z
  .object({
    uid: z.string(),
    description: z.string().optional(),
    facility_uid: z.string(),
    activity_uid: z.union([z.string(), z.null()]).optional(),
    horse_uids: z.array(z.string()).optional(),
    slots: z.number().int(),
    contact_uid: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    is_private_reservation: z.boolean(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    status: EventStatus.optional().default('confirmed'),
    recurrence: z.string().optional(),
  })
  .passthrough();
const PaginatedFacilityEventList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(FacilityEvent),
  })
  .passthrough();
const FacilityEventUpdate = z
  .object({
    uid: z.string(),
    description: z.string().optional(),
    facility_uid: z.string(),
    activity_uid: z.union([z.string(), z.null()]).optional(),
    horse_uids: z.array(z.string()).optional(),
    slots: z.number().int(),
    contact_uid: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    is_private_reservation: z.boolean(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    status: EventStatus.optional().default('confirmed'),
    recurrence: z.string().optional(),
    events_to_change: EventsToChangeEnum.optional().default('ONE'),
  })
  .passthrough();
const PatchedFacilityEventUpdate = z
  .object({
    uid: z.string(),
    description: z.string(),
    facility_uid: z.string(),
    activity_uid: z.union([z.string(), z.null()]),
    horse_uids: z.array(z.string()),
    slots: z.number().int(),
    contact_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    is_private_reservation: z.boolean(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    status: EventStatus.default('confirmed'),
    recurrence: z.string(),
    events_to_change: EventsToChangeEnum.default('ONE'),
  })
  .passthrough();
const MyFacilityEvent = z
  .object({
    uid: z.string(),
    description: z.string().optional(),
    facility_uid: z.string(),
    activity_uid: z.union([z.string(), z.null()]).optional(),
    horse_uids: z.array(z.string()).optional(),
    slots: z.number().int(),
    contact_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    is_private_reservation: z.boolean(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    status: EventStatus.optional().default('confirmed'),
    recurrence: z.string().optional(),
  })
  .passthrough();
const MyFacilityEventUpdate = z
  .object({
    uid: z.string(),
    description: z.string().optional(),
    facility_uid: z.string(),
    activity_uid: z.union([z.string(), z.null()]).optional(),
    horse_uids: z.array(z.string()).optional(),
    slots: z.number().int(),
    contact_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    is_private_reservation: z.boolean(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    status: EventStatus.optional().default('confirmed'),
    recurrence: z.string().optional(),
    events_to_change: EventsToChangeEnum.optional().default('ONE'),
  })
  .passthrough();
const PatchedMyFacilityEventUpdate = z
  .object({
    uid: z.string(),
    description: z.string(),
    facility_uid: z.string(),
    activity_uid: z.union([z.string(), z.null()]),
    horse_uids: z.array(z.string()),
    slots: z.number().int(),
    contact_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    is_private_reservation: z.boolean(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    status: EventStatus.default('confirmed'),
    recurrence: z.string(),
    events_to_change: EventsToChangeEnum.default('ONE'),
  })
  .passthrough();
const Feeding = z
  .object({ uid: z.string(), name: z.string().max(255).optional(), time: z.string(), default_id: z.union([z.number(), z.null()]) })
  .passthrough();
const PatchedFeeding = z
  .object({ uid: z.string(), name: z.string().max(255), time: z.string(), default_id: z.union([z.number(), z.null()]) })
  .passthrough();
const QuantityMeasureEnum = z.enum([
  'ml',
  'l',
  'g',
  'kg',
  'lb',
  'oz',
  'imperial_oz',
  'imperial_pint',
  'imperial_qt',
  'imperial_g',
  'imperial_tsp',
  'us_oz',
  'us_pint',
  'us_qt',
  'us_g',
  'us_tsp',
  'custom',
]);
const FeedTypeCategoryEnum = z.enum(['ROUGHAGE', 'CONCENTRATE', 'SUPPLEMENT', 'MEDICINE']);
const FeedType = z
  .object({
    uid: z.string(),
    display_name: z.string().max(45),
    brand: z.string().max(100),
    warning: z.string().max(255).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    quantity_measure: QuantityMeasureEnum,
    default_quantity: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    harvest_date: z.union([z.string(), z.null()]).optional(),
    category: FeedTypeCategoryEnum,
    hidden: z.boolean().optional(),
    wait_time: z.union([z.number(), z.null()]).optional(),
  })
  .passthrough();
const PatchedFeedType = z
  .object({
    uid: z.string(),
    display_name: z.string().max(45),
    brand: z.string().max(100),
    warning: z.string().max(255),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    quantity_measure: QuantityMeasureEnum,
    default_quantity: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    harvest_date: z.union([z.string(), z.null()]),
    category: FeedTypeCategoryEnum,
    hidden: z.boolean(),
    wait_time: z.union([z.number(), z.null()]),
  })
  .passthrough();
const HolidayEvent = z
  .object({
    name: z.string().max(120).optional(),
    country: CountryEnum,
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    all_day_event: z.boolean().optional().default(false),
    endTimeUnspecified: z.boolean().optional().default(false),
    status: EventStatus.optional().default('confirmed'),
    recurrence: z.string().optional(),
  })
  .passthrough();
const PatchedHolidayEvent = z
  .object({
    name: z.string().max(120),
    country: CountryEnum,
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
    endTimeUnspecified: z.boolean().default(false),
    status: EventStatus.default('confirmed'),
    recurrence: z.string(),
  })
  .passthrough();
const CountryYear = z.object({ country: CountryEnum, year: z.number().int() }).passthrough();
const Feed = z
  .object({
    uid: z.string(),
    feed_type_uid: z.string(),
    quantity: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    start_reason: z.string().max(255).optional(),
    end_reason: z.string().max(255).optional(),
    stable_uid: z.string(),
    horse_uid: z.string(),
    feeding_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    endTimeUnspecified: z.boolean().optional().default(false),
    status: EventStatus.optional().default('confirmed'),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
  })
  .passthrough();
const PaginatedFeedList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Feed) })
  .passthrough();
const FeedUpdate = z
  .object({
    uid: z.string(),
    feed_type_uid: z.string(),
    quantity: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    start_reason: z.string().max(255).optional(),
    end_reason: z.string().max(255).optional(),
    stable_uid: z.string(),
    horse_uid: z.string(),
    feeding_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    endTimeUnspecified: z.boolean().optional().default(false),
    status: EventStatus.optional().default('confirmed'),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField.optional(),
    events_to_change: EventsToChangeEnum.optional().default('ONE'),
  })
  .passthrough();
const PatchedFeedUpdate = z
  .object({
    uid: z.string(),
    feed_type_uid: z.string(),
    quantity: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    start_reason: z.string().max(255),
    end_reason: z.string().max(255),
    stable_uid: z.string(),
    horse_uid: z.string(),
    feeding_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    endTimeUnspecified: z.boolean().default(false),
    status: EventStatus.default('confirmed'),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    events_to_change: EventsToChangeEnum.default('ONE'),
  })
  .passthrough();
const HorseGroup = z
  .object({ uid: z.string(), name: z.string().max(45).optional(), default_id: z.union([z.number(), z.null()]) })
  .passthrough();
const PatchedHorseGroup = z
  .object({ uid: z.string(), name: z.string().max(45), default_id: z.union([z.number(), z.null()]) })
  .passthrough();
const Location = z
  .object({ uid: z.union([z.string(), z.null()]), name: z.string(), arrival_date: z.union([z.string(), z.null()]) })
  .passthrough();
const Horse = z
  .object({
    uid: z.string(),
    display_name: z.union([z.string(), z.null()]).optional(),
    avatar_file: z.union([z.string(), z.null()]).optional(),
    box_no: z.union([z.number(), z.null()]).optional(),
    current_employability_status: z.string(),
    current_phase_status: z.string(),
    group_uid: z.union([z.string(), z.null()]).optional(),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    hidden: z.boolean(),
    name: z.string().max(45),
    nickname: z.string().max(45).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    color: ColorEnum.optional(),
    sex: SexEnum.optional(),
    sire: z.string().max(65).optional(),
    dam: z.string().max(65).optional(),
    damsire: z.string().max(45).optional(),
    siredam: z.string().max(45).optional(),
    sire_obj_uid: z.union([z.string(), z.null()]).optional(),
    dam_obj_uid: z.union([z.string(), z.null()]).optional(),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/)
      .optional(),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/)
      .optional(),
    current_location: Location,
    future_location: Location,
    purchaser_horses: z.array(z.string()),
    use_in_breeding: z.boolean().optional(),
    use_in_sport: z.boolean().optional(),
    use_in_care: z.boolean().optional(),
    owner_uids: z.array(z.string()),
    default_rider_uid: z.union([z.string(), z.null()]).optional(),
    default_groom_uid: z.union([z.string(), z.null()]).optional(),
    default_farrier_uid: z.union([z.string(), z.null()]).optional(),
    default_vet_uid: z.union([z.string(), z.null()]).optional(),
    default_trainer_uid: z.union([z.string(), z.null()]).optional(),
    dentist_weeks: z.union([z.number(), z.null()]).optional(),
    deworm_weeks: z.union([z.number(), z.null()]).optional(),
    rhino_weeks: z.union([z.number(), z.null()]).optional(),
    vaccination_rules_uid: z.string().optional(),
    farrier_weeks: z.union([z.number(), z.null()]).optional(),
    default_semen_collection_station: z.union([z.string(), z.null()]).optional(),
    warning: z.string().optional(),
  })
  .passthrough();
const PaginatedHorseList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Horse) })
  .passthrough();
const NestedHorseLocation = z
  .object({
    uid: z.string(),
    location_uid: z.string(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    arrival_date: z.string(),
    arrival_is_import: z.boolean().optional(),
    departure_reason: DepartureReasonEnum.optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    departure_is_export: z.boolean().optional(),
    departure_is_dead: z.boolean().optional(),
    departure_date: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const HorseLocationMove = z
  .object({
    departure_reason: DepartureReasonEnum.optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    departure_is_export: z.boolean().optional(),
    departure_is_dead: z.boolean().optional(),
    arrival_is_import: z.boolean().optional(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    date: z.string(),
    set_inactive: z.boolean().optional(),
    set_active: z.boolean().optional(),
    stop_services: z.boolean().optional(),
    restart_services: z.boolean().optional(),
  })
  .passthrough();
const HorseDetail = z
  .object({
    uid: z.string(),
    owner_uids: z.array(z.string()),
    display_name: z.union([z.string(), z.null()]).optional(),
    group_uid: z.union([z.string(), z.null()]).optional(),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    hidden: z.boolean(),
    dentist_weeks: z.union([z.number(), z.null()]).optional(),
    deworm_weeks: z.union([z.number(), z.null()]).optional(),
    rhino_weeks: z.union([z.number(), z.null()]).optional(),
    vaccination_rules_uid: z.string().optional(),
    farrier_weeks: z.union([z.number(), z.null()]).optional(),
    name: z.string().max(45),
    nickname: z.string().max(45).optional(),
    box_no: z.union([z.number(), z.null()]).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    color: ColorEnum.optional(),
    sex: SexEnum.optional(),
    duplicates: z.array(z.string()),
    weight: z.union([z.string(), z.null()]).optional(),
    temperature: z.union([z.string(), z.null()]).optional(),
    sire_obj_uid: z.union([z.string(), z.null()]).optional(),
    dam_obj_uid: z.union([z.string(), z.null()]).optional(),
    damdam_obj_uid: z.union([z.string(), z.null()]).optional(),
    siresire_obj_uid: z.union([z.string(), z.null()]).optional(),
    damsire_obj_uid: z.union([z.string(), z.null()]).optional(),
    siredam_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregrandsire1_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregrandsire2_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregranddam1_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregranddam2_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgranddam1_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgranddam2_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgrandsire1_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgrandsire2_obj_uid: z.union([z.string(), z.null()]).optional(),
    sire: z.string().max(65).optional(),
    dam: z.string().max(65).optional(),
    damsire: z.string().max(45).optional(),
    breeder: z.string().max(45).optional(),
    studbook: z.string().max(190).optional(),
    note: z.string().optional(),
    warning: z.string().optional(),
    damdam: z.string().max(45).optional(),
    siresire: z.string().max(45).optional(),
    siredam: z.string().max(45).optional(),
    siregrandsire1: z.string().max(45).optional(),
    siregrandsire2: z.string().max(45).optional(),
    siregranddam1: z.string().max(45).optional(),
    siregranddam2: z.string().max(45).optional(),
    damgranddam1: z.string().max(45).optional(),
    damgranddam2: z.string().max(45).optional(),
    damgrandsire1: z.string().max(45).optional(),
    damgrandsire2: z.string().max(45).optional(),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/)
      .optional(),
    FEI_pass_nr: z.string().max(20).optional(),
    local_federation_number: z.string().max(30).optional(),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/)
      .optional(),
    FEI_expiry_date: z.union([z.string(), z.null()]).optional(),
    default_rider_uid: z.union([z.string(), z.null()]).optional(),
    default_groom_uid: z.union([z.string(), z.null()]).optional(),
    default_farrier_uid: z.union([z.string(), z.null()]).optional(),
    default_vet_uid: z.union([z.string(), z.null()]).optional(),
    default_trainer_uid: z.union([z.string(), z.null()]).optional(),
    transport_instruction: z.union([z.string(), z.null()]).optional(),
    id_horsetelex: z.string().max(45).optional(),
    purchase_date: z.union([z.string(), z.null()]).optional(),
    withers_height: z.union([z.number(), z.null()]).optional(),
    heart_rate: z.union([z.number(), z.null()]).optional(),
    respiratory_rate: z.union([z.number(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]).optional(),
    current_horselocation: NestedHorseLocation,
    future_horselocation: NestedHorseLocation,
    register_move: HorseLocationMove.optional(),
    new_owners: z.array(z.string()).optional(),
    use_in_breeding: z.boolean().optional(),
    use_in_sport: z.boolean().optional(),
    use_in_care: z.boolean().optional(),
    default_semen_collection_station: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const PatchedHorseDetail = z
  .object({
    uid: z.string(),
    owner_uids: z.array(z.string()),
    display_name: z.union([z.string(), z.null()]),
    group_uid: z.union([z.string(), z.null()]),
    stable_uid: z.union([z.string(), z.null()]),
    hidden: z.boolean(),
    dentist_weeks: z.union([z.number(), z.null()]),
    deworm_weeks: z.union([z.number(), z.null()]),
    rhino_weeks: z.union([z.number(), z.null()]),
    vaccination_rules_uid: z.string(),
    farrier_weeks: z.union([z.number(), z.null()]),
    name: z.string().max(45),
    nickname: z.string().max(45),
    box_no: z.union([z.number(), z.null()]),
    date_of_birth: z.union([z.string(), z.null()]),
    color: ColorEnum,
    sex: SexEnum,
    duplicates: z.array(z.string()),
    weight: z.union([z.string(), z.null()]),
    temperature: z.union([z.string(), z.null()]),
    sire_obj_uid: z.union([z.string(), z.null()]),
    dam_obj_uid: z.union([z.string(), z.null()]),
    damdam_obj_uid: z.union([z.string(), z.null()]),
    siresire_obj_uid: z.union([z.string(), z.null()]),
    damsire_obj_uid: z.union([z.string(), z.null()]),
    siredam_obj_uid: z.union([z.string(), z.null()]),
    siregrandsire1_obj_uid: z.union([z.string(), z.null()]),
    siregrandsire2_obj_uid: z.union([z.string(), z.null()]),
    siregranddam1_obj_uid: z.union([z.string(), z.null()]),
    siregranddam2_obj_uid: z.union([z.string(), z.null()]),
    damgranddam1_obj_uid: z.union([z.string(), z.null()]),
    damgranddam2_obj_uid: z.union([z.string(), z.null()]),
    damgrandsire1_obj_uid: z.union([z.string(), z.null()]),
    damgrandsire2_obj_uid: z.union([z.string(), z.null()]),
    sire: z.string().max(65),
    dam: z.string().max(65),
    damsire: z.string().max(45),
    breeder: z.string().max(45),
    studbook: z.string().max(190),
    note: z.string(),
    warning: z.string(),
    damdam: z.string().max(45),
    siresire: z.string().max(45),
    siredam: z.string().max(45),
    siregrandsire1: z.string().max(45),
    siregrandsire2: z.string().max(45),
    siregranddam1: z.string().max(45),
    siregranddam2: z.string().max(45),
    damgranddam1: z.string().max(45),
    damgranddam2: z.string().max(45),
    damgrandsire1: z.string().max(45),
    damgrandsire2: z.string().max(45),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/),
    FEI_pass_nr: z.string().max(20),
    local_federation_number: z.string().max(30),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/),
    FEI_expiry_date: z.union([z.string(), z.null()]),
    default_rider_uid: z.union([z.string(), z.null()]),
    default_groom_uid: z.union([z.string(), z.null()]),
    default_farrier_uid: z.union([z.string(), z.null()]),
    default_vet_uid: z.union([z.string(), z.null()]),
    default_trainer_uid: z.union([z.string(), z.null()]),
    transport_instruction: z.union([z.string(), z.null()]),
    id_horsetelex: z.string().max(45),
    purchase_date: z.union([z.string(), z.null()]),
    withers_height: z.union([z.number(), z.null()]),
    heart_rate: z.union([z.number(), z.null()]),
    respiratory_rate: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]),
    current_horselocation: NestedHorseLocation,
    future_horselocation: NestedHorseLocation,
    register_move: HorseLocationMove,
    new_owners: z.array(z.string()),
    use_in_breeding: z.boolean(),
    use_in_sport: z.boolean(),
    use_in_care: z.boolean(),
    default_semen_collection_station: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PatchedMergeHorses = z
  .object({
    uid: z.string(),
    owner_uids: z.array(z.string()),
    display_name: z.string().max(190),
    group_uid: z.union([z.string(), z.null()]),
    stable_uid: z.union([z.string(), z.null()]),
    hidden: z.boolean(),
    dentist_weeks: z.union([z.number(), z.null()]),
    deworm_weeks: z.union([z.number(), z.null()]),
    rhino_weeks: z.union([z.number(), z.null()]),
    vaccination_rules_uid: z.string(),
    farrier_weeks: z.union([z.number(), z.null()]),
    name: z.string().max(45),
    nickname: z.string().max(45),
    box_no: z.union([z.number(), z.null()]),
    date_of_birth: z.union([z.string(), z.null()]),
    color: ColorEnum,
    sex: SexEnum,
    duplicates: z.array(z.string()),
    weight: z.union([z.string(), z.null()]),
    temperature: z.union([z.string(), z.null()]),
    sire_obj_uid: z.union([z.string(), z.null()]),
    dam_obj_uid: z.union([z.string(), z.null()]),
    damdam_obj_uid: z.union([z.string(), z.null()]),
    siresire_obj_uid: z.union([z.string(), z.null()]),
    damsire_obj_uid: z.union([z.string(), z.null()]),
    siredam_obj_uid: z.union([z.string(), z.null()]),
    siregrandsire1_obj_uid: z.union([z.string(), z.null()]),
    siregrandsire2_obj_uid: z.union([z.string(), z.null()]),
    siregranddam1_obj_uid: z.union([z.string(), z.null()]),
    siregranddam2_obj_uid: z.union([z.string(), z.null()]),
    damgranddam1_obj_uid: z.union([z.string(), z.null()]),
    damgranddam2_obj_uid: z.union([z.string(), z.null()]),
    damgrandsire1_obj_uid: z.union([z.string(), z.null()]),
    damgrandsire2_obj_uid: z.union([z.string(), z.null()]),
    sire: z.string().max(65),
    dam: z.string().max(65),
    damsire: z.string().max(45),
    breeder: z.string().max(45),
    studbook: z.string().max(190),
    note: z.string(),
    warning: z.string(),
    damdam: z.string().max(45),
    siresire: z.string().max(45),
    siredam: z.string().max(45),
    siregrandsire1: z.string().max(45),
    siregrandsire2: z.string().max(45),
    siregranddam1: z.string().max(45),
    siregranddam2: z.string().max(45),
    damgranddam1: z.string().max(45),
    damgranddam2: z.string().max(45),
    damgrandsire1: z.string().max(45),
    damgrandsire2: z.string().max(45),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/),
    FEI_pass_nr: z.string().max(20),
    local_federation_number: z.string().max(30),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/),
    FEI_expiry_date: z.union([z.string(), z.null()]),
    default_rider_uid: z.union([z.string(), z.null()]),
    default_groom_uid: z.union([z.string(), z.null()]),
    default_farrier_uid: z.union([z.string(), z.null()]),
    default_vet_uid: z.union([z.string(), z.null()]),
    default_trainer_uid: z.union([z.string(), z.null()]),
    transport_instruction: z.union([z.string(), z.null()]),
    id_horsetelex: z.string().max(45),
    purchase_date: z.union([z.string(), z.null()]),
    withers_height: z.union([z.number(), z.null()]),
    heart_rate: z.union([z.number(), z.null()]),
    respiratory_rate: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]),
    current_horselocation: NestedHorseLocation,
    future_horselocation: NestedHorseLocation,
    register_move: HorseLocationMove,
    new_owners: z.array(z.string()),
    use_in_breeding: z.boolean(),
    use_in_sport: z.boolean(),
    use_in_care: z.boolean(),
    default_semen_collection_station: z.union([z.string(), z.null()]),
    merge_with_uid: z.string(),
  })
  .passthrough();
const MergeHorses = z
  .object({
    uid: z.string(),
    owner_uids: z.array(z.string()),
    display_name: z.string().max(190).optional(),
    group_uid: z.union([z.string(), z.null()]).optional(),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    hidden: z.boolean(),
    dentist_weeks: z.union([z.number(), z.null()]).optional(),
    deworm_weeks: z.union([z.number(), z.null()]).optional(),
    rhino_weeks: z.union([z.number(), z.null()]).optional(),
    vaccination_rules_uid: z.string().optional(),
    farrier_weeks: z.union([z.number(), z.null()]).optional(),
    name: z.string().max(45).optional(),
    nickname: z.string().max(45).optional(),
    box_no: z.union([z.number(), z.null()]).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    color: ColorEnum.optional(),
    sex: SexEnum.optional(),
    duplicates: z.array(z.string()),
    weight: z.union([z.string(), z.null()]).optional(),
    temperature: z.union([z.string(), z.null()]).optional(),
    sire_obj_uid: z.union([z.string(), z.null()]).optional(),
    dam_obj_uid: z.union([z.string(), z.null()]).optional(),
    damdam_obj_uid: z.union([z.string(), z.null()]).optional(),
    siresire_obj_uid: z.union([z.string(), z.null()]).optional(),
    damsire_obj_uid: z.union([z.string(), z.null()]).optional(),
    siredam_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregrandsire1_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregrandsire2_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregranddam1_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregranddam2_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgranddam1_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgranddam2_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgrandsire1_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgrandsire2_obj_uid: z.union([z.string(), z.null()]).optional(),
    sire: z.string().max(65).optional(),
    dam: z.string().max(65).optional(),
    damsire: z.string().max(45).optional(),
    breeder: z.string().max(45).optional(),
    studbook: z.string().max(190).optional(),
    note: z.string().optional(),
    warning: z.string().optional(),
    damdam: z.string().max(45).optional(),
    siresire: z.string().max(45).optional(),
    siredam: z.string().max(45).optional(),
    siregrandsire1: z.string().max(45).optional(),
    siregrandsire2: z.string().max(45).optional(),
    siregranddam1: z.string().max(45).optional(),
    siregranddam2: z.string().max(45).optional(),
    damgranddam1: z.string().max(45).optional(),
    damgranddam2: z.string().max(45).optional(),
    damgrandsire1: z.string().max(45).optional(),
    damgrandsire2: z.string().max(45).optional(),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/)
      .optional(),
    FEI_pass_nr: z.string().max(20).optional(),
    local_federation_number: z.string().max(30).optional(),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/)
      .optional(),
    FEI_expiry_date: z.union([z.string(), z.null()]).optional(),
    default_rider_uid: z.union([z.string(), z.null()]).optional(),
    default_groom_uid: z.union([z.string(), z.null()]).optional(),
    default_farrier_uid: z.union([z.string(), z.null()]).optional(),
    default_vet_uid: z.union([z.string(), z.null()]).optional(),
    default_trainer_uid: z.union([z.string(), z.null()]).optional(),
    transport_instruction: z.union([z.string(), z.null()]).optional(),
    id_horsetelex: z.string().max(45).optional(),
    purchase_date: z.union([z.string(), z.null()]).optional(),
    withers_height: z.union([z.number(), z.null()]).optional(),
    heart_rate: z.union([z.number(), z.null()]).optional(),
    respiratory_rate: z.union([z.number(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]).optional(),
    current_horselocation: NestedHorseLocation,
    future_horselocation: NestedHorseLocation,
    register_move: HorseLocationMove.optional(),
    new_owners: z.array(z.string()).optional(),
    use_in_breeding: z.boolean().optional(),
    use_in_sport: z.boolean().optional(),
    use_in_care: z.boolean().optional(),
    default_semen_collection_station: z.union([z.string(), z.null()]).optional(),
    merge_with_uid: z.string(),
  })
  .passthrough();
const HorseMove = z
  .object({
    group_uid: z.union([z.string(), z.null()]).optional(),
    departure_reason: DepartureReasonEnum.optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    departure_is_export: z.boolean().optional(),
    departure_is_dead: z.boolean().optional(),
    arrival_is_import: z.boolean().optional(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    date: z.string(),
    set_inactive: z.boolean().optional(),
    set_active: z.boolean().optional(),
    stop_services: z.boolean().optional(),
    restart_services: z.boolean().optional(),
  })
  .passthrough();
const HorseMoveResponse = z
  .object({
    message: z.string(),
    horse_uid: z.string(),
    task_id: z.string().optional(),
    code: z.string(),
    background_task: z.boolean().optional().default(false),
  })
  .passthrough();
const HorseWithDuplicates = z
  .object({
    uid: z.string(),
    display_name: z.union([z.string(), z.null()]).optional(),
    avatar_file: z.union([z.string(), z.null()]).optional(),
    box_no: z.union([z.number(), z.null()]).optional(),
    current_employability_status: z.string(),
    current_phase_status: z.string(),
    group_uid: z.union([z.string(), z.null()]).optional(),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    hidden: z.boolean(),
    name: z.string().max(45),
    nickname: z.string().max(45).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    color: ColorEnum.optional(),
    sex: SexEnum.optional(),
    sire: z.string().max(65).optional(),
    dam: z.string().max(65).optional(),
    damsire: z.string().max(45).optional(),
    siredam: z.string().max(45).optional(),
    sire_obj_uid: z.union([z.string(), z.null()]).optional(),
    dam_obj_uid: z.union([z.string(), z.null()]).optional(),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/)
      .optional(),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/)
      .optional(),
    current_location: Location,
    future_location: Location,
    purchaser_horses: z.array(z.string()),
    use_in_breeding: z.boolean().optional(),
    use_in_sport: z.boolean().optional(),
    use_in_care: z.boolean().optional(),
    owner_uids: z.array(z.string()),
    default_rider_uid: z.union([z.string(), z.null()]).optional(),
    default_groom_uid: z.union([z.string(), z.null()]).optional(),
    default_farrier_uid: z.union([z.string(), z.null()]).optional(),
    default_vet_uid: z.union([z.string(), z.null()]).optional(),
    default_trainer_uid: z.union([z.string(), z.null()]).optional(),
    dentist_weeks: z.union([z.number(), z.null()]).optional(),
    deworm_weeks: z.union([z.number(), z.null()]).optional(),
    rhino_weeks: z.union([z.number(), z.null()]).optional(),
    vaccination_rules_uid: z.string().optional(),
    farrier_weeks: z.union([z.number(), z.null()]).optional(),
    default_semen_collection_station: z.union([z.string(), z.null()]).optional(),
    warning: z.string().optional(),
    duplicates: z.array(z.string()),
  })
  .passthrough();
const PaginatedHorseWithDuplicatesList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(HorseWithDuplicates),
  })
  .passthrough();
const StateEnum = z.enum(['RECEIVED', 'REVOKED', 'RETRY', 'SUCCESS', 'STARTED', 'PENDING', 'FAILURE', 'PROGRESS']);
const Progress = z
  .object({ percent: z.number(), description: z.string(), current: z.number().int(), total: z.number().int() })
  .passthrough();
const BackGroundTask = z
  .object({ task_id: z.string(), state: StateEnum, result: z.string(), progress: Progress, exception: z.unknown() })
  .passthrough();
const externalDbEnum = z.enum(['RvO', 'HorseTelex']);
const EquineMHorseSearchDetail = z
  .object({
    display_name: z.union([z.string(), z.null()]).optional(),
    name: z.union([z.string(), z.null()]).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    sex: SexEnum.optional(),
    sire: z.union([z.string(), z.null()]).optional(),
    dam: z.union([z.string(), z.null()]).optional(),
    damsire: z.union([z.string(), z.null()]).optional(),
    studbook: z.union([z.string(), z.null()]).optional(),
    UELN: z.union([z.string(), z.null()]).optional(),
    chip_nr: z.union([z.string(), z.null()]).optional(),
    FEI_pass_nr: z.union([z.string(), z.null()]).optional(),
    id_horsetelex: z.union([z.string(), z.null()]).optional(),
    source_db: externalDbEnum,
    current_location_arrival_date: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const GetHorseUnprocessable = z
  .object({
    no_horses_from_rvo: z.number().int(),
    rvo_horses_not_found: z.array(EquineMHorseSearchDetail),
    horses_wo_chipnr: z.array(Horse),
  })
  .passthrough();
const CannotCreateHorse = z.object({ horse: EquineMHorseSearchDetail, soortFoutIndicator: z.string() }).passthrough();
const GetHorsesFromRvo = z
  .object({
    message: z.string(),
    unprocessable_request: GetHorseUnprocessable.optional(),
    not_found_horse_creation_errors: z.union([z.array(CannotCreateHorse), z.null()]).optional(),
  })
  .passthrough();
const LocationResponse = z
  .object({
    location_uid: z.string(),
    background_task: z.union([BackGroundTask, z.null()]).optional(),
    get_horses_from_rvo_exception: z.union([GetHorsesFromRvo, z.null()]).optional(),
  })
  .passthrough();
const HorseTelexDetailInput = z
  .object({
    get_or_create_parent_horses: z.boolean(),
    uid: z.union([z.string(), z.null()]).optional(),
    id_horsetelex: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const HorseDetailHorseTelex = z
  .object({
    display_name: z.union([z.string(), z.null()]).optional(),
    name: z.string().max(45),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    sex: SexEnum.optional(),
    sire: z.string().max(65).optional(),
    dam: z.string().max(65).optional(),
    damsire: z.string().max(45).optional(),
    breeder: z.string().max(45).optional(),
    damdam: z.string().max(45).optional(),
    sire_obj_uid: z.union([z.string(), z.null()]).optional(),
    dam_obj_uid: z.union([z.string(), z.null()]).optional(),
    damdam_obj_uid: z.union([z.string(), z.null()]).optional(),
    siresire_obj_uid: z.union([z.string(), z.null()]).optional(),
    damsire_obj_uid: z.union([z.string(), z.null()]).optional(),
    siredam_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregrandsire1_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregrandsire2_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregranddam1_obj_uid: z.union([z.string(), z.null()]).optional(),
    siregranddam2_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgranddam1_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgranddam2_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgrandsire1_obj_uid: z.union([z.string(), z.null()]).optional(),
    damgrandsire2_obj_uid: z.union([z.string(), z.null()]).optional(),
    siresire: z.string().max(45).optional(),
    siredam: z.string().max(45).optional(),
    siregrandsire1: z.string().max(45).optional(),
    siregrandsire2: z.string().max(45).optional(),
    siregranddam1: z.string().max(45).optional(),
    siregranddam2: z.string().max(45).optional(),
    damgranddam1: z.string().max(45).optional(),
    damgranddam2: z.string().max(45).optional(),
    damgrandsire1: z.string().max(45).optional(),
    damgrandsire2: z.string().max(45).optional(),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/)
      .optional(),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/)
      .optional(),
    FEI_pass_nr: z.string().max(20).optional(),
    id_horsetelex: z.string().max(45).optional(),
    withers_height: z.union([z.number(), z.null()]).optional(),
  })
  .passthrough();
const SearchHorse = z
  .object({
    q: z.union([z.string(), z.null()]),
    name: z.union([z.string(), z.null()]),
    sire: z.union([z.string(), z.null()]),
    dam: z.union([z.string(), z.null()]),
    damsire: z.union([z.string(), z.null()]),
    year_of_birth: z.union([z.number(), z.null()]),
    UELN: z.union([z.string(), z.null()]),
    FEI_pass_nr: z.union([z.string(), z.null()]),
    chip_nr: z.union([z.string(), z.null()]),
    page: z.union([z.number(), z.null()]),
    pageSize: z.union([z.number(), z.null()]),
    db: externalDbEnum,
  })
  .passthrough();
const EquineMSearchLastPage = z.object({ items: z.array(EquineMHorseSearchDetail), is_last_page: z.boolean() }).passthrough();
const Invoice = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    customer_uid: z.string(),
    supplier_uid: z.string(),
    order_uid: z.union([z.string(), z.null()]),
    total: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    currency: z.string(),
    total_incl_vat: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    invoice_no: z.string().max(255).optional(),
    draft: z.boolean(),
    finalized_on: z.union([z.string(), z.null()]),
    date: z.string().optional(),
    sent_on: z.union([z.string(), z.null()]),
    moneybird_invoice_id: z.union([z.number(), z.null()]),
    exactnl_invoice_id: z.string(),
    snelstart_invoice_id: z.string(),
    yuki_invoice_id: z.string(),
    payment_status: PaymentStatus,
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    start_date_activities: z.union([z.string(), z.null()]).optional(),
    end_date_activities: z.union([z.string(), z.null()]).optional(),
    start_date_services: z.union([z.string(), z.null()]).optional(),
    end_date_services: z.union([z.string(), z.null()]).optional(),
    created_by_uid: z.string(),
    expiration_date: z.union([z.string(), z.null()]),
    first_due_interval: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    payment_summary: PaymentSummary,
  })
  .passthrough();
const PaginatedInvoiceList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Invoice) })
  .passthrough();
const NestedInvoiceItem = z
  .object({
    uid: z.string().optional(),
    description: z.string(),
    unit_price: z.union([z.string(), z.null()]).optional(),
    unit_price_currency: z.union([z.string(), z.null()]),
    category_uid: z.string(),
    quantity: z.union([z.string(), z.null()]).optional(),
    qualifier: z.string().max(15).optional(),
    vat_percentage_uid: z.union([z.string(), z.null()]).optional(),
    vat_percentage: NestedVATPercentage,
    vat_included_in_price: z.boolean().optional(),
    deferred: z.boolean().optional(),
    realactivities: z.array(z.string()).optional(),
    service_uid: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    transactionitems: z.array(z.string()).optional(),
    last_modified_on: z.string().datetime({ offset: true }),
    horse_uid: z.union([z.string(), z.null()]).optional(),
    horse: NamedHorse,
    total_vat: Money,
  })
  .passthrough();
const InvoiceDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    customer_uid: z.string(),
    supplier_uid: z.string().optional(),
    total: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    currency: z.string(),
    total_incl_vat: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    invoice_no: z.string().max(255).optional(),
    draft: z.boolean(),
    finalized_on: z.union([z.string(), z.null()]),
    date: z.string().optional(),
    sent_on: z.union([z.string(), z.null()]),
    moneybird_invoice_id: z.union([z.number(), z.null()]),
    exactnl_invoice_id: z.string(),
    snelstart_invoice_id: z.string(),
    yuki_invoice_id: z.string(),
    payment_status: PaymentStatus,
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    start_date_activities: z.union([z.string(), z.null()]).optional(),
    end_date_activities: z.union([z.string(), z.null()]).optional(),
    start_date_services: z.union([z.string(), z.null()]).optional(),
    end_date_services: z.union([z.string(), z.null()]).optional(),
    created_by_uid: z.string(),
    expiration_date: z.union([z.string(), z.null()]),
    first_due_interval: z.number().int().gte(-2147483648).lte(2147483647),
    payment_summary: PaymentSummary,
    order: NestedOrder.optional(),
    historic_supplier: SupplierNameAndAddress,
    language: z.string(),
    items: z.array(NestedInvoiceItem).optional(),
    historic_customer: PurchaserNameAndAddress,
    exactnl_url: z.union([z.string(), z.null()]),
    exactnl_entry_no: z.string(),
    snelstart_entry_no: z.string(),
  })
  .passthrough();
const PatchedInvoiceDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    customer_uid: z.string(),
    supplier_uid: z.string(),
    total: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    currency: z.string(),
    total_incl_vat: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    invoice_no: z.string().max(255),
    draft: z.boolean(),
    finalized_on: z.union([z.string(), z.null()]),
    date: z.string(),
    sent_on: z.union([z.string(), z.null()]),
    moneybird_invoice_id: z.union([z.number(), z.null()]),
    exactnl_invoice_id: z.string(),
    snelstart_invoice_id: z.string(),
    yuki_invoice_id: z.string(),
    payment_status: PaymentStatus,
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    start_date_activities: z.union([z.string(), z.null()]),
    end_date_activities: z.union([z.string(), z.null()]),
    start_date_services: z.union([z.string(), z.null()]),
    end_date_services: z.union([z.string(), z.null()]),
    created_by_uid: z.string(),
    expiration_date: z.union([z.string(), z.null()]),
    first_due_interval: z.number().int().gte(-2147483648).lte(2147483647),
    payment_summary: PaymentSummary,
    order: NestedOrder,
    historic_supplier: SupplierNameAndAddress,
    language: z.string(),
    items: z.array(NestedInvoiceItem),
    historic_customer: PurchaserNameAndAddress,
    exactnl_url: z.union([z.string(), z.null()]),
    exactnl_entry_no: z.string(),
    snelstart_entry_no: z.string(),
  })
  .passthrough();
const InvoiceFinalize = z.object({ send_email: z.boolean(), send_to_accounting_software: z.boolean() }).passthrough();
const LeaveAllowanceMutation = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    contact: z.string(),
    employment: z.string(),
    date: z.string(),
    amount: z
      .string()
      .regex(/^-?\d{0,4}(?:\.\d{0,2})?$/)
      .optional(),
    note: z.string().optional(),
  })
  .passthrough();
const PatchedLeaveAllowanceMutation = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    contact: z.string(),
    employment: z.string(),
    date: z.string(),
    amount: z.string().regex(/^-?\d{0,4}(?:\.\d{0,2})?$/),
    note: z.string(),
  })
  .passthrough();
const HistoricHorseCount = z
  .object({
    year: z.number().int().gte(2000).lte(2999),
    month: z.number().int().gte(1).lte(12),
    sample_date: z.string(),
    time_frame: TimeFrameEnum,
    name: z.string(),
    pony_count: z.string().regex(/^-?\d{0,5}(?:\.\d{0,1})?$/),
    horse_count: z.string().regex(/^-?\d{0,5}(?:\.\d{0,1})?$/),
  })
  .passthrough();
const AveragedHistoricHorseCount = z.object({ samples: z.array(HistoricHorseCount), average: HistoricHorseCount }).passthrough();
const FilePointOfInterest = z
  .object({
    uid: z.string(),
    parent_map: z.string(),
    coordinate_x: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    coordinate_y: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    filename: z.string(),
    description: z.string().max(255).optional(),
    file: z.string().url(),
  })
  .passthrough();
const FilePointOfInterestTyped = z.object({ resourcetype: z.string() }).passthrough().and(FilePointOfInterest);
const MapPointOfInterest = z
  .object({
    uid: z.string(),
    parent_map: z.string(),
    coordinate_x: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    coordinate_y: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    map: z.string(),
  })
  .passthrough();
const MapPointOfInterestTyped = z.object({ resourcetype: z.string() }).passthrough().and(MapPointOfInterest);
const URLPointOfInterest = z
  .object({
    uid: z.string(),
    parent_map: z.string(),
    coordinate_x: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    coordinate_y: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    url: z.string().max(200).url(),
  })
  .passthrough();
const URLPointOfInterestTyped = z.object({ resourcetype: z.string() }).passthrough().and(URLPointOfInterest);
const OAuth2Token = z
  .object({
    uid: z.string(),
    name: z.string(),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    token_type: z.string(),
  })
  .passthrough();
const PaymentTypeEnum = z.enum(['Bank', 'Cash', 'Mollie', 'PayPal', 'Pin', 'CreditCard']);
const Payment = z
  .object({
    uid: z.string(),
    type: PaymentTypeEnum.optional(),
    date: z.union([z.string(), z.null()]).optional(),
    amount: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    amount_currency: z.string(),
    status: PaymentStatus.optional(),
    created_on: z.string().datetime({ offset: true }),
    mollie_payment_id: z.union([z.string(), z.null()]).optional(),
    invoice: z.union([z.string(), z.null()]).optional(),
    order: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const PaginatedPaymentList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Payment) })
  .passthrough();
const PatchedPayment = z
  .object({
    uid: z.string(),
    type: PaymentTypeEnum,
    date: z.union([z.string(), z.null()]),
    amount: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    amount_currency: z.string(),
    status: PaymentStatus,
    created_on: z.string().datetime({ offset: true }),
    mollie_payment_id: z.union([z.string(), z.null()]),
    invoice: z.union([z.string(), z.null()]),
    order: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PatchedFilePointOfInterest = z
  .object({
    uid: z.string(),
    parent_map: z.string(),
    coordinate_x: z.number().int().gte(-2147483648).lte(2147483647),
    coordinate_y: z.number().int().gte(-2147483648).lte(2147483647),
    filename: z.string(),
    description: z.string().max(255),
    file: z.string().url(),
  })
  .passthrough();
const PatchedFilePointOfInterestTyped = z.object({ resourcetype: z.string() }).passthrough().and(PatchedFilePointOfInterest);
const PatchedMapPointOfInterest = z
  .object({
    uid: z.string(),
    parent_map: z.string(),
    coordinate_x: z.number().int().gte(-2147483648).lte(2147483647),
    coordinate_y: z.number().int().gte(-2147483648).lte(2147483647),
    map: z.string(),
  })
  .passthrough();
const PatchedMapPointOfInterestTyped = z.object({ resourcetype: z.string() }).passthrough().and(PatchedMapPointOfInterest);
const PatchedURLPointOfInterest = z
  .object({
    uid: z.string(),
    parent_map: z.string(),
    coordinate_x: z.number().int().gte(-2147483648).lte(2147483647),
    coordinate_y: z.number().int().gte(-2147483648).lte(2147483647),
    url: z.string().max(200).url(),
  })
  .passthrough();
const PatchedURLPointOfInterestTyped = z.object({ resourcetype: z.string() }).passthrough().and(PatchedURLPointOfInterest);
const shipping_service_type = z
  .union([z.enum(['NEXT_DAY_DELIVERY', 'PICK_UP', 'REGULAR', 'SAME_DAY_DELIVERY', 'STAFF_DELIVERY', 'SUNDAY_HOLIDAY_DELIVERY']), z.null()])
  .optional();
const ProductTypeEnum = z.enum(['GOODS', 'SERVICES', 'DIGITAL']);
const CustomTypeEnum = z.enum(['HEALTH_CERTIFICATE', 'BREEDING_ADMINISTRATION']);
const PricePoint = z
  .object({
    max_units: z.union([z.number(), z.null()]).optional(),
    price: z.union([z.string(), z.null()]).optional(),
    price_currency: z.union([z.string(), z.null()]),
  })
  .passthrough();
const ShippingServiceTypeEnum = z.enum([
  'REGULAR',
  'PICK_UP',
  'SAME_DAY_DELIVERY',
  'NEXT_DAY_DELIVERY',
  'SUNDAY_HOLIDAY_DELIVERY',
  'STAFF_DELIVERY',
]);
const Product = z
  .object({
    uid: z.string(),
    name: z.string().max(100).optional(),
    hidden: z.boolean().optional(),
    description: z.string().max(255).optional(),
    available_until: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    category_uid: z.string(),
    product_type: ProductTypeEnum.optional(),
    custom_type: CustomTypeEnum.optional(),
    plan_uid: z.union([z.string(), z.null()]).optional(),
    max_amount: z.union([z.number(), z.null()]).optional(),
    yearly_invoice_discount_percentage: z.number().int().gte(0).lte(100).optional(),
    qualifier: z.string().max(15).optional(),
    price_points: z.array(PricePoint),
    current_price: z.union([z.string(), z.null()]).optional(),
    current_price_currency: z.string().optional(),
    future_price: z.union([z.string(), z.null()]).optional(),
    future_price_currency: z.string().optional(),
    future_price_application_date: z.union([z.string(), z.null()]).optional(),
    upfront_cost: z
      .string()
      .regex(/^-?\d{0,8}(?:\.\d{0,2})?$/)
      .optional(),
    upfront_cost_currency: z.string(),
    requires_shipping: z.boolean(),
    stallion_uid: z.union([z.string(), z.null()]).optional(),
    fresh_available: z.boolean().optional(),
    frozen_available: z.boolean().optional(),
    shipping_provider_name: z.union([z.string(), z.null()]).optional(),
    postcodes_for_staff_delivery: z
      .string()
      .max(255)
      .regex(/^\W_+(?:,^\W_+)*$/)
      .optional(),
    shipping_service_type: ShippingServiceTypeEnum.optional(),
    shipping_countries: z
      .union([
        z.array(
          z.enum([
            'AF',
            'AX',
            'AL',
            'DZ',
            'AS',
            'AD',
            'AO',
            'AI',
            'AQ',
            'AG',
            'AR',
            'AM',
            'AW',
            'AU',
            'AT',
            'AZ',
            'BS',
            'BH',
            'BD',
            'BB',
            'BY',
            'BE',
            'BZ',
            'BJ',
            'BM',
            'BT',
            'BO',
            'BQ',
            'BA',
            'BW',
            'BV',
            'BR',
            'IO',
            'BN',
            'BG',
            'BF',
            'BI',
            'CV',
            'KH',
            'CM',
            'CA',
            'KY',
            'CF',
            'TD',
            'CL',
            'CN',
            'CX',
            'CC',
            'CO',
            'KM',
            'CG',
            'CD',
            'CK',
            'CR',
            'CI',
            'HR',
            'CU',
            'CW',
            'CY',
            'CZ',
            'DK',
            'DJ',
            'DM',
            'DO',
            'EC',
            'EG',
            'SV',
            'GQ',
            'ER',
            'EE',
            'SZ',
            'ET',
            'FK',
            'FO',
            'FJ',
            'FI',
            'FR',
            'GF',
            'PF',
            'TF',
            'GA',
            'GM',
            'GE',
            'DE',
            'GH',
            'GI',
            'GR',
            'GL',
            'GD',
            'GP',
            'GU',
            'GT',
            'GG',
            'GN',
            'GW',
            'GY',
            'HT',
            'HM',
            'VA',
            'HN',
            'HK',
            'HU',
            'IS',
            'IN',
            'ID',
            'IR',
            'IQ',
            'IE',
            'IM',
            'IL',
            'IT',
            'JM',
            'JP',
            'JE',
            'JO',
            'KZ',
            'KE',
            'KI',
            'KW',
            'KG',
            'LA',
            'LV',
            'LB',
            'LS',
            'LR',
            'LY',
            'LI',
            'LT',
            'LU',
            'MO',
            'MG',
            'MW',
            'MY',
            'MV',
            'ML',
            'MT',
            'MH',
            'MQ',
            'MR',
            'MU',
            'YT',
            'MX',
            'FM',
            'MD',
            'MC',
            'MN',
            'ME',
            'MS',
            'MA',
            'MZ',
            'MM',
            'NA',
            'NR',
            'NP',
            'NL',
            'NC',
            'NZ',
            'NI',
            'NE',
            'NG',
            'NU',
            'NF',
            'KP',
            'MK',
            'MP',
            'NO',
            'OM',
            'PK',
            'PW',
            'PS',
            'PA',
            'PG',
            'PY',
            'PE',
            'PH',
            'PN',
            'PL',
            'PT',
            'PR',
            'QA',
            'RE',
            'RO',
            'RU',
            'RW',
            'BL',
            'SH',
            'KN',
            'LC',
            'MF',
            'PM',
            'VC',
            'WS',
            'SM',
            'ST',
            'SA',
            'SN',
            'RS',
            'SC',
            'SL',
            'SG',
            'SX',
            'SK',
            'SI',
            'SB',
            'SO',
            'ZA',
            'GS',
            'KR',
            'SS',
            'ES',
            'LK',
            'SD',
            'SR',
            'SJ',
            'SE',
            'CH',
            'SY',
            'TW',
            'TJ',
            'TZ',
            'TH',
            'TL',
            'TG',
            'TK',
            'TO',
            'TT',
            'TN',
            'TR',
            'TM',
            'TC',
            'TV',
            'UG',
            'UA',
            'AE',
            'GB',
            'UM',
            'US',
            'UY',
            'UZ',
            'VU',
            'VE',
            'VN',
            'VG',
            'VI',
            'WF',
            'EH',
            'YE',
            'ZM',
            'ZW',
            '',
          ]),
        ),
        z.null(),
      ])
      .optional(),
  })
  .passthrough();
const PaginatedProductList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Product) })
  .passthrough();
const PatchedProduct = z
  .object({
    uid: z.string(),
    name: z.string().max(100),
    hidden: z.boolean(),
    description: z.string().max(255),
    available_until: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    category_uid: z.string(),
    product_type: ProductTypeEnum,
    custom_type: CustomTypeEnum,
    plan_uid: z.union([z.string(), z.null()]),
    max_amount: z.union([z.number(), z.null()]),
    yearly_invoice_discount_percentage: z.number().int().gte(0).lte(100),
    qualifier: z.string().max(15),
    price_points: z.array(PricePoint),
    current_price: z.union([z.string(), z.null()]),
    current_price_currency: z.string(),
    future_price: z.union([z.string(), z.null()]),
    future_price_currency: z.string(),
    future_price_application_date: z.union([z.string(), z.null()]),
    upfront_cost: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    upfront_cost_currency: z.string(),
    requires_shipping: z.boolean(),
    stallion_uid: z.union([z.string(), z.null()]),
    fresh_available: z.boolean(),
    frozen_available: z.boolean(),
    shipping_provider_name: z.union([z.string(), z.null()]),
    postcodes_for_staff_delivery: z
      .string()
      .max(255)
      .regex(/^\W_+(?:,^\W_+)*$/),
    shipping_service_type: ShippingServiceTypeEnum,
    shipping_countries: z.union([
      z.array(
        z.enum([
          'AF',
          'AX',
          'AL',
          'DZ',
          'AS',
          'AD',
          'AO',
          'AI',
          'AQ',
          'AG',
          'AR',
          'AM',
          'AW',
          'AU',
          'AT',
          'AZ',
          'BS',
          'BH',
          'BD',
          'BB',
          'BY',
          'BE',
          'BZ',
          'BJ',
          'BM',
          'BT',
          'BO',
          'BQ',
          'BA',
          'BW',
          'BV',
          'BR',
          'IO',
          'BN',
          'BG',
          'BF',
          'BI',
          'CV',
          'KH',
          'CM',
          'CA',
          'KY',
          'CF',
          'TD',
          'CL',
          'CN',
          'CX',
          'CC',
          'CO',
          'KM',
          'CG',
          'CD',
          'CK',
          'CR',
          'CI',
          'HR',
          'CU',
          'CW',
          'CY',
          'CZ',
          'DK',
          'DJ',
          'DM',
          'DO',
          'EC',
          'EG',
          'SV',
          'GQ',
          'ER',
          'EE',
          'SZ',
          'ET',
          'FK',
          'FO',
          'FJ',
          'FI',
          'FR',
          'GF',
          'PF',
          'TF',
          'GA',
          'GM',
          'GE',
          'DE',
          'GH',
          'GI',
          'GR',
          'GL',
          'GD',
          'GP',
          'GU',
          'GT',
          'GG',
          'GN',
          'GW',
          'GY',
          'HT',
          'HM',
          'VA',
          'HN',
          'HK',
          'HU',
          'IS',
          'IN',
          'ID',
          'IR',
          'IQ',
          'IE',
          'IM',
          'IL',
          'IT',
          'JM',
          'JP',
          'JE',
          'JO',
          'KZ',
          'KE',
          'KI',
          'KW',
          'KG',
          'LA',
          'LV',
          'LB',
          'LS',
          'LR',
          'LY',
          'LI',
          'LT',
          'LU',
          'MO',
          'MG',
          'MW',
          'MY',
          'MV',
          'ML',
          'MT',
          'MH',
          'MQ',
          'MR',
          'MU',
          'YT',
          'MX',
          'FM',
          'MD',
          'MC',
          'MN',
          'ME',
          'MS',
          'MA',
          'MZ',
          'MM',
          'NA',
          'NR',
          'NP',
          'NL',
          'NC',
          'NZ',
          'NI',
          'NE',
          'NG',
          'NU',
          'NF',
          'KP',
          'MK',
          'MP',
          'NO',
          'OM',
          'PK',
          'PW',
          'PS',
          'PA',
          'PG',
          'PY',
          'PE',
          'PH',
          'PN',
          'PL',
          'PT',
          'PR',
          'QA',
          'RE',
          'RO',
          'RU',
          'RW',
          'BL',
          'SH',
          'KN',
          'LC',
          'MF',
          'PM',
          'VC',
          'WS',
          'SM',
          'ST',
          'SA',
          'SN',
          'RS',
          'SC',
          'SL',
          'SG',
          'SX',
          'SK',
          'SI',
          'SB',
          'SO',
          'ZA',
          'GS',
          'KR',
          'SS',
          'ES',
          'LK',
          'SD',
          'SR',
          'SJ',
          'SE',
          'CH',
          'SY',
          'TW',
          'TJ',
          'TZ',
          'TH',
          'TL',
          'TG',
          'TK',
          'TO',
          'TT',
          'TN',
          'TR',
          'TM',
          'TC',
          'TV',
          'UG',
          'UA',
          'AE',
          'GB',
          'UM',
          'US',
          'UY',
          'UZ',
          'VU',
          'VE',
          'VN',
          'VG',
          'VI',
          'WF',
          'EH',
          'YE',
          'ZM',
          'ZW',
          '',
        ]),
      ),
      z.null(),
    ]),
  })
  .passthrough();
const Role = z
  .object({
    uid: z.string(),
    name: z.string().max(45).optional(),
    default_id: z.union([z.number(), z.null()]),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    last_modified_on: z.string().datetime({ offset: true }),
    created_on: z.string().datetime({ offset: true }),
    hidden: z.boolean().optional(),
  })
  .passthrough();
const PatchedRole = z
  .object({
    uid: z.string(),
    name: z.string().max(45),
    default_id: z.union([z.number(), z.null()]),
    stable_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    created_on: z.string().datetime({ offset: true }),
    hidden: z.boolean(),
  })
  .passthrough();
const ModulePermissionsEnum = z.enum([
  'view_own_horses',
  'view_horses',
  'manage_horses',
  'view_horse_files',
  'edit_horse_files',
  'view_horse_owners',
  'view_veterinary_activities',
  'medical_files',
  'view_schedule',
  'set_to_done',
  'manage_schedule',
  'manage_semen_journal',
  'manage_orders',
  'pick_orders',
  'manage_financial',
  'view_contacts',
  'manage_contacts',
  'view_feed',
  'manage_feed',
  'plan_own_facilities_events',
  'manage_facilities',
]);
const ModulePermissions = z.object({ uid: z.string(), module_permissions: z.array(ModulePermissionsEnum) }).passthrough();
const Module = z.object({ id: z.string(), name: z.string() }).passthrough();
const ModulePermissionsObject = z.object({ id: z.string(), name: z.string(), module: Module }).passthrough();
const ModulePermissionsList = z.object({ uid: z.string(), module_permissions: z.array(ModulePermissionsObject) }).passthrough();
const Service = z
  .object({
    uid: z.string(),
    name: z.string().max(255),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    category_uid: z.string(),
    vat_included_in_price: z.boolean().optional(),
    description: z.string().max(255),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const PatchedService = z
  .object({
    uid: z.string(),
    name: z.string().max(255),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    category_uid: z.string(),
    vat_included_in_price: z.boolean(),
    description: z.string().max(255),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const StableContact = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])).optional(),
    initials: z.string().max(3).optional(),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])).optional(),
    first_name: z.string().max(255).optional(),
    last_name: z.string().max(255).optional(),
    studbook_preference: StudbookEnum.optional(),
    business_name: z.string().max(255),
    phone_number: z.string().max(128),
    email: z.string().max(254).email().optional(),
    external_location: z.boolean().optional(),
    customer_id: z.string().max(7).optional(),
    moneybird_contact_id: z.union([z.number(), z.null()]).optional(),
    exactnl_contact_id: z.string().max(100).optional(),
    snelstart_contact_id: z.string().max(150).optional(),
    is_semen_collection_station: z.boolean().optional(),
    is_insemination_station: z.boolean().optional(),
    use_for_picking: z.boolean().optional(),
    vat_number: z.string().max(19).optional(),
    vat_number_checked: z.union([z.boolean(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    country: CountryEnum.optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64),
    city: z.string().max(255),
    title: TitleEnum.optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    show_in_daily: z.boolean().optional(),
    show_in_work_schedule: z.boolean().optional(),
    note: z.string().optional(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30).optional(),
    invoice_language: InvoiceLanguageEnum.optional(),
    business_type: BusinessTypeEnum.optional(),
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().optional().default(false),
    parent_stable: z.union([z.string(), z.null()]).optional(),
    IBAN: z.string().max(34).optional(),
    bank_code: z.string().max(255).optional(),
    account_number: z.string().max(60).optional(),
    bank_name: z.string().max(255).optional(),
    second_phone_number: z.string().max(128).optional(),
    company_registration_number: z.string().max(20).optional(),
  })
  .passthrough();
const Stable = z
  .object({
    uid: z.string(),
    created_by_uid: z.string(),
    location_uid: z.string(),
    timezone: TimezoneEnum.optional(),
    location: StableContact,
    is_primary: z.boolean().optional(),
  })
  .passthrough();
const PatchedStable = z
  .object({
    uid: z.string(),
    created_by_uid: z.string(),
    location_uid: z.string(),
    timezone: TimezoneEnum,
    location: StableContact,
    is_primary: z.boolean(),
  })
  .passthrough();
const VaccinationRule = z
  .object({
    uid: z.string(),
    name: z.string().max(100).optional(),
    organisation_uid: z.union([z.string(), z.null()]),
    months_between_vaccination: z.number().int().gte(-2147483648).lte(2147483647),
    optional_extra_days: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    number_of_days_not_competing_after_vaccination: z.number().int().gte(-2147483648).lte(2147483647),
  })
  .passthrough();
const PatchedVaccinationRule = z
  .object({
    uid: z.string(),
    name: z.string().max(100),
    organisation_uid: z.union([z.string(), z.null()]),
    months_between_vaccination: z.number().int().gte(-2147483648).lte(2147483647),
    optional_extra_days: z.number().int().gte(-2147483648).lte(2147483647),
    number_of_days_not_competing_after_vaccination: z.number().int().gte(-2147483648).lte(2147483647),
  })
  .passthrough();
const VATPercentageVatLevelEnum = z.enum(['STANDARD', 'REDUCED', 'SECOND_REDUCED', 'SUPER_REDUCED', 'ZERO']);
const VATPercentage = z
  .object({
    uid: z.string(),
    percentage: z.string().regex(/^-?\d{0,2}(?:\.\d{0,2})?$/),
    vat_category: VatCategoryEnum,
    hidden: z.boolean().optional(),
    created_on: z.string().datetime({ offset: true }),
    vat_level: VATPercentageVatLevelEnum.optional(),
    start_date: z.string().optional(),
    is_active: z.boolean(),
    country: CountryEnum.optional(),
  })
  .passthrough();
const VATPercentageUpdate = z.object({ hidden: z.boolean() }).passthrough();
const PatchedVATPercentageUpdate = z.object({ hidden: z.boolean() }).passthrough();
const SupplierServiceContract = z
  .object({
    uid: z.string(),
    customer_uid: z.string(),
    product_uid: z.string(),
    module: ModuleEnum,
    pricing_model: PricingModelEnum,
    start_dt: z.string().datetime({ offset: true }),
    duration_amount: z.number().int(),
    duration_unit: TimeFrameEnum,
    notice_period: TimeFrameEnum,
    end_dt: z.union([z.string(), z.null()]),
    is_suspended: z.boolean(),
    currency: z.union([z.string(), z.null()]),
    fixed_price: z.union([z.string(), z.null()]),
    estimated_cost: z.union([z.string(), z.null()]),
    next_tiered_instance_cost: z.union([z.string(), z.null()]),
    total_vat: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    discount_percentage: z.number().int(),
    applied_unit_count: z.union([z.number(), z.null()]),
    available_unit_count: z.union([z.number(), z.null()]),
    maximum_unit_count: z.union([z.number(), z.null()]),
    extend_automatically: z.boolean(),
    invoice_period: TimeFrameEnum,
    followup_contract_uid: z.string(),
  })
  .passthrough();
const PaginatedSupplierServiceContractList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(SupplierServiceContract),
  })
  .passthrough();
const PatchedSupplierServiceContract = z
  .object({
    uid: z.string(),
    customer_uid: z.string(),
    product_uid: z.string(),
    module: ModuleEnum,
    pricing_model: PricingModelEnum,
    start_dt: z.string().datetime({ offset: true }),
    duration_amount: z.number().int(),
    duration_unit: TimeFrameEnum,
    notice_period: TimeFrameEnum,
    end_dt: z.union([z.string(), z.null()]),
    is_suspended: z.boolean(),
    currency: z.union([z.string(), z.null()]),
    fixed_price: z.union([z.string(), z.null()]),
    estimated_cost: z.union([z.string(), z.null()]),
    next_tiered_instance_cost: z.union([z.string(), z.null()]),
    total_vat: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    discount_percentage: z.number().int(),
    applied_unit_count: z.union([z.number(), z.null()]),
    available_unit_count: z.union([z.number(), z.null()]),
    maximum_unit_count: z.union([z.number(), z.null()]),
    extend_automatically: z.boolean(),
    invoice_period: TimeFrameEnum,
    followup_contract_uid: z.string(),
  })
  .passthrough();
const PatchedServiceContractChange = z.object({ max_amount: z.union([z.number(), z.null()]) }).passthrough();
const ServiceContractChange = z.object({ max_amount: z.union([z.number(), z.null()]) }).passthrough();
const PurchaserOrder = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    purchaser_uid: z.union([z.string(), z.null()]),
    supplier: z.string().uuid(),
    customer_note: z.string().optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    payment_summary: PaymentSummary,
    shipping_date: z.union([z.string(), z.null()]).optional(),
    order_items: z.array(PurchaserOrderItem),
    dry_run: z.boolean().optional(),
    terms_accepted: z.boolean(),
    shipping_name: z.string().max(255).optional(),
    shipping_address_line1: z.string().max(255).optional(),
    shipping_address_line2: z.string().max(255).optional(),
    shipping_address_line3: z.string().max(255).optional(),
    shipping_city: z.string().max(255).optional(),
    shipping_state: z.string().max(255).optional(),
    shipping_postcode: z.string().max(64).optional(),
    shipping_country: CountryEnum.optional(),
    shipping_location_traces_number: z.string().max(64).optional(),
    shipping_search_text: z.string(),
    parent_semen_order: ParentSemenOrder,
  })
  .passthrough();
const PaginatedPurchaserOrderList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(PurchaserOrder),
  })
  .passthrough();
const PurchaserOrderDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    purchaser_uid: z.union([z.string(), z.null()]),
    supplier: z.string().uuid(),
    customer_note: z.string().optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    payment_summary: PaymentSummary,
    shipping_date: z.union([z.string(), z.null()]).optional(),
    order_items: z.array(PurchaserOrderItem),
    dry_run: z.boolean().optional(),
    terms_accepted: z.boolean(),
    shipping_name: z.string().max(255).optional(),
    shipping_address_line1: z.string().max(255).optional(),
    shipping_address_line2: z.string().max(255).optional(),
    shipping_address_line3: z.string().max(255).optional(),
    shipping_city: z.string().max(255).optional(),
    shipping_state: z.string().max(255).optional(),
    shipping_postcode: z.string().max(64).optional(),
    shipping_country: CountryEnum.optional(),
    shipping_location_traces_number: z.string().max(64).optional(),
    shipping_search_text: z.string(),
    parent_semen_order: ParentSemenOrder,
    historic_customer: PurchaserNameAndAddress,
  })
  .passthrough();
const FindParentSemenOrderItemPurchaser = z
  .object({
    date: z.string(),
    orderitem_to_exclude: z.string().optional(),
    mare_uid: z.string(),
    usage_type: UsageTypeEnum,
    supplier: z.string().uuid(),
  })
  .passthrough();
const PaginatedPurchaserServiceContractList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(PurchaserServiceContract),
  })
  .passthrough();
const PatchedPurchaserServiceContract = z
  .object({
    uid: z.string(),
    customer_uid: z.string(),
    product_uid: z.string(),
    module: ModuleEnum,
    pricing_model: PricingModelEnum,
    start_dt: z.string().datetime({ offset: true }),
    duration_amount: z.number().int(),
    duration_unit: TimeFrameEnum,
    notice_period: TimeFrameEnum,
    end_dt: z.union([z.string(), z.null()]),
    is_suspended: z.boolean(),
    currency: z.union([z.string(), z.null()]),
    fixed_price: z.union([z.string(), z.null()]),
    estimated_cost: z.union([z.string(), z.null()]),
    next_tiered_instance_cost: z.union([z.string(), z.null()]),
    total_vat: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    discount_percentage: z.number().int(),
    applied_unit_count: z.union([z.number(), z.null()]),
    available_unit_count: z.union([z.number(), z.null()]),
    maximum_unit_count: z.union([z.number(), z.null()]),
    extend_automatically: z.boolean(),
    invoice_period: TimeFrameEnum,
    followup_contract_uid: z.string(),
    purchaser: z.union([z.string(), z.null()]),
  })
  .passthrough();
const NIFAStrawColorEnum = z.enum([
  'CLEAR',
  'PASTEL_GRAY',
  'YELLOW',
  'PASTEL_YELLOW',
  'PUTTY',
  'PASTEL_ORANGE',
  'SALMON',
  'PASTEL_RED',
  'PINK',
  'RED',
  'PASTEL_BLUE',
  'PURPLE',
  'PASTEL_PISTACHIO',
  'GREEN',
  'PASTEL_GREEN',
  'PISTACHIO_GREEN',
]);
const StallionMount = z
  .object({
    uid: z.string(),
    stallion_uid: z.string(),
    collection_date: z.string().optional(),
    volume: z.number().int().gte(0).lte(65535).optional(),
    concentration: z.number().int().gte(0).lte(65535).optional(),
    storage_container: z.string().max(64).optional(),
    storage_canister: z.string().max(64).optional(),
    portions_fresh: z.number().int().gte(0).lte(65535).optional(),
    portions_fresh_available: z.number().int().optional(),
    portions_fresh_destroyed_amount: z.number().int(),
    portions_fresh_destroyed_on: z.union([z.string(), z.null()]),
    straws_frozen: z.number().int().gte(0).lte(65535).optional(),
    straws_frozen_available: z.number().int().optional(),
    straws_frozen_destroyed_amount: z.number().int(),
    straws_frozen_destroyed_on: z.union([z.string(), z.null()]),
    NIFA_straw_color: NIFAStrawColorEnum.optional(),
    morphology: z.number().int().gte(0).lte(100).optional(),
    moving_cells_percentage: z.number().int().gte(0).lte(100).optional(),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    last_modified_by: z.string(),
    semen_collection_station: z.union([z.string(), z.null()]).optional(),
    activity_uid: z.string().optional(),
  })
  .passthrough();
const PaginatedStallionMountList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(StallionMount),
  })
  .passthrough();
const SupplierOrderItem = z
  .object({
    uid: z.string().optional(),
    order_uid: z.string(),
    product_uid: z.union([z.string(), z.null()]).optional(),
    price_point_unit_count: z.union([z.number(), z.null()]).optional(),
    unit_price: z.union([z.string(), z.null()]).optional(),
    unit_price_currency: z.union([z.string(), z.null()]),
    upfront_payment: z.union([z.string(), z.null()]).optional(),
    upfront_payment_currency: z.union([z.string(), z.null()]),
    invoice_period: TimeFrameEnum.optional(),
    vat_percentage: NestedVATPercentage,
    quantity: z.number().int().gte(0).lte(65535).optional(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    picked_on: z.union([z.string(), z.null()]),
    picked_by_uid: z.union([z.string(), z.null()]),
    mare_uid: z.union([z.string(), z.null()]).optional(),
    semen_type: SemenTypeEnum.optional(),
    usage_type: UsageTypeEnum.optional(),
    studbook: StudbookEnum.optional(),
    parent_semen_order_item_uid: z.union([z.string(), z.null()]).optional(),
    total_vat: Money,
    variable_cost_estimate: z.union([Money, z.null()]),
    semen_collection_station: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const SupplierOrder = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.union([z.string(), z.null()]).optional(),
    requester_uid: z.union([z.string(), z.null()]).optional(),
    supplier_note: z.string().optional(),
    customer_note: z.string(),
    traces_certificate_number: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    last_modified_by: z.string(),
    shipping_date: z.union([z.string(), z.null()]).optional(),
    payment_summary: PaymentSummary,
    dry_run: z.boolean().optional(),
    supplier_uid: z.string(),
    order_items: z.array(SupplierOrderItem).optional(),
    shipping_name: z.string().max(255).optional(),
    shipping_address_line1: z.string().max(255).optional(),
    shipping_address_line2: z.string().max(255).optional(),
    shipping_address_line3: z.string().max(255).optional(),
    shipping_city: z.string().max(255).optional(),
    shipping_state: z.string().max(255).optional(),
    shipping_postcode: z.string().max(64).optional(),
    shipping_country: CountryEnum.optional(),
    shipping_search_text: z.string(),
    shipping_location_traces_number: z.string().max(64).optional(),
    terms_accepted_on: z.union([z.string(), z.null()]),
  })
  .passthrough();
const StallionMountDetail = z
  .object({
    uid: z.string(),
    stallion_uid: z.string(),
    collection_date: z.string().optional(),
    volume: z.number().int().gte(0).lte(65535).optional(),
    concentration: z.number().int().gte(0).lte(65535).optional(),
    storage_container: z.string().max(64).optional(),
    storage_canister: z.string().max(64).optional(),
    portions_fresh: z.number().int().gte(0).lte(65535).optional(),
    portions_fresh_available: z.number().int().optional(),
    portions_fresh_destroyed_amount: z.number().int(),
    portions_fresh_destroyed_on: z.union([z.string(), z.null()]),
    straws_frozen: z.number().int().gte(0).lte(65535).optional(),
    straws_frozen_available: z.number().int().optional(),
    straws_frozen_destroyed_amount: z.number().int(),
    straws_frozen_destroyed_on: z.union([z.string(), z.null()]),
    NIFA_straw_color: NIFAStrawColorEnum.optional(),
    morphology: z.number().int().gte(0).lte(100).optional(),
    moving_cells_percentage: z.number().int().gte(0).lte(100).optional(),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    last_modified_by: z.string(),
    semen_collection_station: z.union([z.string(), z.null()]).optional(),
    used_in_orders: z.array(SupplierOrder),
    activity_uid: z.string().optional(),
  })
  .passthrough();
const PatchedStallionMount = z
  .object({
    uid: z.string(),
    stallion_uid: z.string(),
    collection_date: z.string(),
    volume: z.number().int().gte(0).lte(65535),
    concentration: z.number().int().gte(0).lte(65535),
    storage_container: z.string().max(64),
    storage_canister: z.string().max(64),
    portions_fresh: z.number().int().gte(0).lte(65535),
    portions_fresh_available: z.number().int(),
    portions_fresh_destroyed_amount: z.number().int(),
    portions_fresh_destroyed_on: z.union([z.string(), z.null()]),
    straws_frozen: z.number().int().gte(0).lte(65535),
    straws_frozen_available: z.number().int(),
    straws_frozen_destroyed_amount: z.number().int(),
    straws_frozen_destroyed_on: z.union([z.string(), z.null()]),
    NIFA_straw_color: NIFAStrawColorEnum,
    morphology: z.number().int().gte(0).lte(100),
    moving_cells_percentage: z.number().int().gte(0).lte(100),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    last_modified_by: z.string(),
    semen_collection_station: z.union([z.string(), z.null()]),
    activity_uid: z.string(),
  })
  .passthrough();
const PaginatedSupplierOrderList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(SupplierOrder),
  })
  .passthrough();
const PatchedSupplierOrderDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.union([z.string(), z.null()]),
    requester_uid: z.union([z.string(), z.null()]),
    supplier_note: z.string(),
    customer_note: z.string(),
    traces_certificate_number: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    last_modified_by: z.string(),
    shipping_date: z.union([z.string(), z.null()]),
    payment_summary: PaymentSummary,
    dry_run: z.boolean(),
    supplier_uid: z.string(),
    order_items: z.array(SupplierOrderItemDetail),
    shipping_name: z.string().max(255),
    shipping_address_line1: z.string().max(255),
    shipping_address_line2: z.string().max(255),
    shipping_address_line3: z.string().max(255),
    shipping_city: z.string().max(255),
    shipping_state: z.string().max(255),
    shipping_postcode: z.string().max(64),
    shipping_country: CountryEnum,
    shipping_search_text: z.string(),
    shipping_location_traces_number: z.string().max(64),
    terms_accepted_on: z.union([z.string(), z.null()]),
    historic_customer: PurchaserNameAndAddress,
    send_order_confirmation: z.boolean(),
    parent_semen_order: ParentSemenOrder,
  })
  .passthrough();
const FindParentSemenOrderItem = z
  .object({ date: z.string(), orderitem_to_exclude: z.string().optional(), mare_uid: z.string(), usage_type: UsageTypeEnum })
  .passthrough();
const PatchedOrganisation = z
  .object({
    uid: z.string(),
    created_by_uid: z.string(),
    last_modified_by_uid: z.string(),
    public_access_uuid: z.string().uuid(),
    name: z.string().max(45),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    is_paid: z.boolean(),
    is_bp: z.boolean(),
    shop_terms_conditions: z.union([z.string(), z.null()]),
    use_one_stop_shop: z.boolean(),
    timezone: TimezoneEnum,
    expected_no_horses: z.union([z.number(), z.null()]),
    weeks_between_insemination_and_vaccination: z.union([z.number(), z.null()]),
    days_between_insemination_and_pregnancy_check: z.union([z.number(), z.null()]),
    equiboard_refresh_rate: z.number().int().gte(0).lte(60),
    equiboard_screensaver_time: z.number().gte(0).lte(60),
    equiboard_lockscreendarkness: z.number().gte(0).lte(1),
    also_send_invoice_to_email: z.string().max(254).email(),
    website: z.string().max(200).url(),
    logo: z.union([z.string(), z.null()]),
    invoice_subject: z.string().max(45),
    invoice_text: z.string(),
    currency: z.string().max(3),
    invoice_footer_1: z.string(),
    invoice_footer_2: z.string(),
    max_no_horses: z.union([z.number(), z.null()]),
    last_information_send: z.union([z.string(), z.null()]),
    number_of_information_send_last_day: z.union([z.number(), z.null()]),
    relatienummer_rvo: z.string(),
    type_of_organisation: TypeOfOrganisationEnum,
    enabled_modules: z.array(EnabledModulesEnum),
    invoice_email_by_equinem: z.boolean(),
    primary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    secondary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    show_logo_on_invoice: z.boolean(),
    suggested_breeding_product_down_payment: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    down_payment_permitted_countries: z.array(CountryEnum).max(249),
    invoice_first_due_interval: z.number().int().gte(-2147483648).lte(2147483647),
    can_create_payment_link: z.boolean(),
    only_use_ad: z.boolean(),
    microsoft_ad_group_id: z.string(),
    exactnl_sales_journal: z.string().max(100),
    no_breeding_boxes: z.union([z.number(), z.null()]),
  })
  .passthrough();
const ProviderEnum = z.enum(['moneybird', 'exactnl', 'yuki', 'snelstart']);
const VATPercentageExternalProviderId = z
  .object({
    uid: z.string(),
    vat_percentage_uid: z.string(),
    provider: ProviderEnum,
    external_provider_sales_id: z.string().max(254).optional(),
    external_provider_purchases_id: z.string().max(254).optional(),
  })
  .passthrough();
const PatchedVATPercentageExternalProviderId = z
  .object({
    uid: z.string(),
    vat_percentage_uid: z.string(),
    provider: ProviderEnum,
    external_provider_sales_id: z.string().max(254),
    external_provider_purchases_id: z.string().max(254),
  })
  .passthrough();
const PasswordChange = z
  .object({ old_password: z.string().max(128), new_password1: z.string().max(128), new_password2: z.string().max(128) })
  .passthrough();
const RestAuthDetail = z.object({ detail: z.string() }).passthrough();
const EquineMPasswordReset = z.object({ email: z.string().email() }).passthrough();
const PasswordResetConfirm = z
  .object({ new_password1: z.string().max(128), new_password2: z.string().max(128), uid: z.string(), token: z.string() })
  .passthrough();
const Plan = z
  .object({
    uid: z.string(),
    name: z.string(),
    description: z.string(),
    module: ModuleEnum,
    pricing_model: PricingModelEnum,
    is_generic: z.boolean(),
  })
  .passthrough();
const PublicCategory = z
  .object({
    uid: z.string(),
    p_s_type: PSTypeEnum,
    name: z.string(),
    is_subscription_product: z.boolean(),
    default: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PublicHorse = z
  .object({
    uid: z.string(),
    display_name: z.string(),
    name: z.string(),
    date_of_birth: z.union([z.string(), z.null()]),
    color: ColorEnum,
    sex: SexEnum,
    sire: z.string(),
    dam: z.string(),
    damsire: z.string(),
    siredam: z.string(),
    damdam: z.string(),
    siresire: z.string(),
    siregrandsire1: z.string(),
    siregrandsire2: z.string(),
    siregranddam1: z.string(),
    siregranddam2: z.string(),
    damgranddam1: z.string(),
    damgranddam2: z.string(),
    damgrandsire1: z.string(),
    damgrandsire2: z.string(),
    breeder: z.string(),
    studbook: z.string(),
    UELN: z.string(),
    chip_nr: z.string(),
    id_horsetelex: z.string(),
    avatar_file: z.union([z.string(), z.null()]),
  })
  .passthrough();
const CatalogueProduct = z
  .object({
    uid: z.string(),
    name: z.string(),
    description: z.string(),
    available_until: z.union([z.string(), z.null()]),
    category: PublicCategory,
    plan: z.union([z.string(), z.null()]),
    max_amount: z.union([z.number(), z.null()]),
    yearly_invoice_discount_percentage: z.number().int(),
    qualifier: z.string(),
    requires_shipping: z.boolean(),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    price_points: z.array(PricePoint),
    upfront_cost: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    upfront_cost_currency: z.string(),
    shipping_provider_name: z.union([z.string(), z.null()]),
    shipping_service_type: ShippingServiceTypeEnum,
    postcodes_for_staff_delivery: z.string(),
    shipping_countries: z.array(CountryEnum),
    stallion: z.union([PublicHorse, z.null()]),
    fresh_available: z.boolean(),
    frozen_available: z.boolean(),
  })
  .passthrough();
const PaginatedCatalogueProductList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(CatalogueProduct),
  })
  .passthrough();
const PaginatedVATPercentageList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(VATPercentage),
  })
  .passthrough();
const PublicOrganisation = z
  .object({
    public_access_uuid: z.string().uuid(),
    name: z.string().max(45),
    logo: z.union([z.string(), z.null()]).optional(),
    primary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    secondary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    shop_terms_conditions: z.union([z.string(), z.null()]).optional(),
    can_create_payment_link: z.boolean(),
  })
  .passthrough();
const SignupReferenceEnum = z.enum(['ADVERTISEMENT', 'INTERNET', 'EVENTS', 'PAARD_EN_WINST', 'FLORIAN', 'OTHER']);
const EquineMRegister = z
  .object({
    username: z.string().min(2).max(150).optional(),
    email: z.string().email(),
    password1: z.string(),
    password2: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    agreed_to_terms_and_privacy: z.boolean(),
    coupon_code: z.string().optional(),
    breeding_shop_trial: z.boolean().optional(),
    expected_no_horses: z.union([z.number(), z.null()]),
    signup_reference: SignupReferenceEnum.optional(),
    type_of_organisation: TypeOfOrganisationEnum.optional(),
    signup_supplier_shop: z.union([z.string(), z.null()]).optional(),
    UBN: z.string().max(30).optional(),
    postcode: z.string().max(64).optional(),
    address_line1: z.string().max(255).optional(),
    city: z.string().max(255).optional(),
    country: CountryEnum.optional(),
    phone_number: z.string().max(128),
    address_line3: z.string().max(6).optional(),
    business_name: z.string().max(255).optional(),
  })
  .passthrough();
const SignupWithInvitation = z
  .object({
    password1: z.string(),
    password2: z.string(),
    invitation_uuid: z.string().uuid(),
    first_name: z.string(),
    last_name: z.string(),
    agreed_to_terms_and_privacy: z.boolean(),
  })
  .passthrough();
const SignupUser = z
  .object({
    id: z.number().int(),
    username: z
      .string()
      .max(150)
      .regex(/^[\w.@+-]+$/),
    email: z.string().email(),
    first_name: z.string().max(150).optional(),
    last_name: z.string().max(150).optional(),
  })
  .passthrough();
const SignupResponse = z.object({ access: z.string(), refresh: z.string(), user: SignupUser }).passthrough();
const VerifyEmail = z.object({ key: z.string() }).passthrough();

export const schemas = {
  Authorize,
  CountryEnum,
  Account,
  PatchedAccount,
  FertilizerNormTypeEnum,
  FertilizerNorm,
  PaginatedFertilizerNormList,
  Email,
  SSO,
  NotificationSettings,
  CustomFCMDeviceTypeEnum,
  ApplicationIdEnum,
  CustomFCMDevice,
  PatchedCustomFCMDevice,
  PatchedNotificationSettings,
  ColorEnum,
  SexEnum,
  PrivateEnum,
  PublicHorseMediaSet,
  OrganisationNAW,
  PublicHorsePageOrganisation,
  PublicHorsePageHorse,
  PublicHorsePage,
  InviterOrganisation,
  Inviter,
  ContactData,
  ReceivedInvitation,
  PatchedDeclinedInvitation,
  DeclinedInvitation,
  PaymentStatus,
  PaymentSummary,
  NestedOrder,
  TitleEnum,
  SupplierNameAndAddress,
  VatCategoryEnum,
  NestedVATPercentage,
  NamedHorse,
  Money,
  StudbookEnum,
  PublicOrderItem,
  PublicInvoiceItem,
  PurchaserNameAndAddress,
  PublicInvoice,
  PaymentLink,
  Redirect,
  TokenObtainPair,
  TokenRefresh,
  OrganisationOAuth,
  SocialLogin,
  UserDetails,
  JWT,
  TimeFrameEnum,
  SemenTypeEnum,
  UsageTypeEnum,
  PickedStallionMount,
  SupplierOrderItemDetail,
  ParentSemenOrder,
  SupplierOrderDetail,
  PurchaserOrderItem,
  PurchaserOrderUpdate,
  PatchedPurchaserOrderUpdate,
  RoleNames,
  StableNames,
  ModuleEnum,
  PricingModelEnum,
  PurchaserServiceContract,
  OrganisationContact,
  OrganisationList,
  TimezoneEnum,
  TypeOfOrganisationEnum,
  EnabledModulesEnum,
  Organisation,
  ActivityTypePrice,
  PatchedActivityTypePrice,
  FertilizerBudgetTypeEnum,
  LandTypeNitrogenEnum,
  LandTypePhosphateEnum,
  FertilizerConsumer,
  PaginatedFertilizerConsumerList,
  PatchedFertilizerConsumer,
  FertilizerOriginEnum,
  MutationTypeEnum,
  OperatorEnum,
  ProductionBalanceItem,
  PaginatedProductionBalanceItemList,
  PatchedProductionBalanceItem,
  NitrogenApplication,
  PaginatedNitrogenApplicationList,
  PatchedNitrogenApplication,
  ServiceContact,
  PatchedServiceContact,
  HorseMedia,
  PaginatedHorseMediaList,
  HorseMediaUpdate,
  PatchedHorseMediaUpdate,
  HorseOwner,
  PaginatedHorseOwnerList,
  PatchedHorseOwner,
  EmployabilityStatusEnum,
  HorseEmployabilityStatus,
  HorsePage,
  PhaseStatusEnum,
  HorsePhaseStatus,
  ReportTypeEnum,
  ReportedHorseRelocationStatusEnum,
  ReportedHorseRelocation,
  PaginatedReportedHorseRelocationList,
  CreateReportedHorseRelocation,
  RvOEUCountry,
  RvOHorseHairColor,
  SentInvitation,
  InvoiceItem,
  PatchedInvoiceItem,
  DepartureReasonEnum,
  BestemmingLandCodeEnum,
  HorseLocation,
  PaginatedHorseLocationList,
  HorseLocationUpdate,
  PatchedHorseLocationUpdate,
  ArrivalOrDepartureEnum,
  HorseLocationMutation,
  PaginatedHorseLocationMutationList,
  IsPregnantEnum,
  UterusEnum,
  LocationEnum,
  SoftnessEnum,
  NestedEgg,
  NestedCyst,
  PregnancyCheckTermEnum,
  FollowUpInseminationCheck,
  FollowUpMareCycleCheck,
  MareCycleCheck,
  PaginatedMareCycleCheckList,
  PatchedMareCycleCheck,
  OrderItemStallionMountPicker,
  EventStatus,
  ActivityContactRole,
  WorkloadEnum,
  ShowStatusEnum,
  DatetimeOrDateField,
  RealActivities,
  PaginatedRealActivitiesList,
  EventsToChangeEnum,
  RealActivitiesUpdate,
  PatchedRealActivitiesUpdate,
  PatchedEventDestroy,
  PatchedRealActivitiesSetDoneAndUnDone,
  RealActivitiesSetDoneAndUnDone,
  InvoiceLanguageEnum,
  BusinessTypeEnum,
  Contact,
  PaginatedContactList,
  DefaultEnum,
  ActivityTypeCategoryEnum,
  ExtraInfoFieldsEnum,
  ActivityType,
  PatchedActivityType,
  AbscenceTypeEnum,
  AvailabilityStatusEnum,
  Availability,
  PatchedAvailability,
  PatchedAvailabilityApproveDecline,
  AvailabilityApproveDecline,
  PSTypeEnum,
  VatLevelCountryVatLevelEnum,
  VatLevelCountry,
  Category,
  PatchedCategory,
  ContactDetail,
  PatchedContactDetail,
  PatchedMergeContacts,
  MergeContacts,
  PaginatedContactDetailList,
  DailyNote,
  PatchedDailyNote,
  PatchedDailyNoteSetDoneAndUnDone,
  DailyNoteSetDoneAndUnDone,
  DayPartStartTime,
  PatchedDayPartStartTime,
  Employment,
  PatchedEmployment,
  PossibleCalendarHashObjects,
  EventFeeds,
  PatchedEventFeeds,
  FacilityTypeEnum,
  AvailableWeekdaysEnum,
  Facility,
  PaginatedFacilityList,
  PatchedFacility,
  FacilityEvent,
  PaginatedFacilityEventList,
  FacilityEventUpdate,
  PatchedFacilityEventUpdate,
  MyFacilityEvent,
  MyFacilityEventUpdate,
  PatchedMyFacilityEventUpdate,
  Feeding,
  PatchedFeeding,
  QuantityMeasureEnum,
  FeedTypeCategoryEnum,
  FeedType,
  PatchedFeedType,
  HolidayEvent,
  PatchedHolidayEvent,
  CountryYear,
  Feed,
  PaginatedFeedList,
  FeedUpdate,
  PatchedFeedUpdate,
  HorseGroup,
  PatchedHorseGroup,
  Location,
  Horse,
  PaginatedHorseList,
  NestedHorseLocation,
  HorseLocationMove,
  HorseDetail,
  PatchedHorseDetail,
  PatchedMergeHorses,
  MergeHorses,
  HorseMove,
  HorseMoveResponse,
  HorseWithDuplicates,
  PaginatedHorseWithDuplicatesList,
  StateEnum,
  Progress,
  BackGroundTask,
  externalDbEnum,
  EquineMHorseSearchDetail,
  GetHorseUnprocessable,
  CannotCreateHorse,
  GetHorsesFromRvo,
  LocationResponse,
  HorseTelexDetailInput,
  HorseDetailHorseTelex,
  SearchHorse,
  EquineMSearchLastPage,
  Invoice,
  PaginatedInvoiceList,
  NestedInvoiceItem,
  InvoiceDetail,
  PatchedInvoiceDetail,
  InvoiceFinalize,
  LeaveAllowanceMutation,
  PatchedLeaveAllowanceMutation,
  HistoricHorseCount,
  AveragedHistoricHorseCount,
  FilePointOfInterest,
  FilePointOfInterestTyped,
  MapPointOfInterest,
  MapPointOfInterestTyped,
  URLPointOfInterest,
  URLPointOfInterestTyped,
  Map,
  OAuth2Token,
  PaymentTypeEnum,
  Payment,
  PaginatedPaymentList,
  PatchedPayment,
  PatchedFilePointOfInterest,
  PatchedFilePointOfInterestTyped,
  PatchedMapPointOfInterest,
  PatchedMapPointOfInterestTyped,
  PatchedURLPointOfInterest,
  PatchedURLPointOfInterestTyped,
  shipping_service_type,
  ProductTypeEnum,
  CustomTypeEnum,
  PricePoint,
  ShippingServiceTypeEnum,
  Product,
  PaginatedProductList,
  PatchedProduct,
  Role,
  PatchedRole,
  ModulePermissionsEnum,
  ModulePermissions,
  Module,
  ModulePermissionsObject,
  ModulePermissionsList,
  Service,
  PatchedService,
  StableContact,
  Stable,
  PatchedStable,
  VaccinationRule,
  PatchedVaccinationRule,
  VATPercentageVatLevelEnum,
  VATPercentage,
  VATPercentageUpdate,
  PatchedVATPercentageUpdate,
  SupplierServiceContract,
  PaginatedSupplierServiceContractList,
  PatchedSupplierServiceContract,
  PatchedServiceContractChange,
  ServiceContractChange,
  PurchaserOrder,
  PaginatedPurchaserOrderList,
  PurchaserOrderDetail,
  FindParentSemenOrderItemPurchaser,
  PaginatedPurchaserServiceContractList,
  PatchedPurchaserServiceContract,
  NIFAStrawColorEnum,
  StallionMount,
  PaginatedStallionMountList,
  SupplierOrderItem,
  SupplierOrder,
  StallionMountDetail,
  PatchedStallionMount,
  PaginatedSupplierOrderList,
  PatchedSupplierOrderDetail,
  FindParentSemenOrderItem,
  PatchedOrganisation,
  ProviderEnum,
  VATPercentageExternalProviderId,
  PatchedVATPercentageExternalProviderId,
  PasswordChange,
  RestAuthDetail,
  EquineMPasswordReset,
  PasswordResetConfirm,
  Plan,
  PublicCategory,
  PublicHorse,
  CatalogueProduct,
  PaginatedCatalogueProductList,
  PaginatedVATPercentageList,
  PublicOrganisation,
  SignupReferenceEnum,
  EquineMRegister,
  SignupWithInvitation,
  SignupUser,
  SignupResponse,
  VerifyEmail,
};
