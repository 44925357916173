import * as Sentry from '@sentry/react';
import ApiClient, { IApiErrorData } from 'api/ApiClient';
import { Api } from 'api/ApiConstants';
import { BrowserStorageKeys, UserSession } from 'api/UserSession';
import { changeLanguage } from 'i18next';
import { Account, AccountService } from 'openapi';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useConfig } from './ConfigContext';
import { useNavigate } from 'react-router-dom';
import { GroupBy } from 'utilities/Planning';

export interface EmailObject {
  id: number;
  email: string;
  verified: boolean;
  primary: boolean;
}

export interface IEmailUpdateForm {
  email: string;
}

/**
 * Actions related to email operations.
 * - `Add`: Represents the action of adding a new email.
 * - `Send`: Represents the action of sending a verification code to an email.
 * - `Remove`: Represents the action of removing an existing email.
 * - `Primary`: Represents the action of marking an email as the primary contact.
 */
export enum EmailActions {
  'Add' = 'add',
  'Send' = 'send',
  'Remove' = 'remove',
  'Primary' = 'primary',
}

interface EmailResult {
  data: EmailObject[];
}

export interface PasswordUpdate {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

interface Props {
  children: React.ReactNode;
}

type AccountContextType = {
  session: UserSession | undefined;
  apiClient: ApiClient;
  logout: (redirectUrl?: string) => void;
  login: (username: string, password: string, remember: boolean) => Promise<void>;
  loginWithJwtTokens: (accessToken: string, refreshToken: string) => Promise<void>;
  reloadAccount: () => Promise<void>;
  updateEmail: (email: string, action: EmailActions) => Promise<EmailResult>;
  listEmails: (signal?: AbortSignal) => Promise<EmailResult>;
  loading: boolean;
  accountDetails?: Account; // Visible when logged in
  setAccountDetails(accountDetails: Account): void;
  formatDate(date: Date | string): string;
  formatDateTime(date: Date | string): string;
  formatTime(date: Date): string;
  formatDateIntl(date: Date, options: Intl.DateTimeFormatOptions): string;
  formatMoney(amount: number, currency: string): string;
  parseAndFormatMoney(amount: string, currency: string): string;
  formatNumber(percentage: number): string;

  // A locally stored user preference on which planning should be opened when we go to 'Planning' via the menu.
  preferredPlanningGroupBy: GroupBy;
  setPreferredPlanningGroupBy(groupBy: GroupBy): void;
};

const AccountContext = createContext<AccountContextType>({
  session: undefined,
  // So that default api client can be initialized and we will not have to use optional operator while using this client
  apiClient: new ApiClient(undefined, undefined),
  logout: () => {
    throw Error('no AccountContext provider');
  },
  login: (): Promise<void> => {
    throw Error('no AccountContext provider');
  },
  loginWithJwtTokens: (): Promise<void> => {
    throw Error('no AccountContext provider');
  },
  reloadAccount: () => {
    throw Error('no AccountContext provider');
  },
  updateEmail: () => {
    throw Error('no AccountContext provider');
  },
  listEmails: () => {
    throw Error('no AccountContext provider');
  },
  loading: false,
  accountDetails: undefined,
  setAccountDetails: () => {
    throw Error('no AccountContext provider');
  },
  formatDate: () => {
    throw Error('no AccountContext provider');
  },
  formatDateTime: () => {
    throw Error('no AccountContext provider');
  },
  formatTime: () => {
    throw Error('no AccountContext provider');
  },
  formatDateIntl: () => {
    throw Error('no AccountContext provider');
  },
  formatMoney: () => {
    throw Error('no AccountContext provider');
  },
  parseAndFormatMoney: () => {
    throw Error('no AccountContext provider');
  },
  formatNumber: () => {
    throw Error('no AccountContext provider');
  },
  preferredPlanningGroupBy: GroupBy.Horse,
  setPreferredPlanningGroupBy: () => {
    throw Error('no AccountContext provider');
  },
});

export function useAccount(): AccountContextType {
  return useContext(AccountContext);
}

export function AccountProvider({ children }: Props): JSX.Element {
  const [userSession, setUserSession] = useState<UserSession>();
  const [accountDetails, setAccountDetailsObject] = useState<Account | undefined>();
  const [loading, setLoading] = useState<boolean>(UserSession.hasSessionInBrowserStorage());
  const [preferredPlanningGroupBy, setPreferredPlanningGroupByInternal] = useState<GroupBy>(
    localStorage?.getItem(BrowserStorageKeys.PlanningGroupPreference) === 'stable'
      ? GroupBy.Stable
      : localStorage?.getItem(BrowserStorageKeys.PlanningGroupPreference) === 'staff'
        ? GroupBy.Staff
        : GroupBy.Horse,
  );

  const navigate = useNavigate();
  const { config } = useConfig();

  const apiClient = useMemo(() => {
    return new ApiClient(userSession, config);
  }, [userSession, config]);

  // Async method that is used internally by the 'load from session storage' and
  // 'login' method.
  const init = async (sessionPromise: Promise<UserSession>): Promise<{ session: UserSession; account: Account }> => {
    const session = await sessionPromise;
    const account = await AccountService.apiV5AccountRetrieve();
    // Enrich sentry logging by setting the user.
    Sentry.setUser({
      id: account.uid,
      email: account.email,
      username: account.first_name + ' ' + account.last_name,
    });
    return { session, account };
  };

  const setAccountDetails = useCallback(
    (details: Account) => {
      setAccountDetailsObject(details);
      changeLanguage(details.language?.toLocaleLowerCase());
    },
    [setAccountDetailsObject],
  );

  /**
   * Load the logged in user from the session storage.
   */
  const loadAccount = useCallback(
    async (signal?: AbortSignal) => {
      // Remove user session that has been expired.
      UserSession.cleanExpiredLogin();

      if (!UserSession.hasSessionInBrowserStorage()) {
        setLoading(false);
        return;
      }

      try {
        const { session, account } = await init(UserSession.restoreFromBrowserStorage(signal));
        setUserSession(session);
        setAccountDetailsObject(account);
        changeLanguage(account.language?.toLocaleLowerCase());
        setLoading(false);
      } catch (error) {
        if (!signal?.aborted) {
          console.log(`Failed to restore user session: ${(error as Error).message}`);
          setLoading(false);
        }
      }
    },
    [setAccountDetailsObject],
  );

  /**
   * Login with username and password
   */
  const login = useCallback(
    async (username: string, password: string, remember: boolean): Promise<void> => {
      if (loading) {
        console.error('Already logging in');
        return;
      }

      const { session, account } = await init(UserSession.login(username, password, remember));
      setUserSession(session);
      setAccountDetails(account);
    },
    [loading, setAccountDetails],
  );

  /**
   * Login with just the JWT tokens
   * e.g when a user accept an invitation he will get the (access, refresh) tokens from that call
   * with this method we save the tokens in the session storage and load the user account so the user is "logged-in"
   */
  const loginWithJwtTokens = useCallback(
    async (accessToken: string, refreshToken: string): Promise<void> => {
      if (loading) {
        console.error('Already logging in');
        return;
      }

      const { session, account } = await init(Promise.resolve(new UserSession(accessToken, refreshToken, true)));
      setUserSession(session);
      setAccountDetails(account);
    },
    [loading, setAccountDetails],
  );

  /**
   * Format a date (either string or Date) to a localized
   * string using the user language.
   */
  const formatDate = useCallback(
    (date: Date | string, showTime = false): string => {
      if (typeof date === 'string') {
        date = new Date(date);

        // handle an invalid date
        if (date.toString() === 'Invalid Date') {
          console.error('Parsing an invalid date');
          return '';
        }
      }

      if (!accountDetails) {
        console.error('Tried to call formatDate() but account details are not loaded.');
        return date.toISOString();
      }
      return new Intl.DateTimeFormat(accountDetails.language || undefined, {
        dateStyle: 'short',
        timeStyle: showTime ? 'short' : undefined,
      }).format(date);
    },
    [accountDetails],
  );

  const formatDateTime = useCallback(
    (date: Date | string): string => {
      return formatDate(date, true);
    },
    [formatDate],
  );

  const formatTime = useCallback(
    (date: Date): string => {
      if (!accountDetails) {
        console.error('Tried to call formatTime() but account details are not loaded.');
        return date.toISOString();
      }
      return new Intl.DateTimeFormat(accountDetails.language || undefined, { dateStyle: undefined, timeStyle: 'short' }).format(date);
    },
    [accountDetails],
  );

  const formatDateIntl = useCallback(
    (date: Date, options: Intl.DateTimeFormatOptions): string => {
      if (!accountDetails) {
        console.error('Tried to call formatTime() but account details are not loaded.');
        return 'n/a';
      }
      // return new Intl.DateTimeFormat(accountDetails.language, { weekday: 'short' }).format(date);
      return new Intl.DateTimeFormat(accountDetails.language || undefined, options).format(date);
    },
    [accountDetails],
  );

  // Format a money to a localized string using the user language.
  const formatNumber = useCallback(
    (percentage: number): string => {
      if (!accountDetails) {
        console.error('Tried to call formatNumber() but account details are not loaded.');
      }
      return new Intl.NumberFormat(accountDetails?.language || undefined).format(percentage);
    },
    [accountDetails],
  );

  // Format a money to a localized string using the user language.
  const formatMoney = useCallback(
    (amount: number, currency: string): string => {
      if (!accountDetails) {
        console.error('Tried to call formatMoney() but account details are not loaded.');
      }
      return new Intl.NumberFormat(accountDetails?.language || undefined, { style: 'currency', currency: currency }).format(amount);
    },
    [accountDetails],
  );

  const parseAndFormatMoney = useCallback(
    (amountString: string, currency: string): string => {
      if (!accountDetails) {
        console.error('Tried to call formatMoney() but account details are not loaded.');
      }
      const amount = Number(amountString);
      if (isNaN(amount)) {
        return '-';
      }
      return formatMoney(amount, currency);
    },
    [accountDetails, formatMoney],
  );

  /**
   * Update account and update the context with the result
   */
  const updateEmail = useCallback(
    async (email: string, action: EmailActions) => {
      const details: Record<string, string> = {
        email,
        [`action_${action}`]: 'true',
      };
      const formBody: string[] = [];
      for (const property in details) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
      return apiClient.postAuthenticated<EmailResult, IApiErrorData<IEmailUpdateForm>>(Api.Emails, formBody.join('&'), {
        urlEncoded: true,
      });
    },
    [apiClient],
  );

  /**
   * List the emails from a user account
   */
  const listEmails = useCallback(
    (signal?: AbortSignal) => {
      return apiClient.getAuthenticatedJson<EmailResult>(Api.Emails, signal);
    },
    [apiClient],
  );

  /**
   * Load the user onInit
   */
  useEffect(() => {
    // Use an abort controller to avoid the react strict double render.
    const abortController = new AbortController();

    // only fetch the account details for the first time if the account has
    // not loaded yet
    if (!accountDetails) {
      loadAccount(abortController.signal);
    }

    return () => abortController.abort();
  }, []); // eslint-disable-line

  const logout = useCallback(
    async (redirectUrl = '/') => {
      if (userSession) {
        await changeLanguage(undefined);
        setUserSession(undefined);
        setAccountDetailsObject(undefined);
        localStorage?.removeItem(BrowserStorageKeys.SelectedOrganization);
        userSession.logout();
        // make sure we also redirect the user to the root route
        // this avoid we always have e.g. /profile in the redirect
        // param when logging in after the logout
        navigate(redirectUrl);
      }
    },
    [navigate, userSession],
  );

  const setPreferredPlanningGroupBy = useCallback((groupBy: GroupBy) => {
    setPreferredPlanningGroupByInternal(groupBy);
    localStorage?.setItem(
      BrowserStorageKeys.PlanningGroupPreference,
      groupBy === GroupBy.Stable ? 'stable' : groupBy === GroupBy.Staff ? 'staff' : 'horse',
    );
  }, []);

  return (
    <AccountContext.Provider
      value={{
        session: userSession,
        apiClient,
        logout,
        loading,
        login,
        loginWithJwtTokens,
        reloadAccount: loadAccount,
        updateEmail,
        listEmails,
        accountDetails,
        setAccountDetails,
        formatDate,
        formatDateTime,
        formatTime,
        formatDateIntl,
        formatMoney,
        parseAndFormatMoney,
        formatNumber,
        preferredPlanningGroupBy,
        setPreferredPlanningGroupBy,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}
