/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedContactList } from '../models/PaginatedContactList';
import type { PaginatedRealActivitiesList } from '../models/PaginatedRealActivitiesList';
import type { PatchedEventDestroy } from '../models/PatchedEventDestroy';
import type { PatchedRealActivitiesSetDoneAndUnDone } from '../models/PatchedRealActivitiesSetDoneAndUnDone';
import type { PatchedRealActivitiesUpdate } from '../models/PatchedRealActivitiesUpdate';
import type { RealActivities } from '../models/RealActivities';
import type { RealActivitiesSetDoneAndUnDone } from '../models/RealActivitiesSetDoneAndUnDone';
import type { RealActivitiesUpdate } from '../models/RealActivitiesUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ActivitiesService {
  /**
   * List, update and create activities (model: RealActivities) for your organisations
   *
   * Note on facilities: You can provide one or more facilities where this event should be hosted
   * (see Facilities API). Doing so will 'book' that facility, but only if it is available for the
   * indicated event period and has room for the event's horses. If you *update* the subsequent
   * event you *must* provide the required facilities in the partial update. If you were to leave
   * them out, the corresponding FacilityEvent (see FacilityEvents API) will be deleted, meaning the
   * facility would no longer be planned for your event.
   * @returns PaginatedRealActivitiesList
   * @throws ApiError
   */
  public static activitiesList({
    organisationUid,
    activityTypeDefault,
    activityTypeDefaultIn,
    activityTypeHidden,
    activityTypeUid,
    activityTypeUidIn,
    dtendGt,
    dtstartLt,
    horseGroupUid,
    horseGroupUidIn,
    horseStableUid,
    horseStableUidIn,
    horseUidIn,
    horseUserActive,
    lastDoneAndLastAndFirstFuturePerHorseAndActivityType,
    o,
    orderItemUid,
    orderItemUidIn,
    page,
    pageSize,
    remind,
    statusIn,
  }: {
    organisationUid: string,
    /**
     * * `RIDING` - Riding
     * * `PADDOCK` - Paddock
     * * `LUNGING` - Lunging
     * * `FARRIER` - Farrier
     * * `VACCINATION` - Vaccination
     * * `VETERINARY` - Veterinary
     * * `SHOW` - Show
     * * `CUSTOMER` - Customer
     * * `DEWORM` - Deworm
     * * `DENTIST` - Dentist
     * * `LESSON` - Lesson
     * * `INSEMINATION` - Insemination
     * * `FOALING` - Foaling
     * * `MARE_CYCLE_CHECK` - Mare Cycle Check
     * * `RHINO` - Rhino
     * * `STALLION_MOUNT` - Stallion Mount
     */
    activityTypeDefault?: 'CUSTOMER' | 'DENTIST' | 'DEWORM' | 'FARRIER' | 'FOALING' | 'INSEMINATION' | 'LESSON' | 'LUNGING' | 'MARE_CYCLE_CHECK' | 'PADDOCK' | 'RHINO' | 'RIDING' | 'SHOW' | 'STALLION_MOUNT' | 'VACCINATION' | 'VETERINARY',
    /**
     * Multiple values may be separated by commas.
     */
    activityTypeDefaultIn?: Array<string>,
    activityTypeHidden?: boolean,
    activityTypeUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    activityTypeUidIn?: Array<string>,
    dtendGt?: string,
    dtstartLt?: string,
    horseGroupUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    horseGroupUidIn?: Array<string>,
    horseStableUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    horseStableUidIn?: Array<string>,
    /**
     * Multiple values may be separated by commas.
     */
    horseUidIn?: Array<string>,
    horseUserActive?: boolean,
    /**
     * Last done and first future per activity type and horse
     */
    lastDoneAndLastAndFirstFuturePerHorseAndActivityType?: boolean,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    orderItemUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    orderItemUidIn?: Array<string>,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    remind?: boolean,
    /**
     * Multiple values may be separated by commas.
     */
    statusIn?: Array<string>,
  }): CancelablePromise<PaginatedRealActivitiesList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/activities',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'activity_type__default': activityTypeDefault,
        'activity_type__default__in': activityTypeDefaultIn,
        'activity_type__hidden': activityTypeHidden,
        'activity_type__uid': activityTypeUid,
        'activity_type__uid__in': activityTypeUidIn,
        'dtend__gt': dtendGt,
        'dtstart__lt': dtstartLt,
        'horse__group__uid': horseGroupUid,
        'horse__group__uid__in': horseGroupUidIn,
        'horse__stable__uid': horseStableUid,
        'horse__stable__uid__in': horseStableUidIn,
        'horse__uid__in': horseUidIn,
        'horse__user_active': horseUserActive,
        'lastDoneAndLastAndFirstFuturePerHorseAndActivityType': lastDoneAndLastAndFirstFuturePerHorseAndActivityType,
        'o': o,
        'order_item__uid': orderItemUid,
        'order_item__uid__in': orderItemUidIn,
        'page': page,
        'page_size': pageSize,
        'remind': remind,
        'status__in': statusIn,
      },
    });
  }
  /**
   * List, update and create activities (model: RealActivities) for your organisations
   *
   * Note on facilities: You can provide one or more facilities where this event should be hosted
   * (see Facilities API). Doing so will 'book' that facility, but only if it is available for the
   * indicated event period and has room for the event's horses. If you *update* the subsequent
   * event you *must* provide the required facilities in the partial update. If you were to leave
   * them out, the corresponding FacilityEvent (see FacilityEvents API) will be deleted, meaning the
   * facility would no longer be planned for your event.
   * @returns RealActivities
   * @throws ApiError
   */
  public static activitiesCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: RealActivities,
  }): CancelablePromise<RealActivities> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/activities',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List, update and create activities (model: RealActivities) for your organisations
   *
   * Note on facilities: You can provide one or more facilities where this event should be hosted
   * (see Facilities API). Doing so will 'book' that facility, but only if it is available for the
   * indicated event period and has room for the event's horses. If you *update* the subsequent
   * event you *must* provide the required facilities in the partial update. If you were to leave
   * them out, the corresponding FacilityEvent (see FacilityEvents API) will be deleted, meaning the
   * facility would no longer be planned for your event.
   * @returns RealActivities
   * @throws ApiError
   */
  public static activitiesRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<RealActivities> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/activities/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * List, update and create activities (model: RealActivities) for your organisations
   *
   * Note on facilities: You can provide one or more facilities where this event should be hosted
   * (see Facilities API). Doing so will 'book' that facility, but only if it is available for the
   * indicated event period and has room for the event's horses. If you *update* the subsequent
   * event you *must* provide the required facilities in the partial update. If you were to leave
   * them out, the corresponding FacilityEvent (see FacilityEvents API) will be deleted, meaning the
   * facility would no longer be planned for your event.
   * @returns RealActivitiesUpdate
   * @throws ApiError
   */
  public static activitiesUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: RealActivitiesUpdate,
  }): CancelablePromise<RealActivitiesUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/activities/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List, update and create activities (model: RealActivities) for your organisations
   *
   * Note on facilities: You can provide one or more facilities where this event should be hosted
   * (see Facilities API). Doing so will 'book' that facility, but only if it is available for the
   * indicated event period and has room for the event's horses. If you *update* the subsequent
   * event you *must* provide the required facilities in the partial update. If you were to leave
   * them out, the corresponding FacilityEvent (see FacilityEvents API) will be deleted, meaning the
   * facility would no longer be planned for your event.
   * @returns RealActivitiesUpdate
   * @throws ApiError
   */
  public static activitiesPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedRealActivitiesUpdate,
  }): CancelablePromise<RealActivitiesUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/activities/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List, update and create activities (model: RealActivities) for your organisations
   *
   * Note on facilities: You can provide one or more facilities where this event should be hosted
   * (see Facilities API). Doing so will 'book' that facility, but only if it is available for the
   * indicated event period and has room for the event's horses. If you *update* the subsequent
   * event you *must* provide the required facilities in the partial update. If you were to leave
   * them out, the corresponding FacilityEvent (see FacilityEvents API) will be deleted, meaning the
   * facility would no longer be planned for your event.
   * @returns any No response body
   * @throws ApiError
   */
  public static activitiesDeletePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedEventDestroy,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/activities/{uid}/delete',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List, update and create activities (model: RealActivities) for your organisations
   *
   * Note on facilities: You can provide one or more facilities where this event should be hosted
   * (see Facilities API). Doing so will 'book' that facility, but only if it is available for the
   * indicated event period and has room for the event's horses. If you *update* the subsequent
   * event you *must* provide the required facilities in the partial update. If you were to leave
   * them out, the corresponding FacilityEvent (see FacilityEvents API) will be deleted, meaning the
   * facility would no longer be planned for your event.
   * @returns RealActivitiesSetDoneAndUnDone
   * @throws ApiError
   */
  public static activitiesSetDonePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedRealActivitiesSetDoneAndUnDone,
  }): CancelablePromise<RealActivitiesSetDoneAndUnDone> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/activities/{uid}/set_done',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List, update and create activities (model: RealActivities) for your organisations
   *
   * Note on facilities: You can provide one or more facilities where this event should be hosted
   * (see Facilities API). Doing so will 'book' that facility, but only if it is available for the
   * indicated event period and has room for the event's horses. If you *update* the subsequent
   * event you *must* provide the required facilities in the partial update. If you were to leave
   * them out, the corresponding FacilityEvent (see FacilityEvents API) will be deleted, meaning the
   * facility would no longer be planned for your event.
   * @returns RealActivitiesSetDoneAndUnDone
   * @throws ApiError
   */
  public static activitiesSetUndonePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedRealActivitiesSetDoneAndUnDone,
  }): CancelablePromise<RealActivitiesSetDoneAndUnDone> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/activities/{uid}/set_undone',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Returns all contacts needed for activities, by looking up the ExtraInfoSettings.roles of the ActivityType and returning all contacts with those roles.
   *
   * Filters on the ActivityTypes the user has access to.
   * @returns PaginatedContactList
   * @throws ApiError
   */
  public static activitiesContactsList({
    organisationUid,
    page,
    pageSize,
  }: {
    organisationUid: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedContactList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/activities/contacts',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'page': page,
        'page_size': pageSize,
      },
    });
  }
}
