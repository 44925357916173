/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `F1` - F1
 * * `F2` - F2
 * * `F3` - F3
 * * `F4` - F4
 * * `F5` - F5
 * * `trial` - Trial
 * * `lesson` - Les
 */
export enum PhaseStatusEnum {
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  TRIAL = 'trial',
  LESSON = 'lesson',
}
