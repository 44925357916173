/**
 * Small helper that return a query string for the preferred stable.
 */
export default function preferredStableQuery(preferredStableUids: string[]): string {
  let urlSuffix = '';
  if (preferredStableUids.length > 0) {
    const stableSearchParam = new URLSearchParams();
    for (const uid of preferredStableUids) {
      stableSearchParam.append('stable', uid);
    }
    urlSuffix = `?${stableSearchParam.toString()}`;
  }

  return urlSuffix;
}
