import { Capacitor } from '@capacitor/core';

export const IS_MOBILE_APP = Capacitor.getPlatform() !== 'web';
export const IS_WEB_APP = Capacitor.getPlatform() === 'web';
export const IS_IOS_APP = Capacitor.getPlatform() === 'ios';
export const IS_ANDROID_APP = Capacitor.getPlatform() === 'android';

// Subscriptions are managed by the Equinem organization. This is the UID of the Equinem organization.
// We use this UID for all subscription-related calls.
export const EQUINEM_PUBLIC_ORG_UID = '00000000-0000-0000-0000-000000000000';
