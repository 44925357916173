import { Horse as HorseIcon } from '@phosphor-icons/react';
import classNames from 'classnames';
import { Horse, HorseDetail } from 'openapi';
import React, { useEffect, useState } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AvatarIcon, AvatarSize } from 'ui/Avatar';
import SelectList, { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import useModal from 'ui/Modals/UseModal';
import { formalHorseName, informalHorseName, textFilter } from 'utilities/Horse';
import CreateMareModal from './CreateMareModal';
import CreateHorseModal from './CreateHorseModal';
import { FloatProps } from '@headlessui-float/react';
import OptimizedImage from 'ui/OptimizedImage';

/**
 * Complex type that extend some magic types from React Form hooks and include own fields
 *
 * See --> https://github.com/orgs/react-hook-form/discussions/7851#discussioncomment-2219298
 */
interface Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> {
  name: TName; // override from UseControllerProps and make it required
  control: Control<TFieldValues>; // override from UseControllerProps and make it required
  label?: string;
  hint?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  placement?: FloatProps['placement'];
  className?: string;
  horses: WrappedComboboxProps<Horse>[] | Horse[];
  // when the onCreate is called, we should create a new horse
  onCreated?: (horse: Horse) => void;
  // in some cases we just want to create a mare, this flag will trigger the modal to create a mare only
  createMareOnly?: boolean;
  // optional, the owner that will be used to set a 100% ownership
  // this is only used when we create a new mare
  ownerUid?: string;
  // Optional contact uid of the mare location. This field is only used when
  // `createMareOnly` is true.
  locationUid?: string;
  // flag that indicates if the horse is external (=no location and no stable)
  isExternal?: boolean;
  // Display the horse name as nickname (informal) or as their official name (formal).
  useFormalHorseName?: boolean;
}

/**
 * Contact Select field create a filtered select input. It is also possible to create a new contact from this context
 */
function HorseInputSelect<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  label,
  hint,
  error,
  required,
  disabled,
  placement,
  className,
  horses: givenHorses,
  name,
  control,
  onCreated,
  createMareOnly,
  ownerUid,
  locationUid,
  isExternal,
  useFormalHorseName,
}: Props<TFieldValues, TName>): JSX.Element {
  const [defaultName, setDefaultName] = useState<string>();
  const [horses, setHorses] = useState<WrappedComboboxProps<Horse>[]>([]);

  const { t } = useTranslation();
  const { closeModal: closeCreateMareModal, modalIsVisible: createMareModalIsVisible, showModal: showCreateMareModal } = useModal();
  const { closeModal: closeCreateHorseModal, modalIsVisible: createHorseModalIsVisible, showModal: showCreateHorseModal } = useModal();

  /**
   * Create the onCreate function if we passed an onCreated function
   * If we omit the onCreated function, we just return an undefined function
   * this will trigger the SelectList to omit the create option
   */
  const onCreate = onCreated
    ? (query: string) => {
        setDefaultName(query);
        createMareOnly ? showCreateMareModal() : showCreateHorseModal();
        showCreateMareModal();
      }
    : undefined;

  /**
   * Event handler that invoked when a new horse is created
   */
  const onHorseCreated = (newHorse: HorseDetail) => {
    setHorses(prevState => {
      // We cast the newHorse to Horse because the type of the newHorse is HorseDetail
      // As it shared most of the fields with Horse, we can safely cast it

      if (!prevState) {
        return [
          {
            items: [newHorse as unknown as Horse],
          },
        ];
      }

      // loop over the items, and make sure we create a copy of the items and add the newly contact at the last group
      const updatedList: WrappedComboboxProps<Horse>[] = [];
      prevState.forEach((group, index) => {
        const items = [...group.items];

        if (index === prevState.length - 1) {
          items.push(newHorse as unknown as Horse);
        }

        updatedList.push({
          heading: group.heading,
          items: items,
        });
      });

      return updatedList;
    });

    onCreated?.(newHorse as unknown as Horse);
  };

  /**
   * Update the contact state if changed from the paren
   */
  useEffect(() => {
    if (givenHorses && givenHorses.length > 0 && 'items' in givenHorses[0]) {
      setHorses(givenHorses as WrappedComboboxProps<Horse>[]);
    } else if (givenHorses) {
      setHorses([{ items: givenHorses as Horse[] }]);
    }
  }, [givenHorses]);

  return (
    <>
      <SelectList<Horse, TFieldValues, TName>
        className={className}
        placement={placement}
        required={required}
        hint={hint}
        error={error}
        label={label}
        options={horses}
        control={control}
        name={name}
        disabled={disabled}
        idField='uid'
        onFilter={(query, items) => textFilter(items ?? [], query)}
        onCreate={onCreate}
        notFoundLabel={t('horse-not-found', 'Horse not found')}
        createLabel={t('create-new-horse', 'Create new horse')}
        displayInput={horse => (useFormalHorseName ? formalHorseName(horse) : informalHorseName(horse)) ?? ''}
        displayOption={(horse, selected) => (
          <>
            {horse.avatar_file && <OptimizedImage className='w-6 h-6 rounded-full' src={horse.avatar_file} width={64} quality={75} />}
            {!horse.avatar_file && <AvatarIcon size={AvatarSize.XSmall} icon={<HorseIcon />} uuid={horse.uid} />}

            <span
              className={classNames('block truncate', {
                'font-medium': selected,
                'font-normal': !selected,
              })}
            >
              {useFormalHorseName ? formalHorseName(horse) : informalHorseName(horse)}
            </span>
          </>
        )}
      />

      {!createMareOnly && (
        <CreateHorseModal
          open={createHorseModalIsVisible}
          onRequestCloseModal={closeCreateHorseModal}
          onCreated={onHorseCreated}
          defaultName={defaultName}
          isExternal={isExternal}
        />
      )}

      {createMareOnly && (
        <CreateMareModal
          open={createMareModalIsVisible}
          onRequestCloseModal={closeCreateMareModal}
          onCreated={onHorseCreated}
          defaultName={defaultName}
          ownerUid={ownerUid}
          locationUid={locationUid}
        />
      )}
    </>
  );
}

export default HorseInputSelect;
